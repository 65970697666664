import {decryptJson, encryptJson} from "../../utils/JsonEncript";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";

class LocalStorageService {

    static adicionarItem(chave, valor) {
            if (IsNullOrEmpty(chave) || IsNullOrEmpty(valor)) {
                return
            }
            let new_valor = encryptJson(valor, process.env.REACT_APP_SECRET)
            localStorage.setItem(chave, new_valor);

    }

    static obterItem(chave) {
        const item = localStorage.getItem(chave);
            if (IsNullOrEmpty(item)) {
                return null
            }
            try {
            return decryptJson(item, process.env.REACT_APP_SECRET)
            } catch (e) {
                return null
            }
    }

    static removerItem(chave) {
        localStorage.removeItem(chave)
    }

    static adicionarConsulta(chave, valor) {
        if (IsNullOrEmpty(chave) || IsNullOrEmpty(valor)) {
            return
        }
        let new_valor = encryptJson(valor, process.env.REACT_APP_SECRET)
        localStorage.setItem(chave, new_valor);
    }

    static obterConsulta(chave) {
        const item = localStorage.getItem(chave);
        if (IsNullOrEmpty(item)) {
            return null
        }
        try {
            return decryptJson(item, process.env.REACT_APP_SECRET)
        } catch (e) {
            return null
        }
    }

    static limparConsultaProcesso() {
        localStorage.removeItem('_consulta_processo_indice');
        localStorage.removeItem('_consulta_processo_filtro');
    }

    static limparConsultaPessoas() {
        localStorage.removeItem('_consulta_pessoa_filtro');
    }

    static addedImagem(chave, valor) {
        if (IsNullOrEmpty(chave) || IsNullOrEmpty(valor)) {
            return
        }
        localStorage.setItem(chave, valor);
    }

    static getImage(chave) {
        return localStorage.getItem(chave)
    }


}

export default LocalStorageService