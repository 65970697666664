import React from 'react'

import {withRouter} from 'react-router-dom'

import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button'
import {ConfirmDialog, confirmDialog} from 'primereact/confirmdialog'
import {Dialog} from 'primereact/dialog'
import {Dropdown} from 'primereact/dropdown'
import {InputText} from 'primereact/inputtext'
import {InputMask} from 'primereact/inputmask';
import {InputNumber} from 'primereact/inputnumber'
import {InputTextarea} from 'primereact/inputtextarea'
import {Calendar} from 'primereact/calendar'
import {TabPanel, TabView} from 'primereact/tabview';
import {Accordion, AccordionTab} from 'primereact/accordion'
import {addLocale} from 'primereact/api'
import {RadioButton} from 'primereact/radiobutton'

import NavBar from '../../componentes/Navbar'
import PartesAlterar from '../../componentes/PartesAlterar'

import ProcessoService from '../../app/service/ProcessoService'
import LocalStorageService from '../../app/service/localStorageService'
import GrupoTrabalhoService from '../../app/service/GrupoTrabalhoService'
import TipoAcaoService from '../../app/service/TipoAcaoService'
import StatusProcessualService from '../../app/service/StatusProcessualService'
import TipoDecisaoService from '../../app/service/TipoDecisao'
import ObjetoScaoService from '../../app/service/ObjetoAcaoService'
import PessoaService from '../../app/service/PessoaService'
import AreaAtuacaoService from '../../app/service/AreaAtuacaoService'
import ObjetoAcaoProcessoService from '../../app/service/ObjetoAcaoProcessoService'
import PagamentoProcessoService from '../../app/service/PagamentoProcessoService'
import TipoPagamentoService from '../../app/service/TipoPagamentoService'
import CustasProcessoService from '../../app/service/CustasProcessoService'
import TipoCustaService from '../../app/service/TiposCustasService'
import TipoGarantiaService from '../../app/service/TipoGarantiaService'
import GarantiaProcessoService from '../../app/service/GarantiaProcessoService'
import HistoricoProcessoService from '../../app/service/HistoricoProcessoService'
import ArquivoProcessoServiice from '../../app/service/ArquivoProcessoService'
import ProcessoImportanciaService from '../../app/service/ProcessoImportanciaService'
import AtivividadeService from '../../app/service/AtividadeService'
import UsuarioGrupoTrabalhoService from '../../app/service/UsuarioGrupoTrabalhoService'
import ArquivoPessoaService from '../../app/service/ArquivoPessoaService'
import FinanceiroService from '../../app/service/FinanceiroService'
import EstadoService from '../../app/service/EstadoService'
import CidadeService from '../../app/service/CidadeService'
import StatusAtividadeService from '../../app/service/StatusAtividadeService'

import {AuthContext} from '../../main/ProvedorAutenticacao'

import './processo.css'
import './upload.css'
import '../home/painel.css'
import {strings} from "../../utils/strings";
import {DateFormat} from "../../utils/DateFormat";
import {AutoCompleteLocalTramite} from "../../componentes/AutoCompleteLocalTramite";
import {DialogSelectSystemProcesso} from "../../componentes/DialogSelectSystemProcesso";
import {HistoricoProcesso} from "../../componentes/Processo/FichaProcesso/HistoricoProcesso";
import {AtividadeProcesso} from "../../componentes/Processo/FichaProcesso/AtividadesProcesso";
import {DocumentosProcesso} from "../../componentes/Processo/FichaProcesso/DocumentosProcesso";
import {FinanceiroProcesso} from "../../componentes/Processo/FichaProcesso/FinanceiroProcesso";
import {Loading} from "../../componentes/Loading";
import {DialogGrupoTrabalho} from "../../componentes/DialogGrupoTrabalho";
import {DialogStatusProcessual} from "../../componentes/DialogStatusProcessual";
import {DialogAreaAtuacao} from "../../componentes/DialogAreaAtuacao";
import {DialogTipoAcao} from "../../componentes/DialogTipoAcao";
import {DialogTipoDesicao} from "../../componentes/DialogTipoDesicao";
import {CadastrarPessoaDialog} from "../../componentes/Pessoa/CadastrarPessoaDialog";
import {ObjetoAcaoSelecter} from "../../componentes/ObjetosAcaoComponent/ObjetoAcaoSelecter";
import {CadastroHistoricoDialog} from "../../componentes/Processo/FichaProcesso/CadastroHistoricoDialog";
import LiminarService from "../../app/service/LiminarService";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import ProcessoAutomaticoService from '../../app/service/ProcessoAutomaticoService';
import {PrecatorioProcesso} from "../../componentes/Processo/FichaProcesso/PrecatorioProcesso";

class FichaProcesso extends React.Component {

    constructor(props) {
        super(props);

        this.processoService = new ProcessoService();
        this.processoAutoService = new ProcessoAutomaticoService()
        this.grupoTrabalhoService = new GrupoTrabalhoService();
        this.tipoAcaoService = new TipoAcaoService();
        this.statusProcessualService = new StatusProcessualService();
        this.tipoDecisaoService = new TipoDecisaoService();
        this.objetoAcaoService = new ObjetoScaoService();
        this.pessoaService = new PessoaService();
        this.areaAtuacaoService = new AreaAtuacaoService();
        this.objetoAcaoProcessoService = new ObjetoAcaoProcessoService();
        this.pagamentoProcessoService = new PagamentoProcessoService();
        this.tipoPagamentoService = new TipoPagamentoService();
        this.custasProcessoService = new CustasProcessoService();
        this.tipoCustaService = new TipoCustaService();
        this.tipoGarantiaService = new TipoGarantiaService();
        this.garantiaProcessoService = new GarantiaProcessoService();
        this.historicoProcessoService = new HistoricoProcessoService();
        this.arquivoProcessoService = new ArquivoProcessoServiice();
        this.processoImportanciaService = new ProcessoImportanciaService();
        this.atividadeService = new AtivividadeService();
        this.usuarioGrupoTrabalhoService = new UsuarioGrupoTrabalhoService();
        this.arquivoPessoaService = new ArquivoPessoaService();
        this.financeiroService = new FinanceiroService();
        this.estadoService = new EstadoService();
        this.cidadeService = new CidadeService();
        this.statusAtividadeService = new StatusAtividadeService();
        this.liminarService = new LiminarService();

        this.consultarPessoa = this.consultarPessoa.bind(this);
        this.acoesObjetosAcao = this.acoesObjetosAcao.bind(this);
        this.onTipoDecisaoChange = this.onTipoDecisaoChange.bind(this);
        this.vlPagamento = this.vlPagamento.bind(this);
        this.acoesPagamentosProcesso = this.acoesPagamentosProcesso.bind(this);
        this.acoesCustasProcesso = this.acoesCustasProcesso.bind(this);
        this.onChangeTipoPagamento = this.onChangeTipoPagamento.bind(this);
        this.onChangeStatusProcessual = this.onChangeStatusProcessual.bind(this);
        this.onChangeGrupoTrabalho = this.onChangeGrupoTrabalho.bind(this);
        this.onChangeTipoAcao = this.onChangeTipoAcao.bind(this);
        this.onChangeAreaAtuacao = this.onChangeAreaAtuacao.bind(this);
        this.renderFooterCadastroPagamentos = this.renderFooterCadastroPagamentos.bind(this);
        this.confirmaExclusaoPagamento = this.confirmaExclusaoPagamento.bind(this);
        this.confirmaExclusaoCusta = this.confirmaExclusaoCusta.bind(this);
        this.acceptExcluirPagamento = this.acceptExcluirPagamento.bind(this);
        this.acceptExcluirCustas = this.acceptExcluirCustas.bind(this);
        this.vlCustas = this.vlCustas.bind(this);
        this.onChangeTipoCustas = this.onChangeTipoCustas.bind(this);
        this.renderFooterCadastroCustas = this.renderFooterCadastroCustas.bind(this);
        this.acoesGarantiasProcesso = this.acoesGarantiasProcesso.bind(this);
        this.acceptExcluirGarantias = this.acceptExcluirGarantias.bind(this);
        this.confirmaExclusaoGarantia = this.confirmaExclusaoGarantia.bind(this);
        this.vlPenhora = this.vlPenhora.bind(this);
        this.headerResumoPagamentos = this.headerResumoPagamentos.bind(this);
        this.headerResumoCustas = this.headerResumoCustas.bind(this);
        this.onChangeTipoGarantia = this.onChangeTipoGarantia.bind(this);
        this.headerTotalPenhora = this.headerTotalPenhora.bind(this);
        this.confirmaExclusaoAutor = this.confirmaExclusaoAutor.bind(this);
        this.aceptExcluirAutor = this.aceptExcluirAutor.bind(this);
        this.acoesExcluirAutor = this.acoesExcluirAutor.bind(this);
        this.acoesExcluirReu = this.acoesExcluirReu.bind(this);
        this.acoesExcluirAdvogado = this.acoesExcluirAdvogado.bind(this);
        this.confirmaExclusaoReu = this.confirmaExclusaoReu.bind(this);
        this.aceptExcluirReu = this.aceptExcluirReu.bind(this);
        this.aceptExcluirAdvogado = this.aceptExcluirAdvogado.bind(this);
        this.confirmaExclusaoAdvogado = this.confirmaExclusaoAdvogado.bind(this);
        this.aceptExcluirObjetoAcao = this.aceptExcluirObjetoAcao.bind(this);
        this.onProcessoPushChange = this.onProcessoPushChange.bind(this);

        this.onChangeTipoPessoa = this.onChangeTipoPessoa.bind(this);
        //Lançamentos Financeiros
        // this.acoesFinanceiro = this.acoesFinanceiro.bind(this);

        this.state = {
            processo: {},
            codigo: '',
            gruposTrabalho: [],
            grupoTrabalho: null,
            pasta: '',
            nrProcesso: '',
            nrCnj: '',
            nrInstancia: null,
            system: null,
            localTramite: null,
            tiposAcao: [],
            tipoAcao: null,
            statusProcessuais: [],
            statusProcessual: null,
            areasAtuacao: [],
            areaAtuacao: null,
            tiposDecisao: [],
            tipoDecisao: null,
            dataDistribuicao: '',
            dataUltimaMovimentacao: '',
            dataCadastro: '',
            dataSentenca: '',
            dataUltimaDecisao: '',
            vlProvavel: '',
            vlPossivel: '',
            vlRemoto: '',
            vlCausa: '',
            objetosAcao: [],
            objetoAcao: null,
            dsPedidos: '',
            responsavel: null,
            sistema: null,
            pagamentos: [],
            processoPush: false,
            processoPushEmail: false,
            dialogSelectSystem: false,
            modalLoad: '',
            //Partes
            //advogados foi alterado para outras partes, sempre que ver advogdo lembre-se que é outra parte
            modalAutores: false,
            modalReus: false,
            modalAdvogados: false,
            visibleAutor: false,
            visibleReu: false,
            visibleAdvogados: false,
            codigoParte: '',
            autores: [],
            reus: [],
            //advogados foi alterado para outras partes, sempre que ver advogdo lembre-se que é outra parte
            // advogados: [],
            outrasPartes: [],
            autor: '',
            reu: '',
            outraParte: "",
            // advogado: '',
            objetos: '',
            objetosProcesso: [],
            classeDivAutor: 'hide',
            classeDivReu: 'hide',
            classeDivAdvogado: 'hide',
            classeDiv: 'hide',
            classeFile: 'hide',
            selectedAutor: null,
            selectedReu: null,
            selectedAdvogado: null,
            filterPessoas: null,
            filterPessoasPA: null,
            filterPessoasPP: null,
            filterPessoasOP: null,
            filterPessoasParteContraria: null,
            filterPessoasAdvogados: null,
            pessoas: [],
            pessoasParteContraria: [],
            pessoasAdvogados: [],
            iconAutor: 'pi pi-plus-circle',
            classeAutor: 'p-button-rounded p-button-text',
            iconReu: 'pi pi-plus-circle',
            classeReu: 'p-button-rounded p-button-text',
            iconAdvogado: 'pi pi-plus-circle',
            classeAdvogado: 'p-button-rounded p-button-text',
            toolTipAutor: 'Clique para adicionar polo ativo',
            toolTipReu: 'Clique para adicionar polo passivo',
            toolTipAdvogado: 'Clique para adicionar outra parte',
            codigoObjeto: '',
            visibleObjeto: false,
            modalObjetos: false,
            //Pagamentos de Processo
            codigoPagamento: '',
            modalPagamentos: false,
            tipo: '',
            pagamento: null,
            tiposPagamentos: [],
            tipoPagamento: null,
            vlPagamento: '',
            dtPagamento: '',
            visiblePagamento: false,
            vlCredito: 0,
            vlDebito: 0,
            saldo: 0,
            corSaldo: '',
            //Custas do Processo
            codigoCustas: '',
            modalCustas: false,
            custasProcessos: [],
            custas: null,
            vlCustas: '',
            visibleCusas: false,
            vlCreditoCustas: 0,
            vlDebitoCustas: 0,
            saldoCustas: 0,
            corSaldoCustas: '',
            tiposCustas: [],
            tipoCusta: null,
            //Garantias de Processo
            codigoGarantia: '',
            garantiasProcesso: [],
            tipoGarantia: null,
            tiposGarantias: [],
            vlPenhora: '',
            visibleGarantias: false,
            modalGarantias: false,
            totalPenhora: 0,
            // //historico do Processo
            modalHistorico: false,
            oldTipoDecisao: null,
            //processo Importancia
            iconeImportanteParaMim: 'p-button-rounded p-button-secondary',
            iconeImportanteParaMimTitle: 'Clique para marcar este processo como importante',
            iconeImportanteParaEmpresa: '',
            iconeImportanteParaEmpresaTitle: '',
            processoImportancia: null,

            //Lançamentos Financeiros
            lancamentosFinanceiros: [],
            lancamento: null,
            displayConsultaLancamento: false,
            displayPagarLancamento: false,
            categoriaNome: '',
            tipoNome: '',
            vlLancamento: '',
            dtVencimento: '',
            vlPago: '',
            dtPago: '',
            dsLancamento: '',
            observacao: '',
            situacao: '',
            vlLancamentoNovo: '',
            dtVencimentoNovo: '',
            modalDespesaPagamento: false,
            modalReceitaPagamento: false,
            //Cadastro de nova Pessoa
            modalNovoCliente: null,
            nome: '',
            selectTipo: null,
            perfil: null,
            selectCidade: null,
            selectEstado: null,
            estados: [],
            cidades: [],
            openSpinner: true,
            openGpTrab: false,
            //modal status processual
            openStatus: false,
            //modal area atuacao
            openAreaAtuacao: false,
            //modal area atuacao
            openTipoAcao: false,
            //modal tipo decisao
            openTipoDesicao: false,
            loading: false,
            subObjetos: [],
            // liminar
            cumprimentoLiminar: null,
            statusLiminar: null,
            cumprimentoLiminarOptions: [],
            statusLiminarOptions: []


        };

        this.tiposPessoa = [
            {codigo: 'F', nome: 'Física'},
            {codigo: 'J', nome: 'Jurídica'}
        ]

        addLocale('es', {
            firstDayOfWeek: 1,
            dayNames: ['Domingo', 'Domingo', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
            dayNamesShort: ['Domingo', 'Domingo', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
            dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Stembro', 'Outubro', 'Novembro', 'Dezemro'],
            monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            today: 'Hoy',
            clear: 'Claro'
        });
    }

    listarStatusAtividade = async () => {
        const usuarioLogado = this.context.usuarioAutenticado;
        await this.statusAtividadeService.listarStatus(usuarioLogado.empresa.codigo)
            .then(response => {
                this.setState({statusAtividade: response.data});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Status de atividade',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            });
    }


    onChangeTipoPessoa(e) {
        this.setState({selectTipo: e.value});
    }

    fecharModal(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    abrirModalCadastroCliente(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }
        this.setState(state);
    }


    carregaCombosConsulta = () => {
        const usuarioLogado = this.context.usuarioAutenticado
        this.listarGruposTrabalho(usuarioLogado);
        this.listarTiposAcao(usuarioLogado.empresa);
        this.listarStatusProcessual(usuarioLogado.empresa);
        this.listarTiposDecisao(usuarioLogado.empresa);
        this.listarObjetosAcao(usuarioLogado.empresa);
        this.listarAreaAtuacao(usuarioLogado.empresa);
    }

    listarGruposTrabalho = (usuario) => {
        this.grupoTrabalhoService.listarGruposUsuario(usuario.codigo)
            .then(response => {
                this.setState({gruposTrabalho: response.data});
            }).catch(() => {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'Erro ao carregar grupos de trabalhos do usuário',
                life: 4000
            });
        });
    }

    listarTiposGarantias = async () => {
        const usuarioLogado = this.context.usuarioAutenticado
        await this.tipoGarantiaService.listarGarantias(usuarioLogado.empresa.codigo)
            .then(response => {
                this.setState({tiposGarantias: response.data});
            }).catch(() => {
                this.toast.show({
                    severity: 'error',
                    summary: 'Tipos de garantias',
                    detail: 'Erro ao carregar tipos de garantias',
                    life: 4000
                });
            })
    }

    listarTiposAcao = (empresa) => {
        this.tipoAcaoService.listarTipoAcao(empresa.codigo)
            .then(response => {
                this.setState({tiposAcao: response.data});
            }).catch(() => {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'Erro ao carregar tipos de ação',
                life: 4000
            });
        })
    }

    listarStatusProcessual = (empresa) => {
        this.statusProcessualService.listarStatusProcessual(empresa.codigo)
            .then(response => {
                this.setState({statusProcessuais: response.data});
            }).catch(() => {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'Erro ao carregar status processual',
                life: 4000
            });
        })
    }

    listarTiposDecisao = (empresa) => {
        this.tipoDecisaoService.listarTiposDecisao(empresa.codigo)
            .then(response => {
                this.setState({tiposDecisao: response.data});
            }).catch(() => {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'Erro ao carregar tipos de decisão',
                life: 4000
            });
        })
    }

    listarObjetosAcao = async (empresa) => {
        const filtro = {
            empresa: empresa.codigo,
            processo: this.state.processo.codigo
        }
        await this.objetoAcaoService.listarObjetosForaProcesso(filtro)
            .then(response => {
                this.setState({objetosAcao: response.data});
            }).catch(() => {
                this.toast.show({
                    severity: 'error',
                    summary: 'Processos',
                    detail: 'Erro ao carregar objetos de ação',
                    life: 4000
                });
            })
    }

    listarAreaAtuacao = (empresa) => {
        this.areaAtuacaoService.listarAreaAtuacao(empresa.codigo)
            .then(response => {
                this.setState({areasAtuacao: response.data});
            }).catch(() => {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'Erro ao carregar áreas de atuação',
                life: 4000
            });
        })
    }

    //pessoas
    novaPessoa() {
        this.abrirModalCadastroCliente('modalNovoCliente');
        this.setState({
            perfil: {
                codigo: 'AU'
            }
        })
    }

    listarPessoasEmpresa = async () => {
        const usuarioLogado = this.context.usuarioAutenticado
        await this.pessoaService.listarPessoas(usuarioLogado.empresa.codigo)
            .then(response => {

                this.setState({pessoas: response.data});
            }).catch(() => {
                console.log('Erro ao carregar pessoas');
            })
    }

    consultarPessoa(event) {
        setTimeout(() => {
            let filterPessoas;
            if (!event.query.trim().length) {
                filterPessoas = [...this.state.pessoas];
            } else {
                if (event.query.toLowerCase().length > 2) {
                    filterPessoas = this.state.pessoas.filter((pessoa) => {
                        return pessoa.nome.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }
            this.setState({[event.originalEvent.target.name]: filterPessoas})
        }, 250);
    }

    acoesExcluirReu(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text"
                        tooltip="Retirar polo passivo do processo"
                        onClick={() => this.confirmaExclusaoReu(rowData)}
                        tooltipOptions={{position: 'top'}}
                        style={{width: '20px', height: '10px'}}/>
            </React.Fragment>
        );
    }

    acoesExcluirAdvogado(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text"
                        tooltip="Retirar outras partes do processo"
                        id={"btn-excluir-"+rowData.pessoa.nome.split(" ")[0].toLowerCase()}
                        onClick={() => this.confirmaExclusaoAdvogado(rowData)}
                        tooltipOptions={{position: 'top'}}
                        style={{width: '20px', height: '10px'}}/>
            </React.Fragment>
        );
    }

    abrirModalReus(name, position) {
        this.consultarReus();
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }
        this.setState(state);
    }

    abrirModalAdvogados(name, position) {
        this.consultarAdvogados();
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }
        this.setState(state);
    }

    excluirParteAdvogado = async () => {
        await this.processoService.excluirParte(this.state.codigoParte)
            .then(() => {
                this.toast.show({
                    severity: 'success',
                    summary: 'Partes do processo',
                    detail: 'Parte retirada do processo',
                    life: 4000
                });
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Partes do processo',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            })

        this.retornaAdvogado(this.state.processo.codigo);
    }

    excluirParteReu = async () => {
        this.setState({classeDiv: 'show'});
        await this.processoService.excluirParte(this.state.codigoParte)
            .then(() => {
                this.toast.show({
                    severity: 'success',
                    summary: 'Partes do processo',
                    detail: 'Parte retirada do processo',
                    life: 4000
                });
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Partes do processo',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            })

        this.listarPoloPassivo(this.state.processo.codigo);
        this.setState({classeDiv: 'hide'});
    }

    confirmaExclusaoReu(parte) {
        this.setState({codigoParte: parte.codigo});
        confirmDialog({
            message: 'Deseja realmente retirar o polo passivo do processo?',
            header: 'Retirada de polo passivo',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: this.aceptExcluirReu,
            reject: this.reject
        });
    }

    aceptExcluirReu() {
        this.excluirParteReu();
    }

    confirmaExclusaoAdvogado(parte) {
        this.setState({codigoParte: parte.codigo});
        confirmDialog({
            message: 'Deseja realmente retirar outras partes do processo?',
            header: 'Retirada de outras partes',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: this.aceptExcluirAdvogado,
            reject: this.reject
        });
    }

    aceptExcluirAdvogado() {
        this.excluirParteAdvogado();
    }

    consultarReus = async () => {
        const filtro = {
            processo: this.state.processo.codigo,
            tipo: 'PP'
        }

        await this.processoService.listarPartesProcessoTipo(filtro)
            .then(response => {
                this.setState({reus: response.data});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({severity: 'error', summary: 'Processos', detail: `${this.state.detail}`, life: 4000});
            })
    }

    consultarAdvogados = async () => {
        const filtro = {
            processo: this.state.processo.codigo,
            tipo: 'OP'
            //advogados foi alterado para outras partes, sempre que ver advogdo lembre-se que é outra parte
        }

        await this.processoService.listarPartesProcessoTipo(filtro)
            .then(response => {
                this.setState({advogados: response.data});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({severity: 'error', summary: 'Processos', detail: `${this.state.detail}`, life: 4000});
            })
    }

    renderFooterPartes(name) {
        return (
            <div>
                <Button label="Fechar"
                        id={"btn-close-dialog"}
                        className={strings.buttonSecondaryStyle}
                        onClick={() => this.fecharModalCadastro(name)}/>
            </div>
        );
    }

    acoesExcluirAutor(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text"
                        tooltip="Retirar polo ativo do processo"
                        onClick={() => this.confirmaExclusaoAutor(rowData)}
                        tooltipOptions={{position: 'top'}}
                        style={{width: '20px', height: '10px'}}/>
            </React.Fragment>
        );
    }

    abrirModalAutores(name, position) {
        this.consultarParte("PA");
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }
        this.setState(state);
    }

    consultarParte = async (tipo) => {
        const filtro = {
            processo: this.state.processo.codigo,
            tipo: tipo
        }
        await this.processoService.listarPartesProcessoTipo(filtro)
            .then(response => {
                this.setState({autores: response.data});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({severity: 'error', summary: 'Processos', detail: `${this.state.detail}`, life: 4000});
            })
    }

    excluirParteAutor = async () => {
        this.setState({classeDiv: 'show'});
        await this.processoService.excluirParte(this.state.codigoParte)
            .then(() => {
                this.toast.show({
                    severity: 'success',
                    summary: 'Partes do processo',
                    detail: 'Parte retirada do processo',
                    life: 4000
                });
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Partes do processo',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            })

        this.listarPoloAtivo(this.state.processo.codigo);
        this.setState({classeDiv: 'hide'});
    }

    confirmaExclusaoAutor(parte) {
        this.setState({codigoParte: parte.codigo});
        confirmDialog({
            message: 'Deseja realmente retirar o polo ativo do processo?',
            header: 'Retirada de polo ativo',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: this.aceptExcluirAutor,
            reject: this.reject
        });
    }

    aceptExcluirAutor() {
        this.excluirParteAutor();
    }

    confirmaExclusaoObjetoAcao(objeto) {
        this.setState({codigoObjeto: objeto.codigo});
        console.log('Objeto para ser excluído')
        confirmDialog({
            message: 'Deseja realmente excluir o objeto de ação?',
            header: 'Exclusão de objeto de ação',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: this.aceptExcluirObjetoAcao,
            reject: this.reject
        });
    }

    aceptExcluirObjetoAcao() {
        this.excluirObjetoAcao();
    }

    excluirObjetoAcao = async () => {
        this.setState({classeDiv: 'show'});
        const {processo, codigoObjeto, objetosProcesso} = this.state;
        await this.objetoAcaoProcessoService.excluirPorCodigoProcessoAndObejto(processo.codigo, codigoObjeto)
            .then(() => {
                this.toast.show({
                    severity: 'success',
                    summary: 'Objetos de ação',
                    detail: 'Objeto de ação excluído',
                    life: 4000
                });
                const usuarioLogado = this.context.usuarioAutenticado
                this.listarObjetosAcao(usuarioLogado.empresa);
// Pesquisar o índice do objeto com o código a ser removido
                let index = -1;
                for (let i = 0; i < objetosProcesso.length; i++) {
                    if (objetosProcesso[i].codigo === codigoObjeto) {
                        index = i;
                        break;
                    }
                }
// Remover o objeto do array
                if (index !== -1) {
                    const novoArray = [...objetosProcesso]; // Cria uma cópia do array original
                    novoArray.splice(index, 1); // Remove o objeto do novo array

                    this.setState({
                        objetosProcesso: novoArray, // Atualiza o estado com o novo array
                        subObjetos: novoArray.filter(obj => obj.objetoPai !== null),// Atualiza o estado com o novo array
                    });
                }
                // this.consultarObjetosPorProcesso(this.state.processo.codigo);
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Objetos de ação',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            })

        this.setState({classeDiv: 'hide'});
    }

    listarTiposPagamentos = async () => {
        const usuarioLogado = this.context.usuarioAutenticado
        await this.tipoPagamentoService.listarTiposPagamentos(usuarioLogado.empresa.codigo)
            .then(response => {
                this.setState({tiposPagamentos: response.data});
            }).catch(error => {
                console.log('Erro ao listar os tipos de pagamentos', error.data);
                this.setState(error.response.data);
                this.toast.show({severity: 'error', summary: 'Processos', detail: `${this.state.detail}`, life: 4000});
            })
    }

    onChangeTipoPagamento(e) {
        this.setState({tipoPagamento: e.value});
    }

    onChangeTipoCustas(e) {
        this.setState({tipoCusta: e.value});
    }

    onChangeStatusProcessual(e) {
        this.setState({statusProcessual: e.value})
    }

    onChangeGrupoTrabalho(e) {
        this.setState({grupoTrabalho: e.value})
    }

    onChangeAreaAtuacao(e) {
        this.setState({areaAtuacao: e.value})
    }

    onChangeTipoAcao(e) {
        this.setState({tipoAcao: e.value})
    }

    listarPessoasEmpresaParteCliente = async () => {
        const usuarioLogado = this.context.usuarioAutenticado
        await this.pessoaService.listarPessoasEmpresaPerfil(usuarioLogado.empresa.codigo, 'CL')
            .then(response => {
                this.setState({pessoas: response.data});
            }).catch(() => {
                console.log('Erro ao carregar pessoas');
            })
    }

    listarPessoasEmpresaParteContraria = async () => {
        const usuarioLogado = this.context.usuarioAutenticado
        await this.pessoaService.listarPessoasEmpresaPerfil(usuarioLogado.empresa.codigo, 'PC')
            .then(response => {
                this.setState({pessoasParteContraria: response.data});
            }).catch(() => {
                console.log('Erro ao carregar pessoas');
            })
    }

    listarPessoasEmpresaAdvogados = async () => {
        const usuarioLogado = this.context.usuarioAutenticado
        await this.pessoaService.listarPessoasEmpresaPerfil(usuarioLogado.empresa.codigo, "AD")
            .then(response => {
                this.setState({pessoasAdvogados: response.data});
            }).catch(() => {
                console.log('Erro ao carregar pessoas');
            })
    }


    load(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }
        this.setState(state);
    }

    async componentDidMount() {
        //await this.load('modalLoad');
        //this.setState({modalLoad: 'modal_loader_container mostrar'})
        this.setState({openSpinner: true})
        const params = this.props.match.params;
        if (params.codigo) {
            await this.consultarProcesso(params.codigo);
        }
        await this.listarPessoasEmpresa()
        await this.carregaCombosConsulta();
        await this.consultarProcessoImportancia();
        await this.getCumprimentoOptions()
        await this.getStatusLiminarOptions()
        await this.getFilas()
        this.setState({openSpinner: false})

    }


    getFilas =()=>{

        this.processoAutoService.getSistemas().then((res) => {
            const data = res.data
            this.setState({filas: data})
            this.findSystemBySystemName(data)
        }).catch(
            (error) => {
                const errorData = error.response.data
                const detail = errorData.detail || "Erro ao buscar sistemas"
                console.log(errorData)
                this.toast.show({
                    severity: 'error',
                    summary: 'Erro',
                    detail: `${detail}`,
                    life: 4000
                })
            }
        )
    }

    findSystemBySystemName = (filas) => {
        const { system } = this.state;
        if (system) {
            // Corrigido para usar igualdade ao invés de includes
            let sistema = filas.find((sistema) => sistema.systemName === system);
            
            // Atualiza o estado com o sistema encontrado
            this.setState({ system: sistema });
            
        }
    }
    

    // consultarObjetosPorProcesso = async (codigo) => {
    //     await this.objetoAcaoProcessoService.consultarObjetos(codigo)
    //         .then(response => {
    //             this.setState({objetosProcesso: response.data});
    //             let objetos = '';
    //             let contador = 0;
    //             const tamanhoLista = response.data.length;
    //             for (let i = 0; i < response.data.length; i++) {
    //                 if (contador === tamanhoLista - 1) {
    //                     objetos = objetos + response.data[i].objeto.nome;
    //                 } else {
    //                     objetos = objetos + response.data[i].objeto.nome + ',';
    //                 }
    //                 contador++;
    //             }
    //             this.setState({objetos: objetos});
    //         }).catch(error => {
    //             this.setState(error.response.data);
    //             this.toast.show({severity: 'error', summary: 'Processos', detail: `${this.state.detail}`, life: 4000});
    //         });
    // }
    formatarObejtosAcaoProcesso = (obejtosList) => {
        let objetos = '';
        let contador = 0;
        const tamanhoLista = obejtosList.length;
        for (let i = 0; i < obejtosList.length; i++) {
            if (contador === tamanhoLista - 1) {
                objetos = objetos + obejtosList[i].nome;
            } else {
                objetos = objetos + obejtosList[i].nome + ',';
            }
            contador++;
        }
        this.setState({objetos: objetos});
    }

    consultarProcesso = async (codigo) => {
        await this.processoService.consultarFichaProcessoPorCodigo(codigo)
            .then(response => {
                this.preencherDados(response.data)

            }).catch(error => {
                 let detail = error.response.data.detail || "Erro ao tentar consultar processo"
                this.toast.show({severity: 'error', summary: 'Processos', detail: `${detail}`, life: 4000});
            })
    }

    consultarLancamentosFinanceiros = (processo) => {
        this.processoService.consultarLancamentosFinanceiros(processo)
            .then(response => {
                this.setState({lancamentosFinanceiros: response.data});
            }).catch(error => {
            this.setState(error.response.data);
            this.toast.show({
                severity: 'error',
                summary: 'Processos - Lançamentos financeiros',
                detail: `${this.state.detail}`,
                life: 4000
            });
        })
    }


    renderFooterConsultaLancamento(name) {
        return (
            <div className="right flex-1 gap-1">
                <Button label="Reabrir"
                        className="p-button-info p-button-text"
                        onClick={this.reabrirLancamento}/>
                <Button label="Pagar"
                        className="p-button-success p-button-text"
                        onClick={this.consultarLancamentoPagamento}/>
                <Button label="Fechar"
                        className={strings.buttonSecondaryStyle}
                        onClick={() => this.fecharModal(name)}/>
            </div>
        );
    }

    abrirModaConsultaLancamanto(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }
        this.setState(state);
    }

    reabrirLancamento = () => {
        if (this.state.lancamento.situacao === 'A') {
            this.toast.show({severity: 'error', summary: 'Pagamento', detail: 'O lançamento está ativo', life: 4000});
            return false;
        }
        this.financeiroService.reabrirLancamento({
            codigo: this.state.lancamento.codigo,
            situacao: 'A'
        }).then(() => {
            this.toast.show({severity: 'success', summary: 'Pagamento', detail: 'Lançamento reaberto', life: 4000});
            this.fecharModal('displayConsultaLancamento');
            this.consultarLancamentosFinanceiros(this.state.processo.codigo);
        }).catch(error => {
            this.setState(error.response.data);
            this.toast.show({severity: 'error', summary: 'Pagamento', detail: `${this.state.detail}`, life: 4000});
        })
    }

    consultarLancamentoPagamento = () => {
        this.setState({vlLancamentoNovo: ''});
        this.setState({dtVencimentoNovo: ''});
        if (this.state.lancamento.tipo.tipo === 'D') {
            this.abrirModaConsultaLancamanto('modalDespesaPagamento')
        } else {
            this.abrirModal('modalReceitaPagamento')
        }
    }
    pagarDespesaReceita = async () => {
        const usuarioLogado = this.context.usuarioAutenticado

        if (this.state.lancamento.situacao === 'P') {
            this.toast.show({severity: 'error', summary: 'Pagamento', detail: 'Lançamento já pago', life: 4000});
            return false;
        }

        //Se o campo de novo valor estiver preenchido, paga o novo valor e cria um novo lancamento com a diferença
        if (this.state.vlLancamentoNovo !== '') {
            //Efetua pagamento
            if (this.state.dtVencimentoNovo === '') {
                this.toast.show({
                    severity: 'error',
                    summary: 'Pagamento',
                    detail: 'Informe a data de vencimento',
                    life: 4000
                });
                return false;
            }

            await this.financeiroService.pagarOuCancelarLancamentoIndividual({
                codigo: this.state.lancamento.codigo,
                situacao: 'P',
                vlPago: this.state.vlLancamentoNovo,
                observacao: this.state.observacao
            }).then(() => {
                this.toast.show({severity: 'success', summary: 'Pagamento', detail: 'Pagamento efetuado', life: 4000});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({severity: 'error', summary: 'Pagamento', detail: `${this.state.detail}`, life: 4000});
            });

            //Data de vencimento
            var novoValor = this.state.vlLancamento - this.state.vlLancamentoNovo;
            var dataFormatadaDataVencimento = '';
            if (this.state.dtVencimentoNovo !== '') {
                var date = this.state.dtVencimentoNovo;
                var month = this.pad2(date.getMonth() + 1);//months (0-11)
                var day = this.pad2(date.getDate());//day (1-31)
                var year = date.getFullYear();
                dataFormatadaDataVencimento = year + "-" + month + "-" + day;
            }


            this.financeiroService.cadastrarLancamento({
                tipo: this.state.tipo,
                vlLancamento: novoValor,
                dtVencimento: dataFormatadaDataVencimento,
                observacao: 'Diferença do pagamento anterior',
                situacao: 'A',
                usuario: usuarioLogado.codigo,
                vlPago: '0',
                processo: this.state.lancamento.processo
            }).then(() => {
                this.toast.show({
                    severity: 'success',
                    summary: 'Cadastro de lançamento',
                    detail: 'Novo lançamento cadastrado',
                    life: 4000
                });
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Cadastro de lançamento',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            });

            this.fecharModal('modalDespesaPagamento');
            this.fecharModal('displayConsultaLancamento');
            this.consultarLancamentosFinanceiros(this.state.processo.codigo);

        } else {
            await this.financeiroService.pagarOuCancelarLancamentoIndividual({
                codigo: this.state.lancamento.codigo,
                situacao: 'P',
                vlPago: this.state.vlLancamento,
                observacao: this.state.observacao
            }).then(() => {
                this.toast.show({
                    severity: 'success',
                    summary: 'Pagamento de despesa',
                    detail: 'Pagamento efetuado',
                    life: 4000
                });
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Pagamento de despesa',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            });

            this.fecharModal('modalDespesaPagamento');
            this.fecharModal('displayConsultaLancamento');
            this.consultarLancamentosFinanceiros(this.state.processo.codigo);
        }
    }

    formatarAutorOrReu = (autoresOrReus) => {
        let polos = '';
        const tamanhoLista = autoresOrReus.length;

        for (let i = 0; i < tamanhoLista; i++) {
            const autorOrReu = autoresOrReus[i];
            const nome = autorOrReu.nome;
            const cdconselho = autorOrReu.cdconselho;
            const perfilNome = autorOrReu.perfil ? autorOrReu.perfil.nome : '';

            polos += nome;

            if (perfilNome) {
                polos += ` - ${perfilNome}`;
            }

            if (cdconselho) {
                polos += ` - ${cdconselho}`;
            }

            if (i < tamanhoLista - 1) {
                polos += ', ';
            }
        }

        return polos;
    }
    // retornaReu = async (codigo) => {
    //     const parte = {
    //         processo: codigo,
    //         tipo: 'R'
    //     }
    //     await this.processoService.listarPartes(parte)
    //         .then(response => {
    //             this.setState({reus: response.data});
    //         }).catch(error => {
    //             this.setState(error.response.data);
    //             this.toast.show({severity: 'error', summary: 'Processos', detail: `${this.state.detail}`, life: 4000});
    //         });
    //
    //     let reu = '';
    //     let contador = 0;
    //     const tamanhoLista = this.state.reus.length;
    //     for (let i = 0; i < this.state.reus.length; i++) {
    //         if (contador === tamanhoLista - 1) {
    //             reu = reu + this.state.reus[i].pessoa.nome;
    //         } else {
    //             reu = reu + this.state.reus[i].pessoa.nome + ", ";
    //         }
    //         contador++;
    //     }
    //
    //     this.setState({reu: reu});
    // }

    listarPoloAtivo = async (codigo) => {
        const parte = {
            processo: codigo,
            tipo: 'PA'
        }
        await this.processoService.listarPartes(parte)
            .then(response => {
                let pessoas = response.data.map(item=> (item.pessoa))
                this.setState({autores: response.data,
                    autor: this.formatarAutorOrReu(pessoas)
                });
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({severity: 'error', summary: 'Processos', detail: `${this.state.detail}`, life: 4000});
            });
    }
    listarPoloPassivo = async (codigo) => {
        const parte = {
            processo: codigo,
            tipo: 'PP'
        }
        await this.processoService.listarPartes(parte)
            .then(response => {
                // this.setState({advogados: response.data});
                let pessoas = response.data.map(item=> (item.pessoa))
                this.setState({reus: response.data,
                    reu: this.formatarAutorOrReu(pessoas)
                });
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({severity: 'error', summary: 'Processos', detail: `${this.state.detail}`, life: 4000});
            });

    }
    retornaAdvogado = async (codigo) => {
        const parte = {
            processo: codigo,
            tipo: 'OP'
        }
        await this.processoService.listarPartes(parte)
            .then(response => {
                // this.setState({advogados: response.data});
                let pessoas = response.data.map(item=> (item.pessoa))
                this.setState({outrasPartes: response.data,
                    outraParte: this.formatarAutorOrReu(pessoas)
                });
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({severity: 'error', summary: 'Processos', detail: `${this.state.detail}`, life: 4000});
            });

        // let advogado = '';
        // let contador = 0;
        // const tamanhoLista = this.state.advogados.length;
        // for (let i = 0; i < this.state.advogados.length; i++) {
        //     if (contador === tamanhoLista - 1) {
        //         advogado = advogado + this.state.advogados[i].pessoa.nome;
        //     } else {
        //         advogado = advogado + this.state.advogados[i].pessoa.nome + ", ";
        //     }
        //     contador++;
        // }
        //
        // this.setState({advogado: advogado});
    }

    pagamentosProcesso = async (codigo) => {
        await this.pagamentoProcessoService.listarPagamentos(codigo)
            .then(response => {
                this.calculaPagamentos(response.data)
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({severity: 'error', summary: 'Processos', detail: `${this.state.detail}`, life: 4000});
            })
    }

    calculaPagamentos = (data) => {
        this.setState({pagamentos: data});
        //Resumo
        let vlCredito = 0;
        let vlDebito = 0;
        let saldo
        for (let i = 0; i < this.state.pagamentos.length; i++) {
            if (this.state.pagamentos[i].tipo === 'Crédito') {
                vlCredito = vlCredito + this.state.pagamentos[i].vlPagamento;
            } else {
                vlDebito = vlDebito + this.state.pagamentos[i].vlPagamento;
            }
        }
        saldo = vlCredito - vlDebito;
        this.setState({vlCredito: vlCredito});
        this.setState({vlDebito: vlDebito});
        this.setState({saldo: saldo});
        this.setState({corSaldo: saldo < 0 ? 'vermelho' : 'verde'});

    }

    adicionarAutor = () => {
        if (this.state.classeDivAutor === 'hide') {
            this.setState({classeDivAutor: 'show'});
            this.setState({iconAutor: 'pi pi-angle-double-left'});
            this.setState({classeAutor: 'p-button-rounded  p-button-secondary p-button-text'});
            this.setState({toolTipAutor: 'Cancelar adicionar polo ativo'});
            this.setState({selectedAutor: null});
        } else {
            this.setState({classeDivAutor: 'hide'});
            this.setState({iconAutor: 'pi pi-plus-circle'});
            this.setState({classeAutor: 'p-button-rounded p-button-text'});
            this.setState({toolTipAutor: 'Clique para adicionar polo ativo'})
        }
    }

    adicionarReu = () => {
        if (this.state.classeDivReu === 'hide') {
            this.setState({classeDivReu: 'show'});
            this.setState({iconReu: 'pi pi-angle-double-left'});
            this.setState({classeReu: 'p-button-rounded  p-button-secondary p-button-text'});
            this.setState({toolTipReu: 'Cancelar adicionar polo passivo'});
            this.setState({selectedReu: null});
        } else {
            this.setState({classeDivReu: 'hide'});
            this.setState({iconReu: 'pi pi-plus-circle'});
            this.setState({classeReu: 'p-button-rounded p-button-text'});
            this.setState({toolTipReu: 'Clique para adicionar polo passivo'})
        }
    }

    adicionarAdvogado = () => {
        if (this.state.classeDivAdvogado === 'hide') {
            this.setState({classeDivAdvogado: 'show'});
            this.setState({iconAdvogado: 'pi pi-angle-double-left'});
            this.setState({classeAdvogado: 'p-button-rounded  p-button-secondary p-button-text'});
            this.setState({toolTipAdvogado: 'Cancelar adicionar outras partes'});
            this.setState({selectedAdvogado: null});
        } else {
            this.setState({classeDivAdvogado: 'hide'});
            this.setState({iconAdvogado: 'pi pi-plus-circle'});
            this.setState({classeAdvogado: 'p-button-rounded p-button-text'});
            this.setState({toolTipAdvogado: 'Clique para adicionar outras partes'})
        }
    }

    acoesObjetosAcao(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text"
                        tooltip="Retirar objeto"
                        tooltipOptions={{position: 'top'}}
                        onClick={() => this.confirmaExclusaoObjetoAcao(rowData)}
                        style={{width: '20px', height: '10px'}}/>
            </React.Fragment>
        );
    }

    onTipoDecisaoChange(e) {
        if (e.value && e.value.codigo !== this.state.tipoDecisao.codigo) {
            this.setState({oldTipoDecisao: this.state.tipoDecisao})
            this.setState({tipoDecisao: e.value, modalHistorico: true});
        }
    }

    cancelTipoDecisao = () => {
        this.setState({modalHistorico: false, tipoDecisao: this.state.oldTipoDecisao});
    }

    pad2 = (n) => {
        return (n < 10 ? '0' : '') + n;
    }

    formatCurrency(value) {
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    }

    vlPagamento(rowData) {
        return this.formatCurrency(rowData.vlPagamento);
    }

    vlCustas(rowData) {
        return this.formatCurrency(rowData.vlCustas);
    }

    vlPenhora(rowData) {
        return this.formatCurrency(rowData.vlPenhora);
    }

    acoesPagamentosProcesso(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text"
                        tooltip="Excluir pagamento"
                        id={"delete-pagamento"}
                        onClick={() => this.confirmaExclusaoPagamento(rowData)}
                        tooltipOptions={{position: 'top'}}
                        style={{width: '20px', height: '10px'}}/>
            </React.Fragment>
        );
    }

    headerObjetosAcao() {
        return (
            <div>
                <label className="row gap-1">Objetos de ação</label>
            </div>
        )
    }

    headerResumoPagamentos() {
        return (
            <div id={"pagamentos-processo"}>
                <label className="row gap-1">Pagamentos:
                    <span className="verde">Credito: {this.formatCurrency(this.state.vlCredito)}</span>
                    <span className="vermelho">Débito: {this.formatCurrency(this.state.vlDebito)}</span>
                    <span className={this.state.corSaldo}>Saldo: {this.formatCurrency(this.state.saldo)}</span></label>
            </div>
        );
    }

    headerResumoCustas() {
        return (
            <div id={"custas-processo"}>
                <label className="row gap-1">Custas:
                    <span className="verde">Credito: {this.formatCurrency(this.state.vlCreditoCustas)}</span>
                    <span className="vermelho">Débito: {this.formatCurrency(this.state.vlDebitoCustas)}</span>
                    <span
                        className={this.state.corSaldoCustas}>Saldo: {this.formatCurrency(this.state.saldoCustas)}</span></label>
            </div>
        )
    }

    acoesCustasProcesso(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text"
                        tooltip="Excluir custas"
                        id={"delete-custas"}
                        onClick={() => this.confirmaExclusaoCusta(rowData)}
                        tooltipOptions={{position: 'top'}}
                        style={{width: '20px', height: '10px'}}/>
            </React.Fragment>
        );
    }

    renderFooterCadastroPagamentos(name) {
        return (
            <div className="row gap-1">
                <Button label="Cadastrar"
                        id={"btn-confirm-pagamentos"}
                        className={strings.buttonPrimaryStyle}
                        onClick={this.incluirPagamento}/>
                <Button label="Cancelar"
                        id={"btn-cancel-pagamentos"}
                        className={strings.buttonSecondaryStyle}
                        onClick={() => this.fecharModalCadastro(name)}/>
            </div>
        );
    }

    fecharModalCadastro(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    abrirModalCadastroPagamentos(name, position) {
        this.listarTiposPagamentos();
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }
        this.limparCamposPagamentos();
        this.setState(state);
    }

    limparCamposPagamentos = () => {
        this.setState({
            tipoPagamento: null,
            dtPagamento: '',
            tipo: '',
            vlPagamento: ''
        });
    }

    renderFooterCadastroCustas(name) {
        return (
            <div className="row gap-1">
                <Button label="Cadastrar"
                        id={"btn-confirm-custas"}
                        className={strings.buttonPrimaryStyle}
                        onClick={this.incluirCustas}/>
                <Button label="Cancelar"
                        id={"btn-cancel-custas"}
                        className={strings.buttonSecondaryStyle}
                        onClick={() => this.fecharModalCadastro(name)}/>
            </div>
        );
    }

    abrirModalCadastroCustas(name, position) {
        this.listarTiposCustas();
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }
        this.limparCamposCustas();
        this.setState(state);
    }

    limparCamposCustas = () => {
        this.setState({
            tipoCusta: null,
            dtPagamento: '',
            tipo: '',
            vlCustas: '',
        })
    }

    listarTiposCustas = async () => {
        const usuarioLogado = this.context.usuarioAutenticado
        await this.tipoCustaService.listarTiposCusta(usuarioLogado.empresa.codigo)
            .then(response => {
                this.setState({tiposCustas: response.data});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Tipos de custa',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            })
    }

    incluirCustas = async () => {
        if (this.state.tipoCusta == null) {
            this.toast.show({severity: 'error', summary: 'Custas', detail: 'Informe o tipo de custa', life: 4000});
            this.setState({classeDiv: 'hide'});
            return false;
        }

        if (this.state.tipo === '') {
            this.toast.show({
                severity: 'error',
                summary: 'Custas',
                detail: 'Informe o tipo débito ou crédito',
                life: 4000
            });
            this.setState({classeDiv: 'hide'});
            return false;
        }
        if (IsNullOrEmpty(this.state.vlCustas)) {
            this.toast.show({severity: 'error', summary: 'Custas', detail: 'Informe o valor da custa', life: 4000});
            this.setState({classeDiv: 'hide'});
            return false;
        }

        if (this.state.dtPagamento === '') {
            this.toast.show({
                severity: 'error',
                summary: 'Custas',
                detail: 'Informe a data do pagamanto da custa',
                life: 4000
            });
            this.setState({classeDiv: 'hide'});
            return false;
        }

        let dataFormatada = DateFormat(this.state.dtPagamento)
        this.setState({openSpinner: true});
        await this.custasProcessoService.incluirCustas({
            processo: this.state.processo,
            custas: this.state.tipoCusta,
            tipo: this.state.tipo,
            vlCustas: this.state.vlCustas,
            dtPagamento: dataFormatada
        }).then(() => {
            this.toast.show({
                severity: 'success',
                summary: 'Custas',
                detail: 'Custas incluída com sucesso',
                life: 4000
            });
            this.listarCustasProcesso(this.state.processo.codigo);
            this.fecharModalCadastro('modalCustas');
        }).catch(error => {
            this.setState(error.response.data);
            this.toast.show({severity: 'error', summary: 'Custas', detail: `${this.state.detail}`, life: 4000});
        })

        this.setState({openSpinner: false});

    }

    incluirPagamento = async () => {
        if (this.state.tipoPagamento === null) {
            this.toast.show({
                severity: 'error',
                summary: 'Pagamentos',
                detail: 'Informe o tipo de pagamento',
                life: 4000
            });
            return false;
        }

        if (this.state.tipo === '') {
            this.toast.show({
                severity: 'error',
                summary: 'Pagamentos',
                detail: 'Informe o tipo débito ou crédito',
                life: 4000
            });
            return false;
        }

        if (IsNullOrEmpty(this.state.vlPagamento)) {
            this.toast.show({
                severity: 'error',
                summary: 'Pagamentos',
                detail: 'Informe o valor do pagamento',
                life: 4000
            });
            return false;
        }

        if (IsNullOrEmpty(this.state.dtPagamento)) {
            this.toast.show({
                severity: 'error',
                summary: 'Pagamentos',
                detail: 'Informe a data de pagamento',
                life: 4000
            });
            return false;
        }

        var date = this.state.dtPagamento
        var month = this.pad2(date.getMonth() + 1);//months (0-11)
        var day = this.pad2(date.getDate());//day (1-31)
        var year = date.getFullYear() + 1;
        var dataFormatada = year + "-" + month + "-" + day;

        this.setState({openSpinner: true});

        await this.pagamentoProcessoService.incluirPagamento({
            processo: this.state.processo,
            pagamento: this.state.tipoPagamento,
            vlPagamento: this.state.vlPagamento,
            dtPagamento: dataFormatada,
            tipo: this.state.tipo
        }).then(() => {
            this.toast.show({severity: 'success', summary: 'Pagamentos', detail: 'Pagamento incluído', life: 4000});
            this.pagamentosProcesso(this.state.processo.codigo);
            this.fecharModalCadastro('modalPagamentos');
        }).catch(error => {
            this.setState(error.response.data);
            this.toast.show({severity: 'error', summary: 'Pagamentos', detail: `${this.state.detail}`, life: 4000});
        })

        this.setState({openSpinner: false});
    }

    acceptExcluirPagamento() {
        this.excluirPagamentoProcesso();
    }

    acceptExcluirCustas() {
        this.excluirCusta();
    }

    confirmaExclusaoPagamento(pagamento) {
        this.setState({codigoPagamento: pagamento.codigo});
        confirmDialog({
            message: 'Deseja realmente excluir pagamento do processo?',
            header: 'Exclusão de pagamento',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: this.acceptExcluirPagamento,
            reject: this.reject
        });
    }

    confirmaExclusaoCusta(custa) {
        this.setState({codigoCusta: custa.codigo});
        confirmDialog({
            message: 'Deseja realmente excluir a custa?',
            header: 'Exclusão de custas',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: this.acceptExcluirCustas,
            reject: this.reject
        });
    }

    excluirPagamentoProcesso = async () => {
        this.setState({classeDiv: 'show'})
        await this.pagamentoProcessoService.excluirPagamento(this.state.codigoPagamento)
            .then(() => {
                this.toast.show({severity: 'success', summary: 'Pagamentos', detail: 'Pagamento excluído', life: 4000});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({severity: 'error', summary: 'Pagamentos', detail: `${this.state.detail}`, life: 4000});
            })

        this.setState({visiblePagamento: false});
        await this.pagamentosProcesso(this.state.processo.codigo);
        this.setState({classeDiv: 'hide'})
    }

    excluirCusta = async () => {
        this.setState({classeDiv: 'show'});
        await this.custasProcessoService.excluirCustas(this.state.codigoCusta)
            .then(() => {
                this.toast.show({severity: 'success', summary: 'Custas', detail: 'Custa excluída', life: 4000});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({severity: 'error', summary: 'Custas', detail: `${this.state.detail}`, life: 4000});
            })

        this.setState({visibleCustas: false});
        await this.listarCustasProcesso(this.state.processo.codigo);
        this.setState({classeDiv: 'hide'});
    }

    listarCustasProcesso = async (codigo) => {
        await this.custasProcessoService.listarCustas(codigo)
            .then(response => {
                this.calculaCustas(response.data)
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({severity: 'error', summary: 'Custas', detail: `${this.state.detail}`, life: 4000});
            })
    }

    calculaCustas = (data) => {

        this.setState({custasProcessos: data});
        let vlCredito = 0;
        let vlDebito = 0;
        let saldo
        for (let i = 0; i < this.state.custasProcessos.length; i++) {
            if (this.state.custasProcessos[i].tipo === 'Crédito') {
                vlCredito = vlCredito + this.state.custasProcessos[i].vlCustas;
            } else {
                vlDebito = vlDebito + this.state.custasProcessos[i].vlCustas;
            }
        }
        saldo = vlCredito - vlDebito;
        this.setState({vlCreditoCustas: vlCredito});
        this.setState({vlDebitoCustas: vlDebito});
        this.setState({saldoCustas: saldo});
        this.setState({corSaldoCustas: saldo < 0 ? 'vermelho' : 'verde'});

    }

    //Garantias do Processo
    listarGarantiasProcesso = async (codigo) => {
        this.setState({openSpinner: true})
        await this.garantiaProcessoService.listarGarantiasProcesso(codigo)
            .then(response => {
                this.calculaGarantias(response.data)
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Garantias de processo',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            }).finally(()=>
                this.setState({openspinner: false})
            )

    }

    calculaGarantias = (data) => {
        this.setState({garantiasProcesso: data});
        let total = 0;
        for (let i = 0; i < this.state.garantiasProcesso.length; i++) {
            total = total + this.state.garantiasProcesso[i].vlPenhora;
        }
        this.setState({totalPenhora: total});

    }

    headerTotalPenhora() {
        return (
            <React.Fragment>
                <label id={"garantias-processo"} className="row gap-1">Garantias:
                    <span className="verde">{this.formatCurrency(this.state.totalPenhora)}</span></label>
            </React.Fragment>
        )
    }

    onChangeTipoGarantia(e) {
        this.setState({tipoGarantia: e.value});
    }

    acoesGarantiasProcesso(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text"
                        tooltip="Excluir garantia"
                        id={"delete-garantia"}
                        onClick={() => this.confirmaExclusaoGarantia(rowData)}
                        tooltipOptions={{position: 'top'}}
                        style={{width: '20px', height: '10px'}}/>
            </React.Fragment>
        );
    }

    confirmaExclusaoGarantia(garantia) {
        this.setState({codigoGarantia: garantia.codigo});
        confirmDialog({
            message: 'Deseja realmente excluir a garantia?',
            header: 'Exclusão de garantias',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: this.acceptExcluirGarantias,
            reject: this.reject
        });
    }

    acceptExcluirGarantias() {
        this.excluirGarantia();
    }

    excluirGarantia = async () => {
        this.setState({classeDiv: 'show'});
        await this.garantiaProcessoService.excluirGarantia(this.state.codigoGarantia)
            .then(() => {
                this.toast.show({severity: 'success', summary: 'Garantias', detail: 'Garantia excluída', life: 4000});
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({severity: 'error', summary: 'Garantias', detail: `${this.state.detail}`, life: 4000});
            })

        this.setState({visibleGarantias: false});
        this.listarGarantiasProcesso(this.state.processo.codigo);
        this.setState({classeDiv: 'hide'});
    }

    renderFooterCadastroGarantias(name) {
        return (
            <div className="row gap-1">
                <Button label="Cadastrar"
                        id={"cadastro-garantias"}
                        className={strings.buttonPrimaryStyle}
                        onClick={this.incluirGarantias}/>
                <Button label="Cancelar"
                        id={"cancelar-garantias"}
                        className={strings.buttonSecondaryStyle}
                        onClick={() => this.fecharModalCadastro(name)}/>
            </div>
        );
    }

    incluirGarantias = async () => {
        if (this.state.tipoGarantia === null) {
            this.toast.show({
                severity: 'error',
                summary: 'Garantias',
                detail: 'Informe o tipo de garantia',
                life: 4000
            });
            this.setState({classeDiv: 'hide'});
            return false;
        }

        if (IsNullOrEmpty(this.state.vlPenhora)) {
            this.toast.show({
                severity: 'error',
                summary: 'Garantias',
                detail: 'Informe o valor da penhora',
                life: 4000
            });
            this.setState({classeDiv: 'hide'});
            return false;
        }
        this.setState({openSpinner: true});
        await this.garantiaProcessoService.incluirGarantias({
            processo: this.state.processo,
            garantia: this.state.tipoGarantia,
            vlPenhora: this.state.vlPenhora
        }).then(() => {
            this.toast.show({
                severity: 'success',
                summary: 'Garantias',
                detail: 'Garantia incluída com sucesso',
                life: 4000
            });
            this.listarGarantiasProcesso(this.state.processo.codigo);
        }).catch(error => {
            this.setState(error.response.data);
            this.toast.show({severity: 'error', summary: 'Garantias', detail: `${this.state.detail}`, life: 4000});
        });

        this.fecharModalCadastro('modalGarantias');
        this.setState({openSpinner: false})
    }

    // subobjetos
    bodySubObjetos = (item) => {
        if (item) {
            const subs = this.state.subObjetos.filter(sub => sub.objetoPai.codigo === item.codigo)
            return (
                <span className={"mr-2"}>
                    {subs.length > 0 &&
                        <DataTable value={subs}>
                            <Column field="nome" header="Sub objetos"></Column>
                            <Column body={this.acoesSubObjetos} header="Açôes"></Column>
                        </DataTable>}
                </span>
            );
        } else {
            return null;
        }
    }

    acoesSubObjetos = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text"
                        tooltip="Retirar objeto"
                        tooltipOptions={{position: 'top'}}
                        onClick={() => this.confirmaExclusaoObjetoAcao(rowData)}
                        style={{width: '20px', height: '10px'}}/>
            </React.Fragment>
        );
    }

    abrirModalCadastroGarantias(name, position) {
        this.listarTiposGarantias();
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }
        this.limparCamposGarantias();
        this.setState(state);
    }

    limparCamposGarantias = () => {
        this.setState({
            tipoGarantia: null,
            vlPenhora: ''
        })
    }

    voltar = () => {
        const consultaIndiceJson = LocalStorageService.obterConsulta('_consulta_processo_indice')

        const consultaFiltroJson = LocalStorageService.obterConsulta('_consulta_processo_filtro')

        if (consultaIndiceJson != null) {
            this.processoService.consultarProcessoPorIndice(consultaIndiceJson)
                .then(() => {
                    this.props.history.push(`/processos?indice=${consultaIndiceJson.indice}&empresa=${consultaIndiceJson.empresa}&usuario=${consultaIndiceJson.usuario}`);
                }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({severity: 'error', summary: 'Processos', detail: `${this.state.detail}`, life: 4000});
            })
        } else if (consultaFiltroJson !== null) {

            let params = `/processos?empresa=${consultaFiltroJson.empresa.codigo}&usuario=${consultaFiltroJson.usuario}`;

            if (consultaFiltroJson.pessoa !== '') {
                params = `${params}&pessoa=${consultaFiltroJson.pessoa}`;
            }

            if (consultaFiltroJson.grupoTrabalho !== null) {
                params = `${params}&grupoTrabalho=${consultaFiltroJson.grupoTrabalho.codigo}`;
            }

            if (consultaFiltroJson.tipoAcao !== null) {
                params = `${params}&tipoAcao=${consultaFiltroJson.tipoAcao.codigo}`;
            }

            if (consultaFiltroJson.statusProcessual !== null) {
                params = `${params}&statusProcessual=${consultaFiltroJson.statusProcessual.codigo}`;
            }

            if (consultaFiltroJson.tipoDecisao !== null) {
                params = `${params}&tipoDecisao=${consultaFiltroJson.tipoDecisao.codigo}`;
            }

            if (consultaFiltroJson.pasta !== '') {
                params = `${params}&pasta=${consultaFiltroJson.pasta}`;
            }

            if (consultaFiltroJson.numeroProcesso !== '') {
                params = `${params}&numeroProcesso=${consultaFiltroJson.numeroProcesso}`;
            }

            if (consultaFiltroJson.numeroCnj !== '') {
                params = `${params}&numeroCnj=${consultaFiltroJson.numeroCnj}`;
            }

            if (consultaFiltroJson.importanteParaMim === 'SIM') {
                params = `${params}&importanteParaMim=${consultaFiltroJson.importanteParaMim}`
            }

            this.props.history.push(params);

        } else {
            this.props.history.push('/processos');
        }
    }

    alterarProcesso = async () => {
        if (this.state.selectedAutor === null && this.state.autores === null) {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'Informe o(s) autor(es) do processo',
                life: 4000
            });
            this.setState({classeDiv: 'hide'});
            return false;
        }

        if (this.state.system == null) {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'É preciso informar o sistema',
                life: 4000
            });
            this.setState({classeDiv: 'hide'});
            return false;
        }

        if (this.state.selectedReu === null && this.state.reus === null) {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'Informe o(s) réu(s) do processo',
                life: 4000
            });
            this.setState({classeDiv: 'hide'});
            return false;
        }

        if (this.state.numeroCnj === '' && this.pasta === '' && this.state.numeroProcesso) {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'É preciso informar uma pasta ou um processo ou um cnj',
                life: 4000
            });
            this.setState({classeDiv: 'hide'});
            return false;
        }

        if (this.state.grupoTrabalho === null) {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'É preciso informar o grupo de trabalho',
                life: 4000
            });
            this.setState({classeDiv: 'hide'});
            return false;
        }

        if (this.state.statusProcessual === null) {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'Informe o status processual',
                life: 4000
            });
            this.setState({classeDiv: 'hide'});
            return false;
        }

        if (IsNullOrEmpty(this.state.areaAtuacao)) {
            this.toast.show({severity: 'error', summary: 'Processos', detail: 'Informe a área de atuação', life: 4000});
            this.setState({classeDiv: 'hide'});
            return false;
        }

        if (this.state.tipoAcao === null) {
            this.toast.show({severity: 'error', summary: 'Processos', detail: 'Informe o tipo de ação', life: 4000});
            this.setState({classeDiv: 'hide'});
            return false;
        }

        if (IsNullOrEmpty(this.state.localTramite)) {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'Informe o local de trâmite',
                life: 4000
            });
            this.setState({classeDiv: 'hide'});
            return false;
        }
        if (this.state.processoPush) {
            if (this.state.processo.sistemaTribunais === null && this.state.sistema === null) {
                this.toast.show({
                    severity: 'error',
                    summary: 'Processos',
                    detail: 'O Processo necessita de um sistema.',
                    life: 4000
                });
                this.setState({classeDiv: 'hide'});
                return false;
            }
        }

        const usuarioLogado = this.context.usuarioAutenticado

        //Campos de Data
        //Data de Distribuição
        if (this.state.dataDistribuicao !== '') {
            var dataFormatadaDataDistribuicao = DateFormat(this.state.dataDistribuicao);
        }
        //Data de Última de Decisão
        if (this.state.dataUltimaDecisao !== '') {
            var dataFormatadaDataUltimaDecisao = DateFormat(this.state.dataUltimaDecisao);
        }
        //Data de Sentença
        if (this.state.dataSentenca !== '') {
            var dataFormatadaDataSentenca = DateFormat(this.state.dataSentenca);
        }

        //Push
        let push = this.state.processoPush ? "S" : 'N';
        let email = this.state.processoPushEmail ? "S" : 'N';
        let sistema = this.state.sistema || this.state.processo.sistemaTribunais
        const processo = {
            codigo: this.state.processo.codigo,
            grupoTrabalho: this.state.grupoTrabalho,
            pasta: this.state.pasta,
            nrProcesso: this.state.nrProcesso,
            nrCnj: this.state.nrCnj,
            nrInstancia: this.state.nrInstancia,
            localTramite: this.state.localTramite,
            tipoAcao: this.state.tipoAcao,
            statusProcessual: this.state.statusProcessual,
            areaAtuacao: this.state.areaAtuacao,
            tipoDecisao: this.state.tipoDecisao,
            vlProvavel: this.state.vlProvavel,
            vlPossivel: this.state.vlPossivel,
            vlRemoto: this.state.vlRemoto,
            vlCausa: this.state.vlCausa,
            dsPedidos: this.state.dsPedidos,
            dataDistribuicao: dataFormatadaDataDistribuicao,
            dataUltimaDecisao: dataFormatadaDataUltimaDecisao,
            dataSentenca: dataFormatadaDataSentenca,
            snPush: push,
            snEmail: email,
            snHistorico: "S",
            system: this.state.system.systemName,
            observacao: this.state.observacao,
            usuario: usuarioLogado,
            responsavel: usuarioLogado,
            empresa: usuarioLogado.empresa,
            liminarCumprimento: this.state.cumprimentoLiminar,
            liminarStatus: this.state.statusLiminar,
            sistemaTribunais: this.state.system
        }
        const processoDTO = {
            processo: processo,
            poloAtivo: this.state.selectedAutor,
            poloPassivo: this.state.selectedReu,
            outrasPartes: this.state.selectedAdvogado,
            objetosAcao: this.state.objetosProcesso,
        }
        this.setState({openSpinner: true});
        await this.processoService.alterarProcesso(processoDTO).then(response => {
            this.setState({
              selectedAutor: null,
              selectedReu: null,
              selectedAdvogado: null,
            })

            this.cadastrarIndiceProcesso(response.data.processo)

            this.preencherDados(response.data)
            this.setState({classeDivAutor: 'hide'});
            this.setState({classeDivReu: 'hide'});
            this.setState({classeDivAdvogado: 'hide'});
            this.setState({iconReu: 'pi pi-plus-circle'});
            this.setState({iconAutor: 'pi pi-plus-circle'});
            this.setState({iconAdvogado: 'pi pi-plus-circle'});
            this.toast.show({
                severity: 'success',
                summary: 'Processos',
                detail: 'Processo alterado com sucesso',
                life: 4000
            });
            setTimeout(() => {
                this.props.history.goBack()
            }, 1500)

        }).catch(error => {
            let data = error.response.data
           let detail = data.detail || "Erro ao alterar processo no sistema"
            this.toast.show({severity: 'error', summary: 'Processos', detail: `${detail}`, life: 4000});
        })

        this.setState({openSpinner: false})
    }

    preencherDados = (data) => {
        this.setState({processo: data.processo});
        this.setState({codigo: data.processo.codigo})
        this.setState({grupoTrabalho: data.processo.grupoTrabalho});
        this.setState({pasta: data.processo.pasta});
        this.setState({nrProcesso: data.processo.nrProcesso});
        this.setState({nrCnj: data.processo.nrCnj});
        this.setState({nrInstancia: data.processo.nrInstancia});
        this.setState({localTramite: data.processo.localTramite});
        this.setState({tipoAcao: data.processo.tipoAcao});
        this.setState({statusProcessual: data.processo.statusProcessual});
        this.setState({areaAtuacao: data.processo.areaAtuacao});
        this.setState({tipoDecisao: data.processo.tipoDecisao});
        this.setState({vlProvavel: data.processo.vlProvavel});
        this.setState({vlPossivel: data.processo.vlPossivel});
        this.setState({vlRemoto: data.processo.vlRemoto});
        this.setState({vlCausa: data.processo.vlCausa});
        this.setState({dsPedidos: data.processo.dsPedidos});
        this.setState({observacao: data.processo.observacao});
        this.setState({cumprimentoLiminar: data.processo.liminarCumprimento});
        this.setState({statusLiminar: data.processo.liminarStatus});
        this.setState({system: data.processo.system});
        this.setState({sistema: data.processo.sistemaTribunais.systemName})
        



        if (data.poloAtivo !== null) {
            // console.log(data.poloAtivo)
            this.setState({
                    autores: data.poloAtivo,
                    autor: this.formatarAutorOrReu(data.poloAtivo)
                }
            );

        }
        if (data.poloPassivo !== null) {
            this.setState({
                reus: data.poloPassivo,
                reu: this.formatarAutorOrReu(data.poloPassivo)
            });
        }
        if (data.outrasPartes !== null) {
            this.setState({
                outrasPartes: data.poloPassivo,
                outraParte: this.formatarAutorOrReu(data.outrasPartes)
            });
        }
        if (data.objetosAcao !== null) {

            this.setState({
                objetosProcesso: data.objetosAcao,
                objetoAcao: null,
                subObjetos: data.objetosAcao.filter(obj => obj.objetoPai !== null),
            })
            this.formatarObejtosAcaoProcesso(data.objetosAcao)
        }
        if (data.processo.dataDistribuicao != null) {
            var dataDist = new Date(data.processo.dataDistribuicao);
            dataDist.setDate(dataDist.getDate() + 1)
            this.setState({dataDistribuicao: new Date(Date.parse(dataDist))})
        }

        if (data.processo.dataSentenca != null) {
            var dataSet = new Date(data.processo.dataSentenca);
            dataSet.setDate(dataSet.getDate() + 1);
            this.setState({dataSentenca: dataSet});
        }

        if (data.processo.dataUltimaDecisao != null) {
            var dataUltDec = new Date(data.processo.dataUltimaDecisao);
            dataUltDec.setDate(dataUltDec.getDate() + 1);
            this.setState({dataUltimaDecisao: dataUltDec})
        }

        if (data.processo.dataCadastro != null) {
            var dataCad = new Date(data.processo.dataCadastro);
            dataCad.setDate(dataCad.getDate() + 1);
            this.setState({dataCadastro: dataCad})
        }

        if (data.processo.dataUltimaMovimentacao != null) {
            var dataUltMov = new Date(data.processo.dataUltimaMovimentacao);
            dataUltMov.setDate(dataUltMov.getDate() + 1);
            this.setState({dataUltimaMovimentacao: dataUltMov})
        }

        this.setState({responsavel: data.processo.responsavel.nome});

            this.setState({processoPush:data.processo.snPush === 'S' })

            this.setState({processoPushEmail: data.processo.snEmail === 'S'})

        if (data.processo.snImportante === 'S') {
            this.setState({iconeImportanteParaEmpresa: 'p-button-rounded p-button-danger'});
        } else {
            this.setState({iconeImportanteParaEmpresa: 'p-button-rounded p-button-secondary'});
        }

        // calcular custas pagamentos e garantias
        this.calculaCustas(data.custas)
        this.calculaPagamentos(data.pagamentos)
        this.calculaGarantias(data.garantias)
    }
    cadastrarIndiceProcesso = (processo) => {
        this.processoService.cadastrarIndiceProcesso({
            codigo: processo.codigo
        }).then(() => {
            console.log('Indice cadastrado')
        })
    }


    onProcessoPushChange() {
        this.setState({
            // processoPush: !this.state.processoPush,
            dialogSelectSystem: !this.state.dialogSelectSystem
        })
        // if(e.checked && this.state.processo.system === undefined || this.state.processo.system === null){
        //     this.setState({
        //         dialogSelectSystem: e.checked})
        // }
    }

//adicionando o sistema a qual o processo pertence, para ser possivel adiciona-lo a fila
//     addSystemToProcess = (sistema) => {
//         this.setState({sistema: sistema})
//     }


    consultarProcessoImportancia = async () => {
        const usuarioLogado = this.context.usuarioAutenticado;
        const filtro = {
            usuario: usuarioLogado.codigo,
            processo: this.state.processo.codigo
        }

        await this.processoImportanciaService.consultarProcessoImportancia(filtro)
            .then(response => {
                if (response.data !== null) {
                    this.setState({iconeImportanteParaMim: 'p-button-rounded p-button-warning'});
                    this.setState({iconeImportanteParaMimTitle: 'Clique para retirar este processo como importante'});
                } else {
                    this.setState({iconeImportanteParaMim: 'p-button-rounded p-button-secondary'});
                    this.setState({iconeImportanteParaMimTitle: 'Clique para marcar este processo como importante'});
                }
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Processo Importancia',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            })
    }

    //agora inclui e remove logica implementada no back-end
    iincluirProcessoImportancia = async () => {
        const usuarioLogado = this.context.usuarioAutenticado

        await this.processoImportanciaService.incluirProcesso({
            usuario: usuarioLogado,
            processo: this.state.processo
        }).then(response => {
            if (response.data === null || response.data === "" || response.data === undefined) {
                this.setState({iconeImportanteParaMim: 'p-button-rounded p-button-secondary'});
                this.setState({iconeImportanteParaMimTitle: 'Clique para marcar este processo como importante'});
            } else {
                this.setState({iconeImportanteParaMim: 'p-button-rounded p-button-warning'});
                this.setState({iconeImportanteParaMimTitle: 'Clique para retirareste processo como importante'});
            }
        }).catch(error => {
            console.log(error)
            this.setState(error.response.data);
            this.toast.show({
                severity: 'error',
                summary: 'Processo importancia',
                detail: `${this.state.detail}`,
                life: 4000
            });
        })
    }

    incluirProcessoImportanteParaEmpresa = async () => {
        const usuarioLogado = this.context.usuarioAutenticado
        if (usuarioLogado.perfil.codigo !== 1) {
            this.toast.show({
                severity: 'error',
                summary: 'Processo Importancia',
                detail: 'O usuário não tem permissão para executar esta ação',
                life: 4000
            });
            return false;
        }

        let importancia = '';
        if (this.state.processo.snImportante === 'S') {
            importancia = 'N';
        } else {
            importancia = 'S';
        }

        await this.processoService.alterarImportanciaEmpresa({
            codigo: this.state.processo.codigo,
            snImportante: importancia
        }).then(() => {
            if (importancia === 'S') {
                this.setState({iconeImportanteParaEmpresa: 'p-button-rounded p-button-danger'});
            } else {
                this.setState({iconeImportanteParaEmpresa: 'p-button-rounded p-button-secondary'});
            }
            this.consultarProcesso(this.state.processo.codigo);
        })
    }

    refreshPage = () => {
        window.location.reload();
    }

    reloadData =  async () => {
        this.setState({
            openSpinner: true
        })
        await this.consultarProcesso(this.state.processo.codigo);
        this.getFilas();
        this.setState({
            openSpinner: false
        })
    }

    abrirModalObjetos = () => {
        this.setState({modalObjetos: true})
    }
    fecharModalObjetos = () => {
        this.setState({modalObjetos: false})
    }

    onCumprimentoLiminarChange = (e) => {
        const {value} = e.target
        this.setState({cumprimentoLiminar: value})
    }

    onStatusLiminarChange = (e) => {
        const {value} = e.target
        this.setState({statusLiminar: value})
    }

    getCumprimentoOptions = () => {
        this.liminarService.getAllCumprimento()
            .then(res => {
                this.setState({cumprimentoLiminarOptions: res.data})
            })
    }

    getStatusLiminarOptions = () => {
        this.liminarService.getAllStatus()
            .then(res => {
                this.setState({statusLiminarOptions: res.data})
            })
    }

    getSistemName = () => {
        const {processo} = this.state;

        // Caso contrário, retorna processo.system
        return processo.sistemaTribunais?.systemName || "";
    }

    render() {
        return (
            <>
                <NavBar/>
                <Toast ref={(el) => this.toast = el}/>
                {this.state.dialogSelectSystem &&
                    <DialogSelectSystemProcesso
                        systemName={this.state.processo?.sistemaTribunais?.systemName}
                       processo={this.state.processo}
                        reloadData={this.reloadData}
                        open={this.state.dialogSelectSystem}
                        close={() => this.setState({dialogSelectSystem: false})}
                        handleClose={() => this.setState({dialogSelectSystem: false,
                            // processoPush: false,
                        })}

                    />
                }
                {/**Modal Excluir Autores */}
                <Dialog header="Polo ativo do processo"
                        visible={this.state.modalAutores}
                        style={{width: '40vw'}}
                        footer={this.renderFooterPartes('modalAutores')}
                        onHide={() => this.fecharModalCadastro('modalAutores')}>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <DataTable value={this.state.autores}>
                                    <Column field="pessoa.nome" header="Pessoa"></Column>
                                    <Column body={this.acoesExcluirAutor} exportable={false} header="Ações"
                                            style={{minWidth: '8rem'}}></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </Dialog>
                {this.state.openGpTrab &&
                    <DialogGrupoTrabalho open={this.state.openGpTrab}
                                         close={() => this.setState({openGpTrab: false})}
                                         toast={this.toast}
                                         refrashList={this.carregaCombosConsulta}
                    />
                }
                {this.state.openStatus &&
                    <DialogStatusProcessual open={this.state.openStatus}
                                            close={() => this.setState({openStatus: false})}
                                            toast={this.toast}
                                            refrashList={this.carregaCombosConsulta}
                    />
                }
                {this.state.openAreaAtuacao &&
                    <DialogAreaAtuacao open={this.state.openAreaAtuacao}
                                       close={() => this.setState({openAreaAtuacao: false})}
                                       toast={this.toast}
                                       refrashList={this.carregaCombosConsulta}
                    />
                }
                {this.state.openTipoAcao &&
                    <DialogTipoAcao open={this.state.openTipoAcao}
                                    close={() => this.setState({openTipoAcao: false})}
                                    toast={this.toast}
                                    refrashList={this.carregaCombosConsulta}
                    />
                }
                {this.state.openTipoDesicao &&
                    <DialogTipoDesicao open={this.state.openTipoDesicao}
                                       close={() => this.setState({openTipoDesicao: false})}
                                       toast={this.toast}
                                       refreshList={this.carregaCombosConsulta}
                    />
                }
                <ConfirmDialog visible={this.state.visibleAutor}
                               onHide={() => this.setState({visibleAutor: false})}
                               message="Are you sure you want to proceed?"
                               header="Confirmation"
                               icon="pi pi-exclamation-triangle"
                               accept={() => this.excluirParteAutor}
                               reject={() => this.setState({visible: false})}/>

                {/**Modal Excluir Réus */}
                <Dialog header="Polo passivo do processo"
                        visible={this.state.modalReus}
                        style={{width: '40vw'}}
                        footer={this.renderFooterPartes('modalReus')}
                        onHide={() => this.fecharModalCadastro('modalReus')}>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <DataTable value={this.state.reus}>
                                    <Column field="pessoa.nome" header="Pessoa"></Column>
                                    <Column body={this.acoesExcluirReu} exportable={false} header="Ações"
                                            style={{minWidth: '8rem'}}></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <ConfirmDialog visible={this.state.visibleReu}
                               onHide={() => this.setState({visibleReu: false})}
                               message="Are you sure you want to proceed?"
                               header="Confirmation"
                               icon="pi pi-exclamation-triangle"
                               accept={() => this.excluirParteReu}
                               reject={() => this.setState({visible: false})}/>

                {/**Modal Excluir OP */}
                <Dialog header="Outra(s) parte(s)"
                        visible={this.state.modalAdvogados}
                        style={{width: '40vw'}}
                        footer={this.renderFooterPartes('modalAdvogados')}
                        onHide={() => this.fecharModalCadastro('modalAdvogados')}>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <DataTable value={this.state.advogados}>
                                    <Column field="pessoa.nome" header="Pessoa"></Column>
                                    <Column body={this.acoesExcluirAdvogado} exportable={false} header="Ações"
                                            style={{minWidth: '8rem'}}></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <ConfirmDialog visible={this.state.visibleAdvogados}
                               onHide={() => this.setState({visibleAdvogados: false})}
                               message="Are you sure you want to proceed?"
                               header="Confirmation"
                               icon="pi pi-exclamation-triangle"
                               accept={() => this.excluirParteAdvogado}
                               reject={() => this.setState({visible: false})}/>

                {/**Incluir Pagamentos de Processo */}
                <Dialog header="Incluir pagamento"
                        visible={this.state.modalPagamentos}
                        style={{width: '50vw'}}
                        footer={this.renderFooterCadastroPagamentos('modalPagamentos')}
                        onHide={() => this.fecharModalCadastro('modalPagamentos')}>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="nomeGrupo">Tipo de pagamento</label>
                                <Dropdown value={this.state.tipoPagamento}
                                          options={this.state.tiposPagamentos} style={{width: '100%', height: '37px'}}
                                          optionLabel="nome"
                                          emptyMessage=" "
                                          filter showClear filterBy="nome"
                                          onChange={this.onChangeTipoPagamento}
                                          id="tipoPagamento"
                                          className="p-inputtext-sm p-d-block p-mb-1"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label className="flex-1 gap-1" htmlFor="city1">
                                    <RadioButton inputId="tipoC" name="tipoC" label="Crédito"
                                                 value="C"
                                                 id={"credito"}
                                                 checked={this.state.tipo === "C"}
                                                 onChange={(e) => this.setState({tipo: e.value})}/>Crédito
                                    <RadioButton inputId="tipoD" name="tipoD" label=">Débito"
                                                 value="D"
                                                 id={"debito"}
                                                 checked={this.state.tipo === "D"}
                                                 onChange={(e) => this.setState({tipo: e.value})}/>Débito
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={"col-sm-12"}>
                        <div className="row gap-5">
                            <div className="width-50">
                                <div className="form-group">
                                    <label>Valor pagamento</label>
                                    <InputNumber inputId="locale-german"
                                                 value={this.state.vlPagamento}
                                                 id={"valor-pagamento"}
                                                 style={{width: '100%', height: '32px'}}
                                                 onChange={(e) => this.setState({vlPagamento: e.value})}
                                                 mode="decimal" locale="de-BR" minFractionDigits={2}/>
                                </div>
                            </div>
                            <div className="width-50">
                                <div className="form-group">
                                    <label>Data pagamento</label>
                                    <Calendar id="mask"
                                              value={this.state.dtPagamento}
                                              onChange={(e) => this.setState({dtPagamento: e.value})}
                                              locale="es"
                                              style={{width: '100%', height: '32px'}}
                                              dateFormat="dd/mm/yy"
                                              mask="99/99/9999"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <ConfirmDialog visible={this.state.visiblePagamento}
                               onHide={() => this.setState({visiblePagamento: false})}
                               message="Are you sure you want to proceed?"
                               header="Confirmation"
                               icon="pi pi-exclamation-triangle"
                               accept={() => this.excluirPagamentoProcesso}
                               reject={() => this.setState({visible: false})}/>
                {/**Incluir Custas Processuais */}
                <Dialog header="Incluir custas"
                        visible={this.state.modalCustas}
                        style={{width: '50vw'}}
                        footer={this.renderFooterCadastroCustas('modalCustas')}
                        onHide={() => this.fecharModalCadastro('modalCustas')}>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="tipoCusta">Tipo de custa</label>
                                <Dropdown value={this.state.tipoCusta}
                                          options={this.state.tiposCustas} style={{width: '100%', height: '37px'}}
                                          optionLabel="nome"
                                          emptyMessage=" "
                                          filter showClear filterBy="nome"
                                          onChange={this.onChangeTipoCustas}
                                          id="tipoCusta"
                                          className="p-inputtext-sm p-d-block p-mb-1"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label className="flex-1 gap-1" htmlFor="city1">
                                    <RadioButton inputId="tipoC" name="tipoC" label="Crédito"
                                                 value="C"
                                                 id={"credito"}
                                                 checked={this.state.tipo === "C"}
                                                 onChange={(e) => this.setState({tipo: e.value})}/>Crédito
                                    <RadioButton inputId="tipoD" name="tipoD" label="Débito"
                                                 value="D"
                                                 id={"debito"}
                                                 checked={this.state.tipo === "D"}
                                                 onChange={(e) => this.setState({tipo: e.value})}/>Débito
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={"col-sm-12"}>
                        <div className="row gap-5">
                            <div className="width-50">
                                <div className="form-group">
                                    <label>Valor custas</label>
                                    <InputNumber inputId="locale-german"
                                                 value={this.state.vlCustas}
                                                 id={"valor-custas"}
                                                 style={{width: '100%', height: '37px'}}
                                                 onChange={(e) => this.setState({vlCustas: e.value})}
                                                 mode="decimal" locale="de-BR" minFractionDigits={2}/>
                                </div>
                            </div>
                            <div className="width-50">
                                <div className="form-group">
                                    <label>Data pagamento</label>
                                    <Calendar id="maskCusta"
                                              value={this.state.dtPagamento}
                                              onChange={(e) => this.setState({dtPagamento: e.value})}
                                              locale="es"
                                              style={{width: '100%', height: '37px'}}
                                              dateFormat="dd/mm/yy"
                                              mask="99/99/9999"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <ConfirmDialog visible={this.state.visibleCusas}
                               onHide={() => this.setState({visibleCusas: false})}
                               message="Are you sure you want to proceed?"
                               header="Confirmation"
                               icon="pi pi-exclamation-triangle"
                               accept={() => this.excluirCusta}
                               reject={() => this.setState({visible: false})}/>

                {/**Incluir Garantias do Processo */}
                <Dialog header="Incluir garantias"
                        visible={this.state.modalGarantias}
                        style={{width: '40vw'}}
                        footer={this.renderFooterCadastroGarantias('modalGarantias')}
                        onHide={() => this.fecharModalCadastro('modalGarantias')}>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="tipo-garantia">Tipo de garantia</label>
                                <Dropdown value={this.state.tipoGarantia}
                                          options={this.state.tiposGarantias} style={{width: '100%', height: '37px'}}
                                          optionLabel="nome"
                                          emptyMessage=" "
                                          filter showClear filterBy="nome"
                                          onChange={this.onChangeTipoGarantia}
                                          id="tipo-garantia"
                                          className="p-inputtext-sm p-d-block p-mb-1"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>Valor penhora</label>
                                <InputNumber inputId="locale-german"
                                             value={this.state.vlPenhora}
                                             id={"valor-penhora"}
                                             style={{width: '100%', height: '32px'}}
                                             onChange={(e) => this.setState({vlPenhora: e.value})}
                                             mode="decimal" locale="de-BR" minFractionDigits={2}/>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <section className="content-header">
                            <div className="card card-primary card-outline">
                                <div className="card-header row-etrium gap-1 align-items-center">
                                    <h3 className="card-title row gap-1 align-items-center">
                                        <i className="fas fa-users"></i>
                                        <span>Ficha do processo</span>
                                    </h3>
                                    <div className="flex-row gap-5">
                                        <div className="row-etrium gap-1"
                                             style={{textAlign: 'right'}}>
                                            <Button icon="pi pi-star"
                                                    className={this.state.iconeImportanteParaMim}
                                                    onClick={this.iincluirProcessoImportancia}
                                                    tooltip={this.state.iconeImportanteParaMimTitle}
                                                    tooltipOptions={{
                                                        className: 'blue-tooltip',
                                                        position: 'top'
                                                    }}
                                                    style={{width: '29px', height: '26px'}}/>
                                            {/*<Button icon="pi pi-heart"*/}
                                            {/*        onClick={this.incluirProcessoImportanteParaEmpresa}*/}
                                            {/*        className={this.state.iconeImportanteParaEmpresa}*/}
                                            {/*        style={{width: '29px', height: '26px'}}/>*/}


                                        <div className="row-etrium gap-0 align-items-center"
                                             style={{textAlign: 'right',}}>
                                            <Button icon="fas fa-level-down-alt"
                                                    id="push"
                                                    className={this.state.processoPush ? 'p-button-rounded p-button-primary' : 'p-button-rounded p-button-secondary'}
                                                    onClick={this.onProcessoPushChange}
                                                    tooltip={this.state.processoPush ? 'Clique aqui para retirar processo do push' : 'Clique aqui para adicionar processo no push'}
                                                    tooltipOptions={{
                                                        className: 'blue-tooltip',
                                                        position: 'top'
                                                    }}
                                                    style={{width: '29px', height: '26px'}}/>
                                            <label className="ml-2">{this.getSistemName()} </label>
                                        </div>
                                        </div>
                                    </div>
                                </div>

                                <Loading
                                    open={this.state.openSpinner}>
                                </Loading>

                                <TabView className="tabview-custom">
                                    <TabPanel header="Processo">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                            <span className="flex-1 gap-1">
                              <Button icon={this.state.iconAutor} className={this.state.classeAutor}
                                      tooltip={this.state.toolTipAutor}
                                      tooltipOptions={{position: 'top'}}
                                      onClick={this.adicionarAutor}
                                      id={"add-polo-ativo"}
                                      style={{width: '20px', height: '10px'}}/>
                              <Button icon="pi pi-times-circle"
                                      className="p-button-rounded p-button-danger p-button-text"
                                      tooltip="Clique para retirar polo ativo"
                                      tooltipOptions={{position: 'top'}}
                                      data-toggle="modal"
                                      id={"removed-polo-ativo"}
                                      onClick={() => this.abrirModalAutores('modalAutores')}
                                      data-target="#modal-default"
                                      style={{width: '20px', height: '10px'}}/>
                              <Button icon="pi pi-user-plus"
                                      className="p-button-rounded p-button-primary p-button-text"
                                      tooltip="Clique para adicionar uma nova pessoa"
                                      tooltipOptions={{position: 'top'}}
                                      id={"cadastro-polo-ativo"}
                                      onClick={() => this.novaPessoa()}
                                      data-target="#modal-default"
                                      style={{width: '20px', height: '10px'}}/>
                                {/*temp removido*/}

                                {/*<Button icon="pi pi-exclamation-circle"*/}
                                {/*        className="p-button-rounded p-button-primary p-button-text"*/}
                                {/*        tooltip="Informações sobre a pessoa"*/}
                                {/*        tooltipOptions={{position: 'top'}}*/}
                                {/*        data-target="#modal-default"*/}
                                {/*        style={{width: '20px', height: '10px'}}/>*/}
                            </span>
                                                        <label className="flex-1 gap-1">Polo ativo:<span
                                                            className="partes">{this.state.autor}</span> </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <PartesAlterar classeSecundaria={this.state.classeDivAutor}
                                                           value={this.state.selectedAutor}
                                                           suggestions={this.state.filterPessoasPA}
                                                           name={"filterPessoasPA"}
                                                           completeMethod={this.consultarPessoa}
                                                           placeholder="Digite aqui para pesquisar"
                                                           change={(e) => this.setState({selectedAutor: e.value})}>
                                            </PartesAlterar>
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="form-group">
                            <span className="flex-1 gap-1">
                              <Button icon={this.state.iconReu} className={this.state.classeReu}
                                      tooltip={this.state.toolTipReu}
                                      tooltipOptions={{position: 'top'}}
                                      onClick={this.adicionarReu}
                                      id={"added-polo-passivo"}
                                      style={{width: '20px', height: '10px'}}/>
                              <Button icon="pi pi-times-circle"
                                      className="p-button-rounded p-button-danger p-button-text"
                                      tooltip="Clique para retirar polo passivo"
                                      tooltipOptions={{position: 'top'}}
                                      data-toggle="modal"
                                      onClick={() => this.abrirModalReus('modalReus')}
                                      data-target="#modal-default"
                                      id={"removed-polo-passivo"}
                                      style={{width: '20px', height: '10px'}}/>
                              <Button icon="pi pi-user-plus"
                                      className="p-button-rounded p-button-primary p-button-text"
                                      tooltip="Clique para adicionar uma nova pessoa"
                                      tooltipOptions={{position: 'top'}}
                                      onClick={() => this.novaPessoa()}
                                      data-target="#modal-default"
                                      id={"cadastro-polo-passivo"}
                                      style={{width: '20px', height: '10px'}}/>
                                {/*<Button icon="pi pi-exclamation-circle"*/}
                                {/*        className="p-button-rounded p-button-primary p-button-text"*/}
                                {/*        tooltip="Informações sobre a pessoa"*/}
                                {/*        tooltipOptions={{position: 'top'}}*/}
                                {/*        data-target="#modal-default"*/}
                                {/*        style={{width: '20px', height: '10px'}}/>*/}
                            </span>
                                                        <label className="flex-1 gap-1">Polo passivo:<span
                                                            className="partes">{this.state.reu}</span></label>

                                                    </div>
                                                </div>
                                            </div>
                                            <PartesAlterar classeSecundaria={this.state.classeDivReu}
                                                           value={this.state.selectedReu}
                                                           suggestions={this.state.filterPessoasPP}
                                                           completeMethod={this.consultarPessoa}
                                                           name={"filterPessoasPP"}
                                                           placeholder="Digite aqui para pesquisar"
                                                           change={(e) => this.setState({selectedReu: e.value})}>
                                            </PartesAlterar>
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="form-group">
                            <span className="flex-1 gap-1">
                              <Button icon={this.state.iconAdvogado} className={this.state.classeAdvogado}
                                      tooltip={this.state.toolTipAdvogado}
                                      tooltipOptions={{position: 'top'}}
                                      onClick={this.adicionarAdvogado}
                                      id={"added-op"}
                                      style={{width: '20px', height: '10px'}}/>
                              <Button icon="pi pi-times-circle"
                                      className="p-button-rounded p-button-danger p-button-text"
                                      tooltip="Clique para retirar outras partes"
                                      tooltipOptions={{position: 'top'}}
                                      data-toggle="modal"
                                      id={"removed-op"}
                                      onClick={() => this.abrirModalAdvogados('modalAdvogados')}
                                      data-target="#modal-default"
                                      style={{width: '20px', height: '10px'}}/>
                              <Button icon="pi pi-user-plus"
                                      className="p-button-rounded p-button-primary p-button-text"
                                      tooltip="Clique para adicionar uma nova pessoa"
                                      tooltipOptions={{position: 'top'}}
                                      onClick={() => this.novaPessoa()}
                                      id={"cadastro-op"}
                                      data-target="#modal-default"
                                      style={{width: '20px', height: '10px'}}/>
                                {/*<Button icon="pi pi-exclamation-circle"*/}
                                {/*        className="p-button-rounded p-button-primary p-button-text"*/}
                                {/*        tooltip="Informações sobre a pessoa"*/}
                                {/*        tooltipOptions={{position: 'top'}}*/}
                                {/*        data-target="#modal-default"*/}
                                {/*        style={{width: '20px', height: '10px'}}/>*/}
                            </span>
                                                        <label className="flex-1 gap-1">Outra(s) parte(s):<span
                                                            className="partes">{this.state.outraParte}</span></label>

                                                    </div>
                                                </div>
                                            </div>
                                            <PartesAlterar classeSecundaria={this.state.classeDivAdvogado}
                                                           value={this.state.selectedAdvogado}
                                                           suggestions={this.state.filterPessoasOP}
                                                           completeMethod={this.consultarPessoa}
                                                           name={"filterPessoasOP"}
                                                           placeholder="Digite aqui para pesquisar"
                                                           change={(e) =>
                                                               this.setState({selectedAdvogado: e.value})}
                                            >
                                            </PartesAlterar>
                                            <div className="row">
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        <label>Data cadastro</label>
                                                        <Calendar id="mask2"
                                                                  value={this.state.dataCadastro}
                                                                  style={{width: '100%', height: '32px'}}
                                                                  dateFormat="dd/mm/yy"
                                                                  disabled="true"
                                                                  mask="99/99/9999"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        <label>Ult. mov.</label>
                                                        <Calendar id="mask2"
                                                                  value={new Date(this.state.dataUltimaMovimentacao)}
                                                                  style={{width: '100%', height: '32px'}}
                                                                  dateFormat="dd/mm/yy"
                                                                  disabled="true"
                                                                  mask="99/99/9999"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Responsável</label>
                                                        <InputText value={this.state.responsavel}
                                                                   style={{width: '100%', height: '32px'}}
                                                                   disabled="true"/>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<hr></hr>*/}
                                            {/*<br></br>*/}


                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Numeração única</label>
                                                        <InputMask id="numeroCnj"
                                                                   type="text"
                                                                   mask="9999999-99.9999.9.99.9999"
                                                                   style={{width: '100%', height: '32px'}}
                                                                   value={this.state.nrCnj}
                                                                   onChange={e => this.setState({nrCnj: e.target.value})}
                                                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Processo original</label>
                                                        <InputText id="numeroProcesso"
                                                                   type="text"
                                                                   maxLength={30}
                                                                   style={{width: '100%', height: '32px'}}
                                                                   value={this.state.nrProcesso}
                                                                   onChange={e => this.setState({nrProcesso: e.target.value})}
                                                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4" style={{paddingTop: '5px'}}>
                                                    <div className="form-group">
                                                        <label>Pasta
                                                        </label>
                                                        <InputText id="pasta"
                                                                   type="text"
                                                                   maxLength={30}
                                                                   style={{width: '100%', height: '37px'}}
                                                                   value={this.state.pasta}
                                                                   onChange={e => this.setState({pasta: e.target.value})}
                                                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Área de atuação
                                                            <span className="obrigatorioAsterisco"> * </span>
                                                            <span>
                                <Button icon="pi pi-plus-circle" className="p-button-rounded p-button-text"
                                        tooltip="Clique para adicionar uma nova área de atuação"
                                        tooltipOptions={{position: 'top'}}
                                        style={{width: '20px', height: '10px'}}
                                        onClick={() => this.setState({openAreaAtuacao: true})}
                                />
                              </span>
                                                        </label>
                                                        <Dropdown value={this.state.areaAtuacao}
                                                                  options={this.state.areasAtuacao}
                                                                  style={{width: '100%', height: '37px'}}
                                                                  optionLabel="nome"
                                                                  filter showClear filterBy="nome"
                                                                  onChange={this.onChangeAreaAtuacao}
                                                                  id="areaAtuacao"
                                                                  className="p-inputtext-sm p-d-block p-mb-1"/>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">

                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Tipo de ação
                                                            <span className="obrigatorioAsterisco"> * </span>
                                                            <span>
                                <Button icon="pi pi-plus-circle" className="p-button-rounded p-button-text"
                                        tooltip="Clique para adicionar um novo tipo de ação"
                                        tooltipOptions={{position: 'top'}}
                                        style={{width: '20px', height: '10px'}}
                                        onClick={() => this.setState({openTipoAcao: true})}
                                />
                              </span>
                                                        </label>
                                                        <Dropdown value={this.state.tipoAcao}
                                                                  options={this.state.tiposAcao}
                                                                  style={{width: '100%', height: '37px'}}
                                                                  optionLabel="nome"
                                                                  filter showClear filterBy="nome"
                                                                  onChange={this.onChangeTipoAcao}
                                                                  id="tipoAcao"
                                                                  className="p-inputtext-sm p-d-block p-mb-1"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Grupo de trabalho
                                                            <span className="obrigatorioAsterisco"> * </span>
                                                            <span>
                                <Button icon="pi pi-plus-circle" className="p-button-rounded p-button-text"
                                        tooltip="Clique para adicionar um novo grupo de trabalho"
                                        tooltipOptions={{position: 'top'}}
                                        style={{width: '20px', height: '10px'}}
                                        onClick={() => this.setState({openGpTrab: true})}
                                />

                              </span>
                                                        </label>
                                                        <Dropdown value={this.state.grupoTrabalho}
                                                                  options={this.state.gruposTrabalho}
                                                                  style={{width: '100%', height: '37px'}}
                                                                  optionLabel="nome"
                                                                  filter showClear filterBy="nome"
                                                                  onChange={this.onChangeGrupoTrabalho}
                                                                  id="grupoTrabalho"
                                                                  className="p-inputtext-sm p-d-block p-mb-1"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Cumprimento da liminar
                                                        </label>
                                                        <Dropdown value={this.state.cumprimentoLiminar}
                                                                  options={this.state.cumprimentoLiminarOptions}
                                                                  style={{width: '100%', height: '37px'}}
                                                                  placeholder={"Selecione uma opção"}
                                                                  optionLabel="nome"
                                                                  filter showClear filterBy="nome"
                                                                  onChange={this.onCumprimentoLiminarChange}
                                                                  id="cumprimentoLiminar"
                                                                  className="p-inputtext-sm p-d-block p-mb-1"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Status da liminar
                                                        </label>
                                                        <Dropdown value={this.state.statusLiminar}
                                                                  options={this.state.statusLiminarOptions}
                                                                  optionLabel="nome"
                                                                  placeholder={"Selecione um status"}
                                                                  filter showClear filterBy="nome"
                                                                  style={{width: '100%', height: '37px'}}
                                                                  onChange={this.onStatusLiminarChange}
                                                                  id="statusLiminar"
                                                                  className="p-inputtext-sm p-d-block p-mb-1"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"row"}>
                                                <div className="col-sm-4">
                                                    <AutoCompleteLocalTramite
                                                        selectLocalTramite={this.state.localTramite}
                                                        setSelect={(value) => this.setState({localTramite: value})}

                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                <div className="form-group">
                                                        <label>Sistema
                                                        <span className="obrigatorioAsterisco"> * </span>
                                                        </label>
                                                        <Dropdown value={this.state.system}
                                                            options={this.state.filas}
                                                            style={{width: '100%', height: '37px'}}
                                                            optionLabel="systemName"
                                                            filter showClear filterBy="systemName"
                                                            onChange={e => this.setState({system: e.target.value})}
                                                            id="system"
                                                            className="p-inputtext-sm p-d-block p-mb-1 card-max-width"/>                                                              
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Status processual
                                                            <span className="obrigatorioAsterisco"> * </span>
                                                            <span>
                                <Button icon="pi pi-plus-circle" className="p-button-rounded p-button-text"
                                        tooltip="Clique para adicionar um novo status processual"
                                        tooltipOptions={{position: 'top'}}
                                        style={{width: '20px', height: '10px'}}
                                        onClick={() => this.setState({openStatus: true})}
                                />
                              </span>
                                                        </label>
                                                        <Dropdown value={this.state.statusProcessual}
                                                                  options={this.state.statusProcessuais}
                                                                  style={{width: '100%', height: '37px'}}
                                                                  optionLabel="descricao"
                                                                  filter showClear filterBy="descricao"
                                                                  onChange={this.onChangeStatusProcessual}
                                                                  id="statusProcessual"
                                                                  className="p-inputtext-sm p-d-block p-mb-1"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Tipo de decisão<span
                                                            className="obrigatorioAsterisco"> * </span>
                                                            <span>
                                                                <Button icon="pi pi-plus-circle"
                                                                        className="p-button-rounded p-button-text"
                                                                        tooltip="Clique para adicionar um novo tipo de decisão"
                                                                        tooltipOptions={{position: 'top'}}
                                                                        style={{width: '20px', height: '10px'}}
                                                                        onClick={() => this.setState({openTipoDesicao: true})}
                                                                />
                                                              </span></label>
                                                        <Dropdown value={this.state.tipoDecisao}
                                                                  options={this.state.tiposDecisao}
                                                                  style={{width: '100%', height: '37px'}}
                                                                  optionLabel="nome"
                                                                  filter showClear filterBy="nome"
                                                                  onChange={this.onTipoDecisaoChange}
                                                                  id="tipo-deicsao-dd"
                                                                  className="p-inputtext-sm p-d-block p-mb-1"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"row form-group"}>
                                                <div className="col-sm-8">
                                                    <div className="flex-row gap-1">
                                                        <div className="flex-1 flex-col">
                                                            <label>Data de distribuição</label>
                                                            <Calendar id="mask2"
                                                                      value={new Date(this.state.dataDistribuicao)}
                                                                      onChange={(e) => this.setState({dataDistribuicao: e.value})}
                                                                      locale="es"
                                                                      style={{width: '100%', height: '32px'}}
                                                                      dateFormat="dd/mm/yy"
                                                                      mask="99/99/9999"/>
                                                        </div>
                                                        <div className="flex-1 flex-col">
                                                            <label>Data da sentença</label>
                                                            <Calendar id="mask"
                                                                      value={this.state.dataSentenca}
                                                                      onChange={(e) => this.setState({dataSentenca: e.value})}
                                                                      locale="es"
                                                                      style={{width: '100%', height: '32px'}}
                                                                      dateFormat="dd/mm/yy"
                                                                      mask="99/99/9999"/>
                                                        </div>

                                                        <div className="flex-1 flex-col">
                                                            <label>Data ultima decisão</label>
                                                            <Calendar id="mask"
                                                                      value={this.state.dataUltimaDecisao}
                                                                      onChange={(e) => this.setState({dataUltimaDecisao: e.value})}
                                                                      locale="es"
                                                                      style={{width: '100%', height: '32px'}}
                                                                      dateFormat="dd/mm/yy"
                                                                      mask="99/99/9999"/>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="form-group">
                                                        {/*<AccordionTab header={this.headerObjetosAcao()}>*/}
                                                        {/*<MultiSelect inputId="multiselect"*/}
                                                        {/*             value={this.state.objetoAcao}*/}
                                                        {/*             options={this.state.objetosAcao}*/}
                                                        {/*             style={{*/}
                                                        {/*                 width: '100%',*/}
                                                        {/*                 height: '37px',*/}
                                                        {/*                 padding_top: '1px'*/}
                                                        {/*             }}*/}
                                                        {/*             onChange={(e) => this.setState({objetoAcao: e.value})}*/}
                                                        {/*             filter showClear filterBy="nome"*/}
                                                        {/*             optionLabel="nome"/>*/}
                                                        {/*<label>Selecionar objetos</label>*/}
                                                        <ObjetoAcaoSelecter
                                                            objetos={this.state.objetosProcesso}
                                                            objetosOptions={this.state.objetosAcao}
                                                            onChange={objs => this.setState({objetosProcesso: objs})}/>
                                                        {/*<label className={"mt-2"}>Objetos no processo</label>*/}
                                                        {/*filtro para nao exibir os subobjetos na mesma table q objetos*/}
                                                        {/*<DataTable*/}
                                                        {/*    value={this.state.objetosProcesso.filter(obj => obj.objetoPai === null)}*/}
                                                        {/*    emptyMessage=" ">*/}
                                                        {/*    <Column field="nome" header="Objeto"></Column>*/}
                                                        {/*    <Column body={this.acoesObjetosAcao} exportable={false}*/}
                                                        {/*            style={{minWidth: '2rem'}}*/}
                                                        {/*            header="Ações"></Column>*/}
                                                        {/*    <Column body={this.bodySubObjetos} exportable={false}*/}
                                                        {/*            style={{minWidth: '8rem'}}></Column>*/}
                                                        {/*</DataTable>*/}
                                                        {/*    <ConfirmDialog visible={this.state.visibleObjeto}*/}
                                                        {/*                   onHide={() => this.setState({visibleObjeto: false})}*/}
                                                        {/*                   message="Are you sure you want to proceed?"*/}
                                                        {/*                   header="Confirmation"*/}
                                                        {/*                   icon="pi pi-exclamation-triangle"*/}
                                                        {/*                   accept={() => this.excluirObjetoAcao}*/}
                                                        {/*reject={() => this.setState({visible: false})}/>*/}
                                                        {/*</AccordionTab>*/}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="form-group">
                                                        <Accordion multiple>

                                                            <AccordionTab header={this.headerResumoPagamentos()}>
                                                                <DataTable value={this.state.pagamentos}
                                                                           emptyMessage="Nenhum pagamento cadastrado">
                                                                    <Column field="pagamento.nome" header="Pagamento"
                                                                            className="cabecalho"></Column>
                                                                    <Column field="vlPagamento" header="R$"
                                                                            body={this.vlPagamento}></Column>
                                                                    <Column field="tipo" header="Deb/Cre"></Column>
                                                                    <Column field="dtPagamento"
                                                                            header="Data de pagamento"></Column>
                                                                    <Column body={this.acoesPagamentosProcesso}
                                                                            exportable={false}
                                                                            style={{minWidth: '8rem'}} header={
                                                                        <Button icon="pi pi-plus-circle"
                                                                                className="p-button-rounded p-button-text"
                                                                                tooltip="Clique para adicionar um novo pagamento"
                                                                                tooltipOptions={{position: 'top'}}
                                                                                id={"add-pagamento"}
                                                                                style={{width: '20px', height: '20px'}}
                                                                                data-toggle="modal"
                                                                                onClick={() => this.abrirModalCadastroPagamentos('modalPagamentos')}
                                                                                data-target="#modal-default"/>
                                                                    }></Column>
                                                                </DataTable>
                                                            </AccordionTab>
                                                            <AccordionTab header={this.headerResumoCustas()}>
                                                                <DataTable value={this.state.custasProcessos}
                                                                           emptyMessage="Nenhuma custa cadastrada">
                                                                    <Column field="custas.nome" header="Custa"
                                                                            className="cabecalho"></Column>
                                                                    <Column field="vlCustas" header="R$"
                                                                            body={this.vlCustas}></Column>
                                                                    <Column field="tipo" header="Deb/Cre"></Column>
                                                                    <Column field="dtPagamento"
                                                                            header="Data de pagamento"></Column>
                                                                    <Column body={this.acoesCustasProcesso}
                                                                            exportable={false}
                                                                            style={{minWidth: '8rem'}} header={
                                                                        <Button icon="pi pi-plus-circle"
                                                                                className="p-button-rounded p-button-text"
                                                                                tooltip="Clique para adicionar uma nova custa"
                                                                                tooltipOptions={{position: 'top'}}
                                                                                style={{width: '20px', height: '20px'}}
                                                                                id={"add-custas"}
                                                                                data-toggle="modal"
                                                                                onClick={() => this.abrirModalCadastroCustas('modalCustas')}
                                                                                data-target="#modal-default"/>
                                                                    }></Column>
                                                                </DataTable>
                                                            </AccordionTab>
                                                            <AccordionTab header={this.headerTotalPenhora()}>
                                                                <DataTable value={this.state.garantiasProcesso}
                                                                           emptyMessage="Nenhuma garantia cadastrada">
                                                                    <Column field="garantia.nome" header="Garantia"
                                                                            className="cabecalho"></Column>
                                                                    <Column field="vlPenhora" header="R$"
                                                                            body={this.vlPenhora}></Column>
                                                                    <Column body={this.acoesGarantiasProcesso}
                                                                            exportable={false}
                                                                            style={{minWidth: '8rem'}} header={
                                                                        <Button icon="pi pi-plus-circle"
                                                                                className="p-button-rounded p-button-text"
                                                                                tooltip="Clique para adicionar uma nova garantia"
                                                                                tooltipOptions={{position: 'top'}}
                                                                                id={'add-garantia'}
                                                                                style={{width: '20px', height: '20px'}}
                                                                                data-toggle="modal"
                                                                                onClick={() => this.abrirModalCadastroGarantias('modalGarantias')}
                                                                                data-target="#modal-default"/>
                                                                    }></Column>
                                                                </DataTable>
                                                            </AccordionTab>
                                                        </Accordion>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Valor provável</label>
                                                        <InputNumber inputId="locale-german"
                                                                     value={this.state.vlProvavel}
                                                                     style={{width: '100%', height: '32px'}}
                                                                     onChange={(e) => this.setState({vlProvavel: e.value})}
                                                                     mode="decimal" locale="de-BR"
                                                                     id="vlProvavel"
                                                                     minFractionDigits={2}/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Valor possível</label>
                                                        <InputNumber inputId="locale-german"
                                                                     value={this.state.vlPossivel}
                                                                     id="vlPossivel"
                                                                     style={{width: '100%', height: '32px'}}
                                                                     onChange={(e) => this.setState({vlPossivel: e.value})}
                                                                     mode="decimal" locale="de-BR"
                                                                     minFractionDigits={2}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Valor remoto</label>
                                                        <InputNumber inputId="locale-german"
                                                                     value={this.state.vlRemoto}
                                                                     style={{width: '100%', height: '32px'}}
                                                                     id="vlRemoto"
                                                                     onChange={(e) => this.setState({vlRemoto: e.value})}
                                                                     mode="decimal" locale="de-BR"
                                                                     minFractionDigits={2}/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Valor causa</label>
                                                        <InputNumber inputId="locale-german"
                                                                     value={this.state.vlCausa}
                                                                    id="vlCausa"
                                                                     style={{width: '100%', height: '32px'}}
                                                                     onChange={(e) => this.setState({vlCausa: e.value})}
                                                                     mode="decimal" locale="de-BR"
                                                                     minFractionDigits={2}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="form-group">
                                                        <label>Pedidos</label>
                                                        <InputText id="pedidos"
                                                                   type="text"
                                                                   style={{width: '100%', height: '32px'}}
                                                                   value={this.state.dsPedidos}
                                                                   maxLength={200}
                                                                   onChange={e => this.setState({dsPedidos: e.target.value})}
                                                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="form-group">
                                                        <label>Observação</label>
                                                        <InputTextarea id="obs-text"
                                                                       maxLength={3000}
                                                                       type="text"
                                                                       style={{width: '100%'}}
                                                                       value={this.state.observacao}
                                                                       onChange={e => this.setState({observacao: e.target.value})}
                                                                       className="p-inputtext-sm p-d-block p-mb-1"
                                                                       rows={3} cols={30}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row gap-1" style={{paddingLeft: '12px'}}>
                                                <Button label="Alterar"
                                                        id={"confirm-uptade-process"}
                                                        className={strings.buttonPrimaryStyle}
                                                        onClick={this.alterarProcesso}/>
                                                <Button label="Voltar"
                                                        id={"cancel-uptade-process"}
                                                        className={strings.buttonSecondaryStyle}
                                                        onClick={this.voltar}/>

                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel header="Movimentação">
                                        <HistoricoProcesso
                                            processo={this.state.processo}
                                            autor={this.state.autor}
                                            reu={this.state.reu}
                                            pasta={this.state.pasta}
                                            nrProcesso={this.state.nrProcesso}
                                            nrCnj={this.state.nrCnj}
                                            toast={this.toast}
                                        />
                                    </TabPanel>

                                    <TabPanel header="Atividades">
                                        <AtividadeProcesso
                                            processo={this.state.processo}
                                            autor={this.state.autor}
                                            reu={this.state.reu}
                                            pasta={this.state.pasta}
                                            nrProcesso={this.state.nrProcesso}
                                            nrCnj={this.state.nrCnj}
                                            toast={this.toast}
                                        />

                                    </TabPanel>
                                    <TabPanel header="Documentos">
                                        <DocumentosProcesso
                                            processo={this.state.processo}
                                            autor={this.state.autor}
                                            reu={this.state.reu}
                                            pasta={this.state.pasta}
                                            nrProcesso={this.state.nrProcesso}
                                            nrCnj={this.state.nrCnj}
                                            toast={this.toast}
                                        />

                                    </TabPanel>
                                    <TabPanel header="Financeiro">
                                        <FinanceiroProcesso
                                            processo={this.state.processo}
                                            autor={this.state.autor}
                                            reu={this.state.reu}
                                            pasta={this.state.pasta}
                                            nrProcesso={this.state.nrProcesso}
                                            nrCnj={this.state.nrCnj}
                                            toast={this.toast}
                                        />
                                    </TabPanel>
                                    <TabPanel header="Precatórios">
                                        <PrecatorioProcesso
                                            processo={this.state.processo}
                                            autor={this.state.autor}
                                            reu={this.state.reu}
                                            pasta={this.state.pasta}
                                            nrProcesso={this.state.nrProcesso}
                                            nrCnj={this.state.nrCnj}
                                            toast={this.toast}
                                        />
                                    </TabPanel>
                                </TabView>

                                {/**Cadastrar um novo cliente */}
                                {this.state.modalNovoCliente &&
                                    <CadastrarPessoaDialog
                                        open={this.state.modalNovoCliente}
                                        onClose={() => this.fecharModal('modalNovoCliente')}
                                        refresh={() => this.listarPessoasEmpresa()}
                                        toast={this.toast}
                                    />
                                }
                            </div>
                        </section>
                    </div>
                </div>
                {this.state.modalHistorico &&
                    <CadastroHistoricoDialog
                        toast={this.toast}
                        processo={this.state.processo}
                        refreshList={() => {
                        }}
                        open={this.state.modalHistorico}
                        tipoDecisao={this.state.tipoDecisao}
                        historicoProcessoService={this.historicoProcessoService}
                        onClose={() => {
                            this.setState({modalHistorico: false})
                        }}
                        onCancel={this.cancelTipoDecisao}
                    />
                }
            </>
        );
    }
}

FichaProcesso.contextType = AuthContext;
export default withRouter(FichaProcesso)
