import React from 'react'
import {withRouter} from 'react-router-dom'
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button'
import {Dropdown} from 'primereact/dropdown'
import {InputText} from 'primereact/inputtext'
import {InputMask} from 'primereact/inputmask';
import {AutoComplete} from 'primereact/autocomplete';
import {InputTextarea} from 'primereact/inputtextarea'
import {Calendar} from 'primereact/calendar'
import {addLocale, locale} from 'primereact/api'

import NavBar from '../../componentes/Navbar'
import ProcessoService from '../../app/service/ProcessoService'
import GrupoTrabalhoService from '../../app/service/GrupoTrabalhoService'
import TipoAcaoService from '../../app/service/TipoAcaoService'
import StatusProcessualService from '../../app/service/StatusProcessualService'
import TipoDecisaoService from '../../app/service/TipoDecisao'
import ObjetoScaoService from '../../app/service/ObjetoAcaoService'
import PessoaService from '../../app/service/PessoaService'
import AreaAtuacaoService from '../../app/service/AreaAtuacaoService'
import ObjetoAcaoProcessoService from '../../app/service/ObjetoAcaoProcessoService'
import LocalTramiteService from '../../app/service/LocalTramiteService'
import {AuthContext} from '../../main/ProvedorAutenticacao'

import './processo.css'
import {DialogGrupoTrabalho} from "../../componentes/DialogGrupoTrabalho";
import {DialogStatusProcessual} from "../../componentes/DialogStatusProcessual";
import {DialogAreaAtuacao} from "../../componentes/DialogAreaAtuacao";
import {DialogTipoAcao} from "../../componentes/DialogTipoAcao";
import {strings} from "../../utils/strings";
import {DateFormat} from "../../utils/DateFormat";
import {DialogTipoDesicao} from '../../componentes/DialogTipoDesicao';
import {IsNullOrEmpty} from '../../utils/IsNullOrEmpy';
import {AutoCompleteLocalTramite} from "../../componentes/AutoCompleteLocalTramite";
import {formatCnj} from "../../utils/nrCnj-format";
import {Loading} from "../../componentes/Loading";
import {CadastrarPessoaDialog} from "../../componentes/Pessoa/CadastrarPessoaDialog";
import {ObjetoAcaoSelecter} from "../../componentes/ObjetosAcaoComponent/ObjetoAcaoSelecter";
import LiminarService from "../../app/service/LiminarService";
import ProcessoAutomaticoService from '../../app/service/ProcessoAutomaticoService';

class CadastrarProcesso extends React.Component {

    constructor(props) {
        super(props);

        this.processoService = new ProcessoService();
        this.processoAutoService = new ProcessoAutomaticoService()
        this.grupoTrabalhoService = new GrupoTrabalhoService();
        this.tipoAcaoService = new TipoAcaoService();
        this.statusProcessualService = new StatusProcessualService();
        this.tipoDecisaoService = new TipoDecisaoService();
        this.objetoAcaoService = new ObjetoScaoService();
        this.pessoaService = new PessoaService();
        this.areaAtuacaoService = new AreaAtuacaoService();
        this.objetoAcaoProcessoService = new ObjetoAcaoProcessoService();
        this.liminarService = new LiminarService();

        this.consultarPessoa = this.consultarPessoa.bind(this);
        this.onGrupoTrabalhoChange = this.onGrupoTrabalhoChange.bind(this);
        this.onAreaAtuacaoChange = this.onAreaAtuacaoChange.bind(this);
        this.onStatusProcessualChange = this.onStatusProcessualChange.bind(this);
        this.onTipoAcaoChange = this.onTipoAcaoChange.bind(this);
        this.onTipoDecisaoChange = this.onTipoDecisaoChange.bind(this);

        this.state = {
            //Cadastro de Processo
            processo: null,
            gruposTrabalho: [],
            grupoTrabalho: null,
            pasta: '',
            nrProcesso: '',
            nrCnj: '',
            system: null,
            tiposAcao: [],
            tipoAcao: null,
            statusProcessuais: [],
            statusProcessual: null,
            areasAtuacao: [],
            areaAtuacao: null,
            tiposDecisao: [],
            tipoDecisao: null,
            dataDistribuicao: "",
            vlProvavel: '',
            vlPossivel: '',
            vlRemoto: '',
            vlCausa: '',
            objetosAcao: [],
            objetoAcao: [],
            modalObjetos: false,
            dsPedidos: '',
            observacao: '',
            //
            selectLocalTramite: null,
            selectedAutor: null,
            selectedReu: null,
            selectedAdvogado: null,

            pessoas: [],
            pessoasParteContraria: [],
            pessoasAdvogados: [],
            classeDiv: 'hide', filterPessoasPA: null,
            filterPessoasPP: null,
            filterPessoasOP: null,
            detail: '',
            //Novo Cliente
            modalNovoCliente: false,
            //modal grupo trabalho
            openGpTrab: false,
            //modal status processual
            openStatus: false,
            //modal area atuacao
            openAreaAtuacao: false,
            //modal area atuacao
            openTipoAcao: false,
            //modal tipo decisao
            openTipoDesicao: false,
            loading: false,
            // liminar
            cumprimentoLiminar: null,
            statusLiminar: null,
            cumprimentoLiminarOptions: [],
            statusLiminarOptions: []
        };

    }

    limparCampos = () => {
        this.setState({
            grupoTrabalho: '',

        })
    }

    fecharModal(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    abrirModalCadastroCliente(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }
        this.setState(state);
    }

    novaPessoa() {
        this.abrirModalCadastroCliente('modalNovoCliente');
        this.setState({
            perfil: {
                codigo: 'AU'
            }
        })
    }

    carregaCombosConsulta = () => {
        const usuarioLogado = this.context.usuarioAutenticado
        this.listarGruposTrabalho(usuarioLogado);
        this.listarTiposAcao(usuarioLogado.empresa);
        this.listarStatusProcessual(usuarioLogado.empresa);
        this.listarTiposDecisao(usuarioLogado.empresa);
        this.listarObjetosAcao(usuarioLogado.empresa);
        this.listarAreaAtuacao(usuarioLogado.empresa);
        this.getFilas();
    }

    getFilas =()=>{

        this.processoAutoService.getSistemas().then((res) => {
            const data = res.data
            this.setState({filas: data})
            this.findSystemBySystemName(data)
        }).catch(
            (error) => {
                const errorData = error.response.data
                const detail = errorData.detail || "Erro ao buscar sistemas"
                console.log(errorData)
                this.toast.show({
                    severity: 'error',
                    summary: 'Erro',
                    detail: `${detail}`,
                    life: 4000
                })
            }
        )
    }

    findSystemBySystemName = (filas) => {
        const { system } = this.state;
        if(system){
            let sistema = filas.find((sistema) => system.includes(sistema.systemName))
            this.setState({ system: sistema })
        }
    }

    listarGruposTrabalho = (usuario) => {
        this.grupoTrabalhoService.listarGruposUsuario(usuario.codigo)
            .then(response => {

                this.setState({gruposTrabalho: response.data});
            }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'Erro ao carregar grupos de trabalhos do usuário',
                life: 4000
            });
        });
    }

    listarTiposAcao = (empresa) => {
        this.tipoAcaoService.listarTipoAcao(empresa.codigo)
            .then(response => {
                this.setState({tiposAcao: response.data});
            }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'Erro ao carregar tipos de ação',
                life: 4000
            });
        })
    }

    listarStatusProcessual = (empresa) => {
        this.statusProcessualService.listarStatusProcessual(empresa.codigo)
            .then(response => {
                this.setState({statusProcessuais: response.data});
            }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'Erro ao carregar status processual',
                life: 4000
            });
        })
    }

    listarTiposDecisao = (empresa) => {
        this.tipoDecisaoService.listarTiposDecisao(empresa.codigo)
            .then(response => {
                this.setState({tiposDecisao: response.data});
            }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'Erro ao carregar tipos de decisão',
                life: 4000
            });
        })
    }

    listarObjetosAcao = (empresa) => {
        this.objetoAcaoService.listarObjetos(empresa.codigo)
            .then(response => {
                this.setState({objetosAcao: response.data});
            }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'Erro ao carregar objetos de ação',
                life: 4000
            });
        })
    }

    listarAreaAtuacao = (empresa) => {
        this.areaAtuacaoService.listarAreaAtuacao(empresa.codigo)
            .then(response => {
                this.setState({areasAtuacao: response.data});
            }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'Erro ao carregar áreas de atuação',
                life: 4000
            });
        })
    }

    listarPessoasEmpresa = async () => {
        const usuarioLogado = this.context.usuarioAutenticado
        await this.pessoaService.listarPessoas(usuarioLogado.empresa.codigo)
            .then(response => {
                this.setState({pessoas: response.data});
            }).catch(error => {
                console.log('Erro ao carregar pessoas');
            })
    }

    async componentDidMount() {
        await this.listarPessoasEmpresa()
        this.carregaCombosConsulta();
        await this.getCumprimentoOptions()
        await this.getStatusLiminarOptions()
    }

    consultarPessoa(event) {
        setTimeout(() => {
            let filterPessoas;
            if (!event.query.trim().length) {
                filterPessoas = [...this.state.pessoas];
            } else {
                if (event.query.toLowerCase().length > 2) {
                    filterPessoas = this.state.pessoas.filter((pessoa) => {
                        return pessoa.nome.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }

            this.setState({[event.originalEvent.target.name]: filterPessoas})
        }, 250);
    }


    onGrupoTrabalhoChange(e) {
        this.setState({grupoTrabalho: e.value});
    }

    onStatusProcessualChange(e) {
        this.setState({statusProcessual: e.value});
    }

    onAreaAtuacaoChange(e) {
        this.setState({areaAtuacao: e.value});
    }

    onTipoAcaoChange(e) {
        this.setState({tipoAcao: e.value});
    }

    onTipoDecisaoChange(e) {
        this.setState({tipoDecisao: e.value});
    }

    pad2 = (n) => {
        return (n < 10 ? '0' : '') + n;
    }

    cadastrarProcesso = async () => {
        this.setState({classeDiv: 'show'});

        if (this.state.selectedAutor == null || this.state.selectedAutor.length === 0) {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'Informe o polo ativo do processo',
                life: 4000
            });
            this.setState({classeDiv: 'hide'});
            return false;
        }

        if (this.state.selectedReu == null || this.state.selectedReu.length === 0) {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'Informe o polo passivo do processo',
                life: 4000
            });
            this.setState({classeDiv: 'hide'});
            return false;
        }

        if (this.state.numeroCnj === '' && this.pasta === '' && this.state.numeroProcesso) {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'É preciso informar uma pasta ou um processo ou um cnj',
                life: 4000
            });
            this.setState({classeDiv: 'hide'});
            return false;
        }

        if (this.state.grupoTrabalho == null) {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'É preciso informar o grupo de trabalho',
                life: 4000
            });
            this.setState({classeDiv: 'hide'});
            return false;
        }


        if (this.state.system == null) {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'É preciso informar o sistema',
                life: 4000
            });
            this.setState({classeDiv: 'hide'});
            return false;
        }

        if (this.state.statusProcessual == null) {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'Informe o status processual',
                life: 4000
            });
            this.setState({classeDiv: 'hide'});
            return false;
        }

        if (this.state.areaAtuacao == null) {
            this.toast.show({severity: 'error', summary: 'Processos', detail: 'Informe a área de atuação', life: 4000});
            this.setState({classeDiv: 'hide'});
            return false;
        }

        if (this.state.tipoAcao == null) {
            this.toast.show({severity: 'error', summary: 'Processos', detail: 'Informe o tipo de ação', life: 4000});
            this.setState({classeDiv: 'hide'});
            return false;
        }
        if (this.state.tipoDecisao == null) {
            this.toast.show({severity: 'error', summary: 'Processos', detail: 'Informe o tipo decisão', life: 4000});
            this.setState({classeDiv: 'hide'});
            return false;
        }

        if (this.state.selectLocalTramite === null || this.state.selectLocalTramite.length === 0) {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'Informe o local de trâmite',
                life: 4000
            });
            this.setState({classeDiv: 'hide'});
            return false;
        }
        this.setState({loading: true});
        const usuarioLogado = this.context.usuarioAutenticado;
        const {dataDistribuicao} = this.state
        const _dataDistribuicao = IsNullOrEmpty(dataDistribuicao) ? DateFormat(new Date()) : DateFormat(dataDistribuicao)
        const json = {
            grupoTrabalho: this.state.grupoTrabalho,
            pasta: this.state.pasta,
            nrProcesso: this.state.nrProcesso,
            nrCnj: formatCnj.removeDots(this.state.nrCnj),
            system: this.state.system.systemName,
            localTramite: this.state.selectLocalTramite,
            tipoAcao: this.state.tipoAcao,
            statusProcessual: this.state.statusProcessual,
            areaAtuacao: this.state.areaAtuacao,
            tipoDecisao: this.state.tipoDecisao,
            dataDistribuicao: _dataDistribuicao,
            vlProvavel: this.state.vlProvavel,
            vlPossivel: this.state.vlPossivel,
            vlRemoto: this.state.vlRemoto,
            vlCausa: this.state.vlCausa,
            dsPedidos: this.state.dsPedidos,
            observacao: this.state.observacao,
            usuario: usuarioLogado,
            responsavel: usuarioLogado,
            empresa: usuarioLogado.empresa,
            liminarCumprimento: this.state.cumprimentoLiminar,
            liminarStatus: this.state.statusLiminar,
            sistemaTribunais: this.state.system
        }

        await this.processoService.cadastrarProcesso(json).then(async (response) => {
            this.toast.show({
                severity: 'success',
                summary: 'Processos',
                detail: 'Processo cadastrado com sucesso!',
                life: 4000
            });
            if (this.state.objetoAcao != null) {
                await this.cadastrarObjetosProcesso(response.data);
            }
            await this.cadastrarPartes(response.data);
            setTimeout(() => this.processoCadastrado(response.data), 4001)

        }).catch(error => {
            console.log(error.response)
            let data = error.response.data
            let detail = data.detail || "Acesso negado pelo servidor."
            this.toast.show({severity: 'error', summary: 'Processos', detail: `${detail}`, life: 4000});
        }).finally(() => this.setState({loading: false}));


    }

    processoCadastrado = (processo) => {
        this.props.history.push(`/fichaProcesso/${processo.codigo}`);
    }

    cadastrarIndiceProcesso = (processo) => {
        this.processoService.cadastrarIndiceProcesso({
            codigo: processo.codigo
        }).then(response => {
            console.log('Indice cadastrado')
        })
    }

    cadastrarPartes = async (processo) => {
        if (this.state.selectedAutor == null) {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'Erro inesperado, lista de autores vazia',
                life: 4000
            });
            return false;
        }

        if (this.state.selectedReu == null) {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'Erro inesperado, lista de réus vazia',
                life: 4000
            });
            return false;
        }

        for (let i = 0; i < this.state.selectedAutor.length; i++) {
            await this.processoService.cadastrarPartes({
                processo: processo,
                pessoa: this.state.selectedAutor[i],
                tipoParte: 'PA'
            }).then(response => {
                console.log('Partes cadastrada', this.state.selectedAutor[i].nome);
            }).catch(error => {
                this.toast.show({
                    severity: 'error',
                    summary: 'Processos',
                    detail: 'Erro ao cadastrar uma das partes do processo - Autor',
                    life: 4000
                });
            })
        }


        for (let i = 0; i < this.state.selectedReu.length; i++) {
            await this.processoService.cadastrarPartes({
                processo: processo,
                pessoa: this.state.selectedReu[i],
                tipoParte: 'PP'
            }).then(response => {
                console.log('Partes cadastrada', this.state.selectedReu[i].nome);
            }).catch(error => {
                this.toast.show({
                    severity: 'error',
                    summary: 'Processos',
                    detail: 'Erro ao cadastrar uma das partes do processo - Réu',
                    life: 4000
                });
            })
        }

        if (this.state.selectedAdvogado != null) {
            for (let i = 0; i < this.state.selectedAdvogado.length; i++) {
                await this.processoService.cadastrarPartes({
                    processo: processo,
                    pessoa: this.state.selectedAdvogado[i],
                    tipoParte: 'OP'
                }).then(response => {
                    console.log('Partes cadastrada', this.state.selectedReu[i].nome);
                }).catch(error => {
                    this.toast.show({
                        severity: 'error',
                        summary: 'Processos',
                        detail: 'Erro ao cadastrar uma das partes do processo - Advogado',
                        life: 4000
                    });
                })
            }
        }

        this.cadastrarIndiceProcesso(processo);
    }

    cadastrarObjetosProcesso = async (processo) => {
        if (this.state.objetoAcao == null) {
            this.toast.show({
                severity: 'error',
                summary: 'Processos',
                detail: 'Erro inesperado, a lista de objetos está vazia',
                life: 4000
            });
            return false;
        }

        for (let i = 0; i < this.state.objetoAcao.length; i++) {
            await this.objetoAcaoProcessoService.cadastrarObjetoAcaoProcesso({
                processo: processo,
                objeto: this.state.objetoAcao[i]
            }).then(response => {
                console.log('Objeto cadastrado: ', this.state.objetoAcao[i].nome)
            }).catch(error => {
                this.toast.show({
                    severity: 'error',
                    summary: 'Processos',
                    detail: 'Erro ao cadastrar objetos de ação no processo',
                    life: 4000
                });
            })
        }
    }

    voltar = () => {
        this.props.history.push('/processos')
    }

    onObjetoSelected = (e) => {
        const {value} = e.target
        this.setState({objetoAcao: value})

    }

    onCumprimentoLiminarChange = (e) => {
        const {value} = e.target
        this.setState({cumprimentoLiminar: value})
    }

    onStatusLiminarChange = (e) => {
        const {value} = e.target
        this.setState({statusLiminar: value})
    }


    getCumprimentoOptions = () => {
        this.liminarService.getAllCumprimento()
            .then(res => {
                this.setState({cumprimentoLiminarOptions: res.data})
            })
    }

    getStatusLiminarOptions = () => {
        this.liminarService.getAllStatus()
            .then(res => {
                this.setState({statusLiminarOptions: res.data})
            })
    }

    render() {
        addLocale('es', {
            firstDayOfWeek: 1,
            dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
            dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
            dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
            monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'Dezembro'],
            monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
            today: 'Hoy',
            clear: 'Claro'
        });

        locale('es');

        return (
            <>
                <Loading open={this.state.loading}/>
                <NavBar/>
                <Toast ref={(el) => this.toast = el}/>
                <div className="content-wrapper">
                    {this.state.openGpTrab &&
                        <DialogGrupoTrabalho open={this.state.openGpTrab}
                                             close={() => this.setState({openGpTrab: false})}
                                             toast={this.toast}
                                             refrashList={this.carregaCombosConsulta}
                        />
                    }
                    {this.state.openStatus &&
                        <DialogStatusProcessual open={this.state.openStatus}
                                                close={() => this.setState({openStatus: false})}
                                                toast={this.toast}
                                                refrashList={this.carregaCombosConsulta}
                        />
                    }
                    {this.state.openAreaAtuacao &&
                        <DialogAreaAtuacao open={this.state.openAreaAtuacao}
                                           close={() => this.setState({openAreaAtuacao: false})}
                                           toast={this.toast}
                                           refrashList={this.carregaCombosConsulta}
                        />
                    }
                    {this.state.openTipoAcao &&
                        <DialogTipoAcao open={this.state.openTipoAcao}
                                        close={() => this.setState({openTipoAcao: false})}
                                        toast={this.toast}
                                        refrashList={this.carregaCombosConsulta}
                        />
                    }
                    {this.state.openTipoDesicao &&
                        <DialogTipoDesicao open={this.state.openTipoDesicao}
                                           close={() => this.setState({openTipoDesicao: false})}
                                           toast={this.toast}
                                           refreshList={this.carregaCombosConsulta}
                        />
                    }
                    <div className="container-fluid">
                        <section className="content-header">
                            <div className="card card-primary card-outline">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="nav-icon fas fa-balance-scale"></i>
                                        <span className="ml-1">Cadastrar processo</span>
                                    </h3>
                                </div>
                                <div className='card-form-align-start'>
                                    <div className="card-body card-max-width">
                                        <div className="row">
                                            <div className="width-100">
                                                <div className="form-group">
                                                    <label>Polo ativo
                                                        <span className="obrigatorioAsterisco"> * </span>
                                                        <span>
                                                            <Button icon="pi pi-plus-circle"
                                                                    className="p-button-rounded p-button-text"
                                                                    tooltip="Clique para cadastrar um novo cliente"
                                                                    tooltipOptions={{position: 'top'}}
                                                                    style={{width: '20px', height: '10px'}}
                                                                    onClick={() => this.novaPessoa()}

                                                            />
                                                        </span>
                                                    </label>
                                                    <AutoComplete value={this.state.selectedAutor}
                                                                  suggestions={this.state.filterPessoasPA}
                                                                  completeMethod={this.consultarPessoa}
                                                                  name={"filterPessoasPA"}
                                                                  style={{width: '100%'}}
                                                                  placeholder="Digite aqui para pesquisar"
                                                                  field="nome" multiple
                                                                  id='poloativo-ac'
                                                                  onChange={(e) => this.setState({selectedAutor: e.value})}
                                                                  className="p-autoComplete-sm p-d-block p-mb-0"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="width-100">
                                                <div className="form-group">
                                                    <label>Polo passivo
                                                        <span className="obrigatorioAsterisco"> * </span>
                                                        <span>
                                                            <Button icon="pi pi-plus-circle"
                                                                    className="p-button-rounded p-button-text"
                                                                    tooltip="Clique para cadastrar um novo cliente"
                                                                    tooltipOptions={{position: 'top'}}
                                                                    style={{width: '20px', height: '10px'}}
                                                                    onClick={() => this.novaPessoa()}

                                                            />
                                                        </span>
                                                    </label>
                                                    <AutoComplete value={this.state.selectedReu}
                                                                  suggestions={this.state.filterPessoasPP}
                                                                  completeMethod={this.consultarPessoa}
                                                                  name={"filterPessoasPP"}
                                                                  style={{width: '100%'}}
                                                                  field="nome" multiple
                                                                  id='polopassivo-ac'
                                                                  placeholder="Digite aqui para pesquisar"
                                                                  onChange={(e) => this.setState({selectedReu: e.value})}
                                                                  className="p-autoComplete-sm p-d-block p-mb-0"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="width-100">
                                                <div className="form-group">
                                                    <label>Outra parte<span> </span>
                                                        <span>
                                                            <Button icon="pi pi-plus-circle"
                                                                    className="p-button-rounded p-button-text"
                                                                    tooltip="Clique para cadastrar um novo cliente"
                                                                    tooltipOptions={{position: 'top'}}
                                                                    style={{width: '20px', height: '10px'}}
                                                                    onClick={() => this.novaPessoa()}

                                                            />
                                                        </span>
                                                    </label>
                                                    <AutoComplete value={this.state.selectedAdvogado}
                                                                  suggestions={this.state.filterPessoasOP}
                                                                  completeMethod={this.consultarPessoa}
                                                                  style={{width: '100%'}}
                                                                  name={"filterPessoasOP"}
                                                                  field="nome" multiple
                                                                  id='outraparte-ac'
                                                                  placeholder="Digite aqui para pesquisar"
                                                                  onChange={(e) =>
                                                                      this.setState({selectedAdvogado: e.value})
                                                                  }
                                                                  className="p-autocomplete-item p-d-block p-mb-0"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row gap-5">
                                            <div className="width-50">
                                                <div className="group">
                                                    <label>Pasta</label>
                                                    <InputText id="pasta"
                                                               type="text"
                                                               style={{width: '100%', height: '37px'}}
                                                               value={this.state.pasta}
                                                               maxLength={30}
                                                               onChange={e => this.setState({pasta: e.target.value})}
                                                               className="p-inputtext-sm p-d-block p-mb-1"/>
                                                </div>
                                            </div>
                                            <div className="width-50">
                                                <div className="group">

                                                    <label>Sistema
                                                        <span className="obrigatorioAsterisco"> *</span>
                                                    </label>
                                                    <Dropdown value={this.state.system}
                                                            options={this.state.filas}
                                                            style={{width: '100%', height: '37px'}}
                                                            optionLabel="systemName"
                                                            filter showClear filterBy="systemName"
                                                            onChange={e => this.setState({system: e.target.value})}
                                                            id="system"
                                                            className="p-inputtext-sm p-d-block p-mb-1 card-max-width"/>                                                                  
                                                        
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row gap-5">
                                            <div className="width-50">
                                                <div className="form-group">
                                                    <label>Numeração única</label>
                                                    <InputMask id="numeroCnj"
                                                               type="text"
                                                               mask="9999999-99.9999.9.99.9999"
                                                               style={{width: '100%', height: '37px'}}
                                                               value={this.state.nrCnj}
                                                               onChange={e => this.setState({nrCnj: e.target.value})}
                                                               className="p-inputtext-sm p-d-block p-mb-1"/>
                                                </div>
                                            </div>
                                            <div className="width-50">
                                                <div className="form-group">
                                                    <label>Processo original</label>
                                                    <InputText id="numeroProcesso"
                                                               type="text"
                                                               maxLength={30}
                                                               style={{width: '100%', height: '37px'}}
                                                               value={this.state.nrProcesso}
                                                               onChange={e => this.setState({nrProcesso: e.target.value})}
                                                               className="p-inputtext-sm p-d-block p-mb-1"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row gap-5">
                                            <div className="width-50">
                                                <div className="form-group">
                                                    <label>Grupo de trabalho
                                                        <span className="obrigatorioAsterisco"> * </span>
                                                        <span>
                                                            <Button icon="pi pi-plus-circle"
                                                                    className="p-button-rounded p-button-text"
                                                                    tooltip="Clique para adicionar um novo grupo de trabalho"
                                                                    tooltipOptions={{position: 'top'}}
                                                                    style={{width: '20px', height: '10px'}}
                                                                    onClick={() => this.setState({openGpTrab: true})}

                                                            />
                                                        </span>
                                                    </label>
                                                    <Dropdown value={this.state.grupoTrabalho}
                                                              options={this.state.gruposTrabalho}
                                                              style={{width: '100%', height: '37px'}}
                                                              optionLabel="nome"
                                                              filter showClear filterBy="nome"
                                                              onChange={this.onGrupoTrabalhoChange}
                                                              id="grupoTrabalho"
                                                              className="p-inputtext-sm p-d-block p-mb-1 card-max-width"/>
                                                </div>
                                            </div>
                                            <div className="width-50">
                                                <div className="form-group">
                                                    <label>Status processual
                                                        <span className="obrigatorioAsterisco"> * </span>
                                                        <span>
                                                            <Button icon="pi pi-plus-circle"
                                                                    className="p-button-rounded p-button-text"
                                                                    tooltip="Clique para adicionar um Novo status processual"
                                                                    tooltipOptions={{position: 'top'}}
                                                                    style={{width: '20px', height: '10px'}}
                                                                    onClick={() => this.setState({openStatus: true})}
                                                            />
                                                        </span>
                                                    </label>
                                                    <Dropdown value={this.state.statusProcessual}
                                                              options={this.state.statusProcessuais}
                                                              style={{width: '100%', height: '37px'}}
                                                              optionLabel="descricao"
                                                              filter showClear filterBy="descricao"
                                                              onChange={this.onStatusProcessualChange}
                                                              id="statusProcessual"
                                                              className="p-inputtext-sm p-d-block p-mb-1"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row gap-5">
                                            <div className="width-50">
                                                <div className="form-group">
                                                    <label>Área de atuação
                                                        <span className="obrigatorioAsterisco"> * </span>
                                                        <span>
                                                            <Button icon="pi pi-plus-circle"
                                                                    className="p-button-rounded p-button-text"
                                                                    tooltip="Clique para adicionar uma nova área de atuação"
                                                                    tooltipOptions={{position: 'top'}}
                                                                    style={{width: '20px', height: '10px'}}
                                                                    onClick={() => this.setState({openAreaAtuacao: true})}
                                                            />
                                                        </span>
                                                    </label>
                                                    <Dropdown value={this.state.areaAtuacao}
                                                              options={this.state.areasAtuacao}
                                                              style={{width: '100%', height: '37px'}}
                                                              optionLabel="nome"
                                                              filter showClear filterBy="nome"
                                                              onChange={this.onAreaAtuacaoChange}
                                                              id="areaAtuacao"
                                                              className="p-inputtext-sm p-d-block p-mb-1"/>
                                                </div>
                                            </div>
                                            <div className="width-50">
                                                <div className="form-group">
                                                    <label>Tipo de ação
                                                        <span className="obrigatorioAsterisco"> * </span>
                                                        <span>
                                                            <Button icon="pi pi-plus-circle"
                                                                    className="p-button-rounded p-button-text"
                                                                    tooltip="Clique para adicionar um Novo tipo de ação"
                                                                    tooltipOptions={{position: 'top'}}
                                                                    style={{width: '20px', height: '10px'}}
                                                                    onClick={() => this.setState({openTipoAcao: true})}
                                                            />
                                                        </span>
                                                    </label>
                                                    <Dropdown value={this.state.tipoAcao}
                                                              options={this.state.tiposAcao}
                                                              style={{width: '100%', height: '37px'}}
                                                              optionLabel="nome"
                                                              filter showClear filterBy="nome"
                                                              onChange={this.onTipoAcaoChange}
                                                              id="tipoAcao"
                                                              className="p-inputtext-sm p-d-block p-mb-1"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row gap-5">
                                            <div className="width-50">
                                                <div className="form-group">
                                                    <label>Cumprimento da liminar
                                                    </label>
                                                    <Dropdown value={this.state.cumprimentoLiminar}
                                                              placeholder={"Selecione uma opção"}
                                                              options={this.state.cumprimentoLiminarOptions}
                                                              style={{width: '100%', height: '37px'}}
                                                              optionLabel="nome"
                                                              filter showClear filterBy="nome"
                                                              onChange={this.onCumprimentoLiminarChange}
                                                              id="cumprimentoLiminar"
                                                              className="p-inputtext-sm p-d-block p-mb-1"/>
                                                </div>
                                            </div>
                                            <div className="width-50">
                                                <div className="form-group">
                                                    <label>Status da liminar
                                                    </label>
                                                    <Dropdown value={this.state.statusLiminar}
                                                              options={this.state.statusLiminarOptions}
                                                              optionLabel="nome"
                                                              placeholder={"Selecione um status"}
                                                              filter showClear filterBy="nome"
                                                              style={{width: '100%', height: '37px'}}
                                                              onChange={this.onStatusLiminarChange}
                                                              id="statusLiminar"
                                                              className="p-inputtext-sm p-d-block p-mb-1"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/*tipo decisao*/}
                                            <div className="width-100">
                                                <div className="form-group">
                                                    <label>Tipo decisão
                                                        <span className="obrigatorioAsterisco"> * </span>
                                                        <span>
                                                            <Button icon="pi pi-plus-circle"
                                                                    className="p-button-rounded p-button-text"
                                                                    tooltip="Clique para adicionar um novo tipo de decisão"
                                                                    tooltipOptions={{position: 'top'}}
                                                                    style={{width: '20px', height: '10px'}}
                                                                    onClick={() => this.setState({openTipoDesicao: true})}

                                                            />
                                                        </span>
                                                    </label>
                                                    <Dropdown value={this.state.tipoDecisao}
                                                              options={this.state.tiposDecisao}
                                                              style={{width: '100%', height: '37px'}}
                                                              optionLabel="nome"
                                                              filter showClear filterBy="nome"
                                                              onChange={this.onTipoDecisaoChange}
                                                              id="tipoDecisao"
                                                              className="p-inputtext-sm p-d-block p-mb-1 card-max-width"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="width-100">
                                                <AutoCompleteLocalTramite
                                                    selectLocalTramite={this.state.selectLocalTramite}
                                                    setSelect={(value) => this.setState({selectLocalTramite: value})}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="width-100">
                                                <div className="form-group">
                                                    <label>Data de distribuição</label>
                                                    <Calendar id="mask"
                                                              value={this.state.dataDistribuicao}
                                                              onChange={(e) => this.setState({dataDistribuicao: e.value})}
                                                              style={{width: '100%', height: '37px'}}
                                                              dateFormat="dd/mm/yy"
                                                              mask="99/99/9999"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="width-100">
                                                <div className="form-group">
                                                    <ObjetoAcaoSelecter
                                                        objetos={this.state.objetoAcao}
                                                        objetosOptions={this.state.objetosAcao}
                                                        onChange={objs => this.setState({objetoAcao: objs})}/>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="width-100">
                                                <div className="form-group">
                                                    <label>Pedidos</label>
                                                    <InputText id="pedidos"
                                                               type="text"
                                                               maxLength={200}
                                                               style={{width: '100%', height: '37px'}}
                                                               value={this.state.dsPedidos}
                                                               onChange={e => this.setState({dsPedidos: e.target.value})}
                                                               className="p-inputtext-sm p-d-block p-mb-1"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="width-100">
                                                <div className="form-group">
                                                    <label>Observação</label>
                                                    <InputTextarea id="observacao"
                                                                   maxLength={3000}
                                                                   type="text"
                                                                   style={{width: '100%'}}
                                                                   value={this.state.observacao}
                                                                   onChange={e => this.setState({observacao: e.target.value})}
                                                                   className="p-inputtext-sm p-d-block p-mb-1"
                                                                   rows={4} cols={30}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='footer-buttons flex-1 gap-1'>
                                            <Button label="Cadastrar"
                                                    id={"btn-cadastrar-processo"}
                                                    className={strings.buttonPrimaryStyle}
                                                    onClick={this.cadastrarProcesso}/>
                                            <Button label="Voltar"
                                                    id={"btn-voltar"}
                                                    className={strings.buttonSecondaryStyle}
                                                    onClick={this.voltar}/>

                                        </div>
                                    </div>
                                    <br></br>
                                </div>
                                {this.state.modalNovoCliente &&
                                    <CadastrarPessoaDialog
                                        open={this.state.modalNovoCliente}
                                        onClose={() => this.fecharModal('modalNovoCliente')}
                                        refresh={() => this.listarPessoasEmpresa()}
                                        toast={this.toast}
                                    />
                                }

                            </div>
                        </section>
                    </div>
                </div>
            </>
        )
    }
}

CadastrarProcesso.contextType = AuthContext;
export default withRouter(CadastrarProcesso)
