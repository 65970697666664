import React, {useRef} from 'react';
import './ImageUpload.css';

const ImageUpload = ({ onFileChange }) => {
    // const [file, setFile] = useState(null);
    const fileInputRef = useRef(null); // Usar useRef para o input de arquivo

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            // setFile(files[0]);
            onFileChange(files[0]);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Aciona o clique no input de arquivo
        }
    };

    return (
        <div
            id="drop-area"
            className="drop-area"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={handleClick}
        >
            {/*{file ? (*/}
            {/*    <img src={URL.createObjectURL(file)} alt="Uploaded" />*/}
            {/*) : (*/}
                <>
                    <i className={"pi pi-cloud-upload"}
                       // size={50}
                       // color="#333"
                    />
                    {/*<p>Arraste e solte uma imagem ou clique para selecionar</p>*/}
                </>
            {/*)}*/}
            <input
                id="fileInput"
                ref={fileInputRef}
                type="file"
                accept="image/png"
                style={{ display: 'none' }}
                onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                        // setFile(file);
                        onFileChange(file);
                    }
                }}
            />
        </div>
    );
};

export default ImageUpload;