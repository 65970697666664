import ApiService from '../apiservice'
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";

export default class RelatorioProcessoService extends ApiService {
  constructor() {
    super('/relatorioProcesso')
  }

  relatorioProcesso(filtro) {
    let params = `?empresa=${filtro.empresa}&usuario=${filtro.usuario}`

    //Filtra por pessoas
    params = `${params}&pessoa=${filtro.pessoa}`;

    //Grupo de Trabalho
    params = `${params}&grupoTrabalho=${filtro.grupoTrabalho}`;

    //Tipo de ação
    params = `${params}&tipoAcao=${filtro.tipoAcao}`;

    //Área de Atuação
    params = `${params}&areaAtuacao=${filtro.areaAtuacao}`

    //Tipo de Decisão
    params = `${params}&tipoDecisao=${filtro.tipoDecisao}`;

    //Data de Decisão
    params = `${params}&dataDecisaoInicial=${filtro.dataDecisaoInicial}&dataDecisaoFinal=${filtro.dataDecisaoFinal}`;

    //Data de Distribuição
    params = `${params}&dataDistribuicaoInicial=${filtro.dataDistribuicaoInicial}&dataDistribuicaoFinal=${filtro.dataDistribuicaoFinal}`;

    //Status Processual
    params = `${params}&statusProcessual=${filtro.statusProcessual}`;

    // Exceto processos arquivados
    params = `${params}&excetoArquivados=${filtro.excetoArquivados}`;

    //Data de Sentença
    params = `${params}&dataSentencaInicial=${filtro.dataSentencaInicial}&dataSentencaFinal=${filtro.dataSentencaFinal}`;

    //Objetos de Ação
    params = `${params}&objetoAcao=${filtro.objetoAcao}`;

    //Garantias
    params = `${params}&garantia=${filtro.garantia}`;

    //Operador Lógico das garantias
    params = `${params}&operadorLogicoGarantias=${filtro.operadorLogicoGarantias}`;

    //Valor Garantias
    params = `${params}&valorGarantia=${filtro.valorGarantia}`;

    //Pagamentos
    params = `${params}&pagamento=${filtro.pagamento}`;

    //Operador Lógico de Pagamentos
    params = `${params}&operadorLogicoPagamento=${filtro.operadorLogicoPagamento}`;

    //Valor Pagamento
    params = `${params}&valorPagamento=${filtro.valorPagamento}`;

    //Custas
    params = `${params}&custas=${filtro.custas}`;

    //Operador Lógico das Custas
    params = `${params}&operadorLogicoCustas=${filtro.operadorLogicoCustas}`;

    //Valor das custas
    params = `${params}&valorCustas=${filtro.valorCustas}`;

    //Data de Cadastro do processo
    params = `${params}&dataCadastroInicial=${filtro.dataCadastroInicial}&dataCadastroFinal=${filtro.dataCadastroFinal}`;

    //Data de Alteração
    params = `${params}&dataAlteracaoInicial=${filtro.dataAlteracaoInicial}&dataAlteracaoFinal=${filtro.dataAlteracaoFinal}`;

    //Importância
    params = `${params}&importancia=${filtro.importancia}`;
    params = `${params}&liminarCumprimento=${filtro.liminarCumprimento}`;
    params = `${params}&liminarStatus=${filtro.liminarStatus}`;
    if(!IsNullOrEmpty(filtro.sistema)){
      params = `${params}&sistema=${filtro.sistema}`
    }

    return this.get(params);
  }

  exportarRelatorio(relatorioProcesso) {
    return this.post('/exportar',relatorioProcesso);
  }

  listarRelatoriosExportados(usuario) {
    return this.get(`/relatoriosExportados/${usuario}`);
  }

  excluirRelatorio(relatorio, usuario) {
    return this.delete(`/excluirRelatorio?relatorio=${relatorio}&usuario=${usuario}`);
  }

  download() {
    return this.get('/download')
  }
}
