import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import React, {useContext, useEffect, useState} from "react";
import {HeaderPanelFichaProcesso} from "./HeaderPanelFichaProcesso";
import {AuthContext} from "../../../main/ProvedorAutenticacao";
import PrecatorioService from "../../../app/service/PrecatorioService";

export const PrecatorioProcesso = ({
                                       processo,
                                       autor,
                                       reu,
                                       nrCnj,
                                       pasta,
                                       nrProcesso,
                                       toast
                                   }) => {
    const {setLoading} = useContext(AuthContext)

    const [listPrecatorios, setListPrecatorios] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);

    const precatorioService = new PrecatorioService();

    function bodyMovimentacao(rowData) {
        if (rowData.listEvents?.length > 0) {
            let last = rowData.listEvents.at(-1)
            return `${last.description}`

        }
        return ""
    }

    function bodyDataMovimentcao(rowData) {
        if (rowData.listEvents?.length > 0) {
            let last = rowData.listEvents.at(-1)
            return `${last.dateEvent}`
        }
        return ""
    }

    function getListaPrecatorios() {
        setLoading(true)
        precatorioService.getListPrecatorioByProcessoId(processo.codigo).then((res) => {
            setListPrecatorios(res.data)
            setLoading(false)
        }).catch(() => {
            setLoading(false)
            toast.current.show({severity: 'error', summary: 'Precatório', detail: 'Erro ao buscar precatórios'})
        })
    }

    function onExpandedRow(data) {
        if (expandedRows?.[data.number] === undefined) {
            let expanded = {
                ...expandedRows,
                [data.number]: true
            };
            setExpandedRows(expanded)
            return
        }else if (expandedRows?.[data.number] === true) {
            const { [data.number]: _, ...remainingRows } = expandedRows; // Remove o elemento
            setExpandedRows(remainingRows);
            return;
        }
        let expanded = {};
        setExpandedRows(expanded)
    }

    const templateExpandedRow = (data) => {
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="numeroPrecatorio">Número Precatório</label>
                                            <input type="text" className="form-control" id="numeroPrecatorio"
                                                   value={data.number} disabled/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="movimentacao" className={"mb-2"}>Movimentações</label>
                                            <div className={"custom-div-movi-precatorios"}>
                                                {/*invertendo a lista para exibir primeiro o ultimo evento*/}
                                                {data?.listEvents?.reverse().map((item, index) => (
                                                    <div className={"flex-column gap-0"} key={index} type="text"
                                                         value={item.dateEvent + " - " + item.description} readOnly>
                                                        <span> {item.dateEvent}</span>
                                                        <span className={"ml-2"}> {item.description}</span>
                                                    </div>
                                                ))}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        getListaPrecatorios()
    }, [])

    return (
        <>
            <div className={"card-body"}>
                <HeaderPanelFichaProcesso
                    autor={autor}
                    reu={reu}
                    nrCnj={nrCnj}
                    pasta={pasta}
                    nrProcesso={nrProcesso}

                />
                {/*<br/>*/}
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <DataTable value={listPrecatorios} paginator
                                       expandedRows={expandedRows}
                                       onRowToggle={(e) => {
                                           setExpandedRows(e.data)
                                       }}
                                       rowExpansionTemplate={templateExpandedRow}
                                       dataKey="number"
                                       responsiveLayout="scroll" size="small"
                                       paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                       currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                                       rows={5}
                                       selectionMode="multiple"
                                       onRowClick={(e) => onExpandedRow(e.data)}
                                       emptyMessage="Nenhum Precatório">
                                {/*<Column body={usuarioHistorico} exportable={false}*/}
                                {/*        style={{textAlign: 'center', minWidth: '8rem'}}/>*/}
                                <Column field="number" header="Numero Precatório"
                                        style={{width: '20rem'}}></Column>
                                <Column body={bodyDataMovimentcao}
                                        style={{width: '20rem'}}
                                        header="Data ocorrência"></Column>
                                <Column body={bodyMovimentacao} header="Ultima movimentação"
                                        style={{width: '50%'}}></Column>
                                <Column expander style={{width: '3em'}}/>
                                {/*<Column field="dataHistorico" header="Data cadastro"></Column>*/}
                                {/*<Column field="usuario.nome" header="Usuário"></Column>*/}
                                {/*<Column body={acoesHistoricoProcesso} exportable={false} header={novoHistoricoHeader}/>*/}
                            </DataTable>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
