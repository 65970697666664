import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import {Dropdown} from "primereact/dropdown";
import {ButtonEtrium} from "../../componentes/ButtonEtrium";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {Toast} from "primereact/toast";
import ProcessoAutomaticoService from "../../app/service/ProcessoAutomaticoService";
import Navbar from "../../componentes/Navbar";
import {Loading} from "../../componentes/Loading";
import {InputText} from "primereact/inputtext";
import {formatCnj} from "../../utils/nrCnj-format";
import {AlertDialog} from "../../componentes/AlertDialog";
import {STRING} from "../../utils/strings";
import TabelaProcessos from "../../componentes/Processo/TableProcessosBuscados";
import {InputSwitch} from "primereact/inputswitch";

export const messageAlertCredentials = () => {
    return (
        <>
            <div>
                <span>{STRING.ALERT_MSG_NO_CREDENTIAL_PROCESSO}</span>
                <br/>
                <span><a className={"link-etrium-login"} href={"#/preferencias"}>Adicione o seu clicando aqui.</a></span>
            </div>
        </>
    )
}

export const CadastrarProcessoAutomatico = () => {

    // const [filas, setFilas] = useState([])

    const toastRef = useRef(null)

    const [numeroProcesso, setNumeroProcesso] = useState("");
    // const [sistema, setSistema] = useState();
    const [loading, setLoading] = useState(false);
    const [credenciaisList, setCredenciaisList] = useState([]);
    const [credential, setCredential] = useState(null);
    const [findPrecatorio, setfindPrecatorio] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
//context
    const {usuarioAutenticado} = useContext(AuthContext);
//service
    const service = new ProcessoAutomaticoService()

    const getUsuarios = () => {
        service.getUsuarioNoCadastroDeProcesso(usuarioAutenticado.empresa.codigo).then((res) => {
            const data = res.data
            setCredenciaisList(data)
            if (data.length > 0) {
                setCredential(data[0])
            } else {
                setShowAlert(true)
            }
        })
            .catch(
                (error) => {
                    const detail = error.response.data.detail || "Erro ao buscar credenciais"
                    toastRef.current.show({
                        severity: 'error',
                        summary: 'Erro',
                        detail: `${detail}`,
                        life: 4000
                    })
                }
            )
    }

    async function cadastrarProcessoAutomatico() {
        if (verificaCampos()) {
            return
        }
        setLoading(true)
        // montando classe processo
        const processo = {
            usuario: usuarioAutenticado,
            numeroProcesso: numeroProcesso,
            credencial: credential.codigo,
            findPrecatorio: findPrecatorio
        }
        // fazer a consulta
        await service.cadastrar(processo).then((res) => {
            const data = res.data
            toastRef.current.show({
                severity: 'success',
                summary: 'Cadastro',
                detail: `${data}`,
                life: 4000
            })
            setNumeroProcesso("")
            window.location.reload()
        }).catch(error => {
            const detail = error.response.data.detail
            toastRef.current.show({
                severity: 'error',
                summary: 'Cadastro',
                detail: `${detail}`,
                life: 4000
            })
        }).finally(() => {
            setLoading(false);
        })


    }

    function verificaCampos() {

        if (IsNullOrEmpty(numeroProcesso)) {
            toastRef.current.show({
                severity: 'error',
                summary: 'Campos obrigatórios',
                detail: 'Insira o número do processo',
                life: 4000
            })
            return true
        }
        if (IsNullOrEmpty(credential)) {
            toastRef.current.show({
                severity: 'error',
                summary: 'Campos obrigatórios',
                detail: 'Selecione uma credencial para o cadastro de processo',
                life: 4000
            })
            return true
        }
        return false
    }

    const onInpoutProcessoChange = useCallback((event) => {
        const {value} = event.target
        setNumeroProcesso(formatCnj.formatarNumeroProcesso(value))
    }, [setNumeroProcesso])

    useEffect(() => {
        // getFilas()
        getUsuarios()
    }, []);

    return (
        <>
            <Navbar/>
            <Toast ref={toastRef}/>
            <Loading open={loading}/>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <section className="content-header">
                        <div className="card card-primary card-outline">
                            <div className="card-header row align-items-center gap-0">
                                <h3 className="card-title">
                                    <i className="nav-icon fas fa-balance-scale"></i>
                                    <span className="ml-1">Cadastrar processo automático</span>
                                </h3>
                            </div>
                            <div className='card-form-align-start'>
                                <div className="card-body"
                                style={{minWidth: '90%'}}
                                >


                                    <div className="row">
                                        <div className="col-sm-4">
                                            <label>Número do processo<span
                                                className="obrigatorioAsterisco"> *</span></label>
                                            <InputText id="numeroCnj"
                                                       type="text"
                                                // mask="9999999-99.9999.9.99.9999"
                                                       style={{width: '100%'}}
                                                       value={numeroProcesso}
                                                       onChange={onInpoutProcessoChange}
                                                       maxLength={25}
                                                       className="p-inputtext-sm p-d-block p-mb-1"/>
                                        </div>
                                        <div className="col-sm-4">
                                            <label>Advogado<span
                                                className="obrigatorioAsterisco"> *</span></label>
                                            <Dropdown value={credential}
                                                      options={credenciaisList}
                                                      style={{width: '100%', height: '37px'}}
                                                      optionLabel="usuario.nome"
                                                      filter showClear filterBy="usuario.nome"
                                                      onChange={(e) => setCredential(e.value)}
                                                      id="credential"
                                                      className="p-inputtext-sm p-d-block p-mb-1 card-max-width"/>
                                        </div>
                                        <div className="col-sm-4">
                                            <label> Buscar precatórios do processo</label>
                                                <InputSwitch id="findPrecatorio"
                                                    checked={findPrecatorio}
                                                             onChange={(e) => setfindPrecatorio(e.value)}/>
                                        </div>
                                    </div>

                                    <div className="footer-buttons-cadastro gap-1">
                                        <ButtonEtrium buttonType={'primary'}
                                                      children={"Buscar"}
                                                      onClick={cadastrarProcessoAutomatico}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-header">
                                <h3 className="card-title">
                                    <i className="nav-icon fas fa-gavel"></i>
                                    <span className="ml-1"> Processos Retornados</span>
                                </h3>
                            </div>

                            <TabelaProcessos userId={usuarioAutenticado.codigo}/>

                        </div>

                    </section>
                </div>
            </div>
            {showAlert &&
                <AlertDialog
                    title="Busca automática de processo"
                    open={showAlert}
                    onClose={() => setShowAlert(false)}
                    message={messageAlertCredentials()}
                    />
            }
        </>
    );

}
