import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import EmpresaService from "../../app/service/EmpresaService";
import Navbar from "../../componentes/Navbar";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import { strings } from "../../utils/strings";
import { Toast } from "primereact/toast";
import { Loading } from "../../componentes/Loading";  // Importando o componente de loading

export default function DetalhesEmpresa() {
  const { id } = useParams();
  const [empresa, setEmpresa] = useState(null);
  const [usuariosPermitidos, setUsuariosPermitidos] = useState(0);
  const [processosPermitidos, setProcessosPermitidos] = useState(0);
  const [status, setStatus] = useState(null); 
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [tipoPessoa, setTipoPessoa] = useState("");
  const [telefone1, setTelefone1] = useState("");
  const [telefone2, setTelefone2] = useState("");
  const [cep, setCep] = useState("");
  const [bairro, setBairro] = useState("");

  const empresaService = new EmpresaService();
  const toast = useRef(null);

  useEffect(() => {
    setLoading(true);  
    empresaService.consultarEmpresa(id).then((res) => {
      const empresaData = res.data;
  
      setEmpresa(empresaData);
      setNome(empresaData.nome || "");
      setEmail(empresaData.email || "");
      setCpfCnpj(empresaData.cpfCnpj || "");
      setTipoPessoa(empresaData.tipoPessoa || "");
      setTelefone1(empresaData.telefone1 || "");
      setTelefone2(empresaData.telefone2 || "");
      setCep(empresaData.cep || "");
      setBairro(empresaData.bairro || "");
      setUsuariosPermitidos(empresaData.qtdeUsuario || 0);
      setProcessosPermitidos(empresaData.quantidadeProcessos || 0);
      setStatus(empresaData.status || 1);
      setLoading(false);
    }).catch((error) => {
      console.error('Erro ao carregar dados da empresa', error);
      setLoading(false);
    });
  }, [id]);

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString();
  };

  const statusOptions = [
    { label: "Ativa", value: 2 },
    { label: "Inativa", value: 3 },
  ];

  const salvarAlteracoes = () => {
    const empresaAtualizada = {
      codigo: Number(empresa.codigo),
      nome: nome,
      email: email,
      cpfCnpj: cpfCnpj,
      tipoPessoa: tipoPessoa,
      telefone1: telefone1,
      telefone2: telefone2,
      cep: cep,
      bairro: bairro,
      status: status,
      qtdeUsuario: usuariosPermitidos,
      quantidadeProcessos: processosPermitidos,
    };

    empresaService.editarEmpresaFull(empresaAtualizada).then(response => {
      setEmpresa(response.data);
      toast.current.show({
        severity: "success",
        summary: "Edição de empresa",
        detail: "Empresa alterada com sucesso!",
        life: 4000,
      });
    }).catch(error => {
      console.error('Erro ao atualizar os recursos da empresa', error);
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: "Ocorreu um erro ao atualizar os recursos.",
        life: 4000,
      });
    });
  };
//removido adicionado ex: empresa?.nome
// if (!empresa) {
//   return <Loading open={true}/>
// }

  return (
    <>
      <Navbar />
      
      <Toast ref={toast} />
      <Loading open={loading} />
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary card-outline">
                  <div className="card-header">
                    <h3 className="card-title">
                      <i className="pi pi-building"></i>
                      <span> Alterar Empresa</span>
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <h4>Informações Gerais</h4>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Nome:</label>
                              <InputText
                                id="nome"
                                type="text"
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                                className="p-inputtext-sm p-d-block p-mb-1"
                                style={{ width: "100%", height: "32px" }}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Email:</label>
                              <InputText
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="p-inputtext-sm p-d-block p-mb-1"
                                style={{ width: "100%", height: "32px" }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>CNPJ:</label>
                              <InputText
                                type="text"
                                value={cpfCnpj}
                                onChange={(e) => setCpfCnpj(e.target.value)}
                                className="p-inputtext-sm p-d-block p-mb-1"
                                style={{ width: "100%", height: "32px" }}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Tipo Pessoa:</label>
                              <InputText
                                type="text"
                                value={tipoPessoa === 'J' ? 'Jurídica' : 'Física'}
                                onChange={(e) => setTipoPessoa(e.target.value)}
                                className="p-inputtext-sm p-d-block p-mb-1"
                                style={{ width: "100%", height: "32px" }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Status:</label>
                              <Dropdown
                                value={status}
                                options={statusOptions}
                                onChange={(e) => setStatus(e.value)} // Atualiza o estado do status
                                style={{ width: "100%" }}
                                className="p-inputtext-sm p-d-block p-mb-1"
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Data de Cadastro:</label>
                              <InputText
                                type="text"
                                value={formatDate(empresa?.dataCadastro)}
                                disabled
                                className="p-inputtext-sm p-d-block p-mb-1"
                                style={{ width: "100%", height: "32px" }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Telefone 1:</label>
                              <InputText
                                type="text"
                                value={telefone1}
                                onChange={(e) => setTelefone1(e.target.value)}
                                className="p-inputtext-sm p-d-block p-mb-1"
                                style={{ width: "100%", height: "32px" }}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Telefone 2:</label>
                              <InputText
                                type="text"
                                value={telefone2}
                                onChange={(e) => setTelefone2(e.target.value)}
                                className="p-inputtext-sm p-d-block p-mb-1"
                                style={{ width: "100%", height: "32px" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <h4>Endereço</h4>
                        <div className="form-group">
                          <label>Cidade:</label>
                          <InputText
                            type="text"
                            disabled
                            value={empresa?.cidade?.nome}
                            className="p-inputtext-sm p-d-block p-mb-1"
                            style={{ width: "100%", height: "32px" }}
                          />
                        </div>

                        <div className="form-group">
                          <label>Estado:</label>
                          <InputText
                            type="text"
                            disabled
                            value={empresa?.cidade?.estado?.estado}
                            className="p-inputtext-sm p-d-block p-mb-1"
                            style={{ width: "100%", height: "32px" }}
                          />
                        </div>

                        <div className="form-group">
                          <label>CEP:</label>
                          <InputText
                            type="text"
                            value={cep}
                            onChange={(e) => setCep(e.target.value)}
                            className="p-inputtext-sm p-d-block p-mb-1"
                            style={{ width: "100%", height: "32px" }}
                          />
                        </div>

                        <div className="form-group">
                          <label>Bairro:</label>
                          <InputText
                            type="text"
                            value={bairro}
                            onChange={(e) => setBairro(e.target.value)}
                            className="p-inputtext-sm p-d-block p-mb-1"
                            style={{ width: "100%", height: "32px" }}
                          />
                        </div>
                      </div>
                    </div>

                    <hr />
                    <h4>Plano Contratado</h4>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Nome do Plano</label>
                          <InputText
                            type="text"
                            value={empresa?.plano?.tipoPlano?.nome}
                            disabled
                            className="p-inputtext-sm p-d-block p-mb-1"
                            style={{ width: "100%", height: "32px" }}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Valor do Plano (R$)</label>
                          <InputText
                            type="text"
                            value={empresa?.plano?.tipoPlano?.valor}
                            disabled
                            className="p-inputtext-sm p-d-block p-mb-1"
                            style={{ width: "100%", height: "32px" }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Usuários Permitidos</label>
                          <InputText
                            type="number"
                            value={usuariosPermitidos}
                            onChange={(e) => setUsuariosPermitidos(parseInt(e.target.value, 10))}
                            className="p-inputtext-sm p-d-block p-mb-1"
                            style={{ width: "100%", height: "32px" }}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Processos Permitidos</label>
                          <InputText
                            type="number"
                            value={processosPermitidos}
                            onChange={(e) => setProcessosPermitidos(parseInt(e.target.value, 10))}
                            className="p-inputtext-sm p-d-block p-mb-1"
                            style={{ width: "100%", height: "32px" }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <Button label="Alterar" onClick={salvarAlteracoes} className={strings.buttonPrimarySm} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
