import ApiService from "../apiservice";


export default class PrecatorioService extends ApiService {
    constructor() {
        super("/precatorio");
    }

    getListPrecatorioByProcessoId(processoID) {
        return this.get(`/processo/${processoID}`);
    }
}