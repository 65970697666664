import React from 'react'

import AuthService from '../app/service/AuthService'
import jwt from 'jsonwebtoken'
import {Loading} from "../componentes/Loading";
import EmpresaPlanosService from "../app/service/EmpresaPlanosService";
import Logo from "../img/etrium-logo-png.png";
import LocalStorageService from "../app/service/localStorageService";
import {KEY_LOGO_EMPRESA} from "../Consts/Tag";
import EmpresaService from "../app/service/EmpresaService";

export const AuthContext = React.createContext()
export const AuthConsumer = AuthContext.Consumer;

const AuthProvider = AuthContext.Provider;

class ProvedorAutenticacao extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            usuarioAutenticado: null,
            isAutenticado: false,
            isLoading: true,
            loading: false,
            expirou: false,
            selectPlano: false,
            logo: Logo,
        }
        this.empresaPlanoService = new EmpresaPlanosService()
        this.empresaService = new EmpresaService()

    }


    iniciarSessao = (tokenDTO) => {
        const token = tokenDTO.token
        const claims = jwt.decode(token)
        const usuario = {
            codigo: claims.codigo,
            nome: claims.nome,
            perfil: claims.perfil,
            empresa: claims.empresa
        }
        this.setState({isAutenticado: true, usuarioAutenticado: usuario})
        AuthService.logar(usuario, token);

    }

    encerrarSessao = () => {
        AuthService.removerUsuarioAutenticado();
        this.setState({isAutenticado: false, usuarioAutenticado: null})
    }

    async componentDidMount() {
        const isAutenticado = AuthService.isUsuarioAutenticado()
        if (isAutenticado) {
            const usuario = await AuthService.refreshSession()
            this.setState({
                isAutenticado: true,
                usuarioAutenticado: usuario,
                isLoading: false
            })
            this.empresaExpirou()
            this.empresaNaoTemPlanoSelecionado()
            this.setState({ logo : this.getLogo()})
            // let logo = this.getLogo()
            // console.log(logo)
        } else {
            this.setState(previousState => {
                return {
                    ...previousState,
                    isLoading: false
                }
            })
        }
    }

    setLoading = (bol) => {
        this.setState({loading: bol})
    }

    empresaExpirou() {
        if (this.state.usuarioAutenticado) {
            this.empresaPlanoService.getEmpresaPlanoExpirou(this.state.usuarioAutenticado.empresa.codigo).then((res) => {
                this.setState({expirou: res.data})
            })
        }
    }

    empresaNaoTemPlanoSelecionado() {
        const {usuarioAutenticado} = this.state
        if (usuarioAutenticado) {
            this.empresaPlanoService.getEmpresaPlanoHasntPlanSelected(usuarioAutenticado.empresa.codigo).then((res) => {
                this.setState({selectPlano: res.data})
            })
        }
    }

    getLogo = () => {
        let localLogo = LocalStorageService.getImage(KEY_LOGO_EMPRESA)
        if (localLogo) return localLogo
        // se sim usar a imagem do local se nao, busca no back-end, se mesmo assim nao existir imagem, retorna Logo
        let _logo = Logo
        this.empresaService.getImagemEmpresa(this.state.usuarioAutenticado.empresa.codigo).then(
            async (res) => {
                let data = res.data
                if (data) {
                    _logo = data
                    LocalStorageService.addedImagem(KEY_LOGO_EMPRESA, data)
                    this.setState({logo: _logo})
                }
            }
        )
        return _logo
    }

    async saveImageToLocalStorage(blob) {
        const reader = new FileReader();

        reader.onloadend = () => {
            // A string base64 será gerada no `reader.result`
            const base64data = reader.result;
            LocalStorageService.addedImagem(KEY_LOGO_EMPRESA, base64data); // Salva no localStorage
            console.log('Imagem salva no localStorage!');
        };

        reader.readAsDataURL(blob); // Converte o blob em Base64
    }

    render() {

        if (this.state.isLoading) {
            return null;
        }

        const contexto = {
            usuarioAutenticado: this.state.usuarioAutenticado,
            isAutenticado: this.state.isAutenticado,
            setLoading: this.setLoading,
            iniciarSessao: this.iniciarSessao,
            encerrarSessao: this.encerrarSessao,
            expirou: this.state.expirou,
            selectPlano: this.state.selectPlano,
            logoEmpresa: this.state.logo,
            saveImageToLocalStorage: this.saveImageToLocalStorage,

        }


        return (
            <AuthProvider value={contexto}>
                <Loading open={this.state.loading}/>
                {this.props.children}
            </AuthProvider>
        )
    }
}

export default ProvedorAutenticacao;
