import React, {useContext, useEffect, useState} from 'react';
import imagemRelatorio from '../seguranca/img/etrium-logo1.svg';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {AuthContext} from '../../main/ProvedorAutenticacao';
import {formatDateHoraImpressao} from "../../utils/DateFormat";
import {formatarMoeda} from "../../utils/formatar-moeda-tela";

export const ComponentToPrint = React.forwardRef(({lancamentos, filtro, quantidade, lancamentosResumido}, ref) => {
    const [nomeUsuario, setNomeUsuario] = useState('');

    const usuarioLogado = useContext(AuthContext).usuarioAutenticado;
    const {logoEmpresa} = useContext(AuthContext);

    const templateVlLancamento=(rowData)=>{
        return rowData.categoria.toString().toLowerCase() === "despesa"? `${formatarMoeda(-rowData.vlLancamento)}` : formatarMoeda(rowData.vlLancamento)
    }
   const templateVlPago=(rowData)=>{
        return formatarMoeda(rowData.vlPago)
    }
    const templateValor=(rowData)=>{
        return formatarMoeda(rowData.valor)
    }

    useEffect(() => {
        setNomeUsuario(usuarioLogado.nome);

    }, [usuarioLogado, lancamentos, lancamentosResumido]);
    return (
        <div ref={ref}>
            <br></br>
            <div style={{margin: '38px'}}>
                <div>
                    <img src={logoEmpresa || imagemRelatorio
                    }
                         // width="250"
                         height="80"
                         alt={'Logo da empresa'}/>
                </div>
                {lancamentos ? <span className="relatorio">Relatório de lançamentos detalhados</span>
                    : <span className="relatorio">Relatório de lançamentos resumidos</span>
                }
                <div className="flex-row gap-0">
                    <text>Impresso por: {nomeUsuario}</text>
                </div>
                <div className="flex-row gap-0">
                    <text>{formatDateHoraImpressao()}</text>
                </div>
                <div>
                    <span className="filtro">Filtros:&nbsp;{filtro}</span>
                </div>
                <div>
                    Quantidade de Registros:&nbsp;{quantidade}
                </div>
                <hr></hr>
            </div>

            <div style={{margin: '46px'}}>
                {lancamentos ? <DataTable value={lancamentos} alwaysShowPaginator responsiveLayout="scroll"
                                          emptyMessage=" "
                                          paginatorRight selectionMode="multiple">
                        <Column style={{background: 'none'}} field="categoria" header="Categoria"></Column>
                        <Column style={{background: 'none'}} field="tipo.nome" header="Tipo"></Column>
                        <Column style={{background: 'none'}} body={templateVlLancamento} header="Valor"></Column>
                        <Column style={{background: 'none'}} field="dtVencimento" header="Data Vencto."></Column>
                        <Column style={{background: 'none'}} body={templateVlPago} header="Valor Pago"></Column>
                        <Column style={{background: 'none'}} field="dtPago" header="Data Pgto."></Column>
                        <Column style={{background: 'none'}} field="situacao" header="Situação"></Column>
                    </DataTable> :
                    <DataTable value={lancamentosResumido} emptyMessage=" ">
                        <Column field="label" header="Tipo"></Column>
                        <Column body={templateValor} header="Valor"></Column>
                    </DataTable>}
            </div>

        </div>
    );
});

