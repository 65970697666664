import React, {useContext, useEffect, useRef, useState} from 'react';
import {Loading} from "../../componentes/Loading";
import {Toast} from 'primereact/toast';
import Navbar from "../../componentes/Navbar";
import {withRouter} from "react-router-dom";
import {strings} from "../../utils/strings";
import EstadoService from "../../app/service/EstadoService";
import EmpresaService from "../../app/service/EmpresaService";
import {InputMask} from "primereact/inputmask";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import LocalStorageService from "../../app/service/localStorageService";
import {USUARIO_LOGADO} from "../../app/service/AuthService";
import EmpresaGraficoService from "../../app/service/EmpresaGraficoService";
import '../css/util.css'
import AlterarPlanosDialog from "../planos/AlterarPlanoDialog";
import TransacoesEmpresa from './TransacoesEmpresa';
import {AuthContext} from "../../main/ProvedorAutenticacao";
import ImageUpload from "../../componentes/Upload/ImageUpload";
import {KEY_LOGO_EMPRESA} from "../../Consts/Tag";


function EditarEmpresa() {
    const [loading, setLoading] = useState(false)
    const [razaoSocial, setRazaoSocial] = useState("")
    const [cnpj, setCnpj] = useState("")
    const [selectEstado, setSelectEstado] = useState()
    const [selectCidade, setSelectCidade] = useState()
    const [cidades, setCidades] = useState([])
    const [estados, setEstados] = useState([])
    const [detail, setDetail] = useState()
    const [empresaDetail, setEmpresaDetail] = useState(null)
    const [openAlterarPlanos, setOpenAlterarPlanos] = useState(false)
    const [logoEmpresaState, setLogoEmpresaState] = useState(null)

    const [showButton, setShowButton] = useState(false);

    const user = LocalStorageService.obterItem(USUARIO_LOGADO);

    const {saveImageToLocalStorage, logoEmpresa} = useContext(AuthContext);
    const estadoService = new EstadoService();
    // const cidadeService = new CidadeService();
    const empresaService = new EmpresaService();
    const empresaGraficoService = new EmpresaGraficoService()

    const toast = useRef(null);

    async function consultarEmpresaByCodigo(codigo) {
        await empresaGraficoService.findEmpresaDetailInfo(codigo).then(r => {
            setEmpresaDetail(r.data)
        })

    }

    const listarEstados = async () => {
        await estadoService.listarEstados()
            .then(response => {
                setEstados(response.data);
            })
    }

    async function consultarEmpresa() {
        await empresaService.consultarEmpresa(user.empresa.codigo).then(e => {
            // console.log(e.data)
            setCnpj(e.data.cpfCnpj)
            setSelectEstado(e.data.cidade.estado)
            // listarCidades(e.data.cidade.estado.codigo)
            setSelectCidade(e.data.cidade)
            setRazaoSocial(e.data.nome)
        }).catch(error => {
            setDetail(error.response.data)
            toast.current.show({
                severity: 'error',
                summary: 'Usuários',
                detail: `${detail}`,
                life: 4000
            });
        })
    }

    useEffect(() => {
        // listarEstados()
        consultarEmpresa()
        consultarEmpresaByCodigo(user.empresa.codigo)
        setLogoEmpresaState(logoEmpresa)
    }, [])

    useEffect(() => {
        setLogoEmpresaState(logoEmpresa)
    }, [logoEmpresa])


    const isNullOrBlank = (item) => {
        if (item == null) {
            return true
        }
        return item === '';
    }
    //temporariamente removido
    // async function onEstadoChange(e) {
    //     setSelectEstado(e);
    //     await listarCidades(e.codigo);
    // }
    //
    // async function listarCidades(cod) {
    //     await cidadeService.listarCidades(cod)
    //         .then(response => {
    //             setCidades(response.data)
    //         }).catch(error => {
    //             setDetail(error.response.data)
    //             toast.current.show({
    //                 severity: 'error',
    //                 summary: 'Empresa',
    //                 detail: `${detail}`,
    //                 life: 4000
    //             });
    //         })
    // }
    //
    // async function handleSubmit() {
    //
    //     const empresa = {
    //         codigo: user.empresa.codigo,
    //         cnpj: cnpj,
    //         razaoSocial: razaoSocial,
    //         cidade: selectCidade
    //     }
    //     await empresaService.editarEmpresa(empresa).then(() => {
    //         toast.current.show({
    //             severity: 'success',
    //             summary: 'Empresa',
    //             detail: `A empresa foi editada com sucesso`,
    //             life: 4000
    //         })
    //     }).catch((error) => {
    //         setDetail(error.response.data)
    //         toast.current.show({
    //             severity: 'error',
    //             summary: 'Empresa',
    //             detail: `${detail}`,
    //             life: 4000
    //         });
    //     })
    // }

    const EmpresaStatus = {
        AGUARDANDO_CONFIRMACAO_EMAIL: 1,
        ATIVO: 2,
        SUSPENSA: 3,
        CANCELADA: 4
    };

    const getStatusText = (status) => {
        switch (status) {
            case EmpresaStatus.AGUARDANDO_CONFIRMACAO_EMAIL:
                return 'Aguardando Confirmação de Email';
            case EmpresaStatus.ATIVO:
                return 'Ativo';
            case EmpresaStatus.SUSPENSA:
                return 'Suspensa';
            case EmpresaStatus.CANCELADA:
                return 'Cancelada';
            default:
                return 'Status Desconhecido';
        }
    };

    function handleUpload(img) {
        if (img.type !== 'image/png') {
            return toast.current.show({
                severity: 'error',
                summary: 'Empresa',
                detail: `O arquivo deve ser do tipo PNG`,
                life: 4000
            })
        }

        const blob = new Blob([img], {type: 'image/png'});
        let formData = new FormData();
        formData.append('file', img);
        formData.append('empresa', user.empresa.codigo);
        empresaService.uploadImagem(formData).then(() => {
            saveImageToLocalStorage(blob)
            window.location.reload()
        }).catch(error => {
            let detail = error.response.data.detail || "Erro ao carregar os dados."
            toast.current.show({severity: 'error', summary: 'Empresa', detail: `${detail}`, life: 4000});
        })
    }

    function handleDeleteLogo() {
        empresaService.deleteImagem(user.empresa.codigo).then(
            () => {
                toast.current.show({
                    severity: 'success',
                    summary: 'Empresa',
                    detail: `A imagem da empresa foi deletada com sucesso`,
                    life: 4000
                })
                LocalStorageService.removerItem(KEY_LOGO_EMPRESA);
                setLogoEmpresaState(null)
                window.location.reload()
            }
        ).catch(
            error => {
                let detail = error.response.data.detail || "Erro ao remover imagem."
                toast.current.show({severity: 'error', summary: 'Empresa', detail: `${detail}`, life: 4000});
            })
    }

    return (
        <>
            <Loading open={loading}/>
            <Navbar/>
            <Toast ref={toast}/>
            <AlterarPlanosDialog toast={toast} open={openAlterarPlanos} close={() => setOpenAlterarPlanos(false)}/>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <form>
                        <section className="content-header">
                            <div className='card card-primary card-outline gap-1'>
                                <div className='card-header'>
                                    <h3 className="card-title row-etrium gap-1 align-center">
                                        <i className="pi pi-building"></i>
                                        <span>Alterar dados da empresa</span>
                                    </h3>
                                </div>
                                {empresaDetail &&
                                    <div className="row col-sm-12 px-4" style={{gap: "0.6rem"}}>
                                        <div className="flex-col justify-content-start align-items-center card m-b-0">
                                            <span className="border-bottom card-header px-5">Usuários:</span><span
                                            className="card-body"> {empresaDetail.usuarios}/{empresaDetail.quantidadeUsuariosPlano}</span>
                                        </div>
                                        <div className="flex-col justify-content-end align-items-center card m-b-0">
                                            <span className="border-bottom card-header px-5">Processos:</span><span
                                            className="card-body"> {empresaDetail.processos}/{empresaDetail.quantidadeProessosPushPlano}</span>
                                        </div>
                                        <div
                                            className="flex-col justify-content-end align-items-center card m-b-0">
                                            <span className="border-bottom card-header px-5">Atividades:</span><span
                                            className="card-body"> {empresaDetail.atividades}</span>
                                        </div>
                                        <div
                                            id='PlanoName'
                                            className="flex-col justify-content-end align-items-center card m-b-0">
                                            <span className="border-bottom card-header px-5">Plano:</span><span
                                            className="card-body">{empresaDetail.plano}</span>

                                        </div>
                                        <div
                                            id='StatusPlano'
                                            className="flex-col justify-content-end align-items-center card m-b-0">
                                            <span className="border-bottom card-header px-2">Status do Plano:</span>
                                            <span className="card-body">{getStatusText(empresaDetail.status)}</span>
                                        </div>
                                    </div>
                                }
                                <div className="row-etrium" style={{
                                    gap: '5px',
                                    marginBottom: "0",
                                    borderBottom: 'none',
                                    paddingLeft: "1.25rem",
                                    paddingRight: "1.25rem"
                                }}>
                                    <div className="form-group" style={{flex: 1}}>
                                        {/*<label>Logo</label>*/}
                                        <div className="row-etrium align-items-center gap-1">
                                            {logoEmpresaState &&
                                                <div className={"position-relative "}
                                                     onMouseEnter={() => setShowButton(true)}
                                                     onMouseLeave={() => setShowButton(false)}
                                                >
                                                    <img src={logoEmpresaState}
                                                         alt="Logo da empresa" height={80}
                                                    />
                                                    {showButton &&
                                                        <button
                                                            onClick={handleDeleteLogo}
                                                            style={{
                                                                position: 'absolute',
                                                                top: '0px',
                                                                right: '0px',
                                                                color: 'red',
                                                                border: 'none',
                                                                borderRadius: '100%',
                                                            }}
                                                        >
                                                            <i className={"pi pi-times-circle"} color={"red"}/>
                                                        </button>
                                                    }
                                                </div>
                                            }
                                            <div>
                                                <ImageUpload onFileChange={handleUpload}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-etrium" style={{
                                    gap: '5px',
                                    marginBottom: "0",
                                    borderBottom: 'none',
                                    paddingLeft: "1.25rem",
                                    paddingRight: "1.25rem"
                                }}>

                                    <div className="group">
                                        <label>CNPJ<span className="obrigatorioAsterisco"> *</span></label>
                                        <InputMask mask="99.999.999/9999-99"
                                                   id="cpnj"
                                                   name='cnpj'
                                                   disabled
                                                   type="text"
                                                   style={{width: '100%'}}
                                                   value={cnpj}
                                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                                    </div>
                                    <div className="group">
                                        <label>Razão social<span
                                            className="obrigatorioAsterisco"> *</span></label>
                                        <InputText id="razaoSocial"
                                                   name="razaoSocial"
                                                   type="text"
                                                   style={{width: '100%'}} autoComplete="off"
                                                   value={razaoSocial}
                                                   disabled
                                            // onChange={e => setRazaoSocial(e.target.value)}
                                                   className="p-inputtext-sm p-d-block p-mb-1"/>

                                    </div>
                                </div>
                                <div className="row-etrium" style={{
                                    gap: '5px',
                                    marginBottom: "0",
                                    borderBottom: 'none',
                                    paddingLeft: "1.25rem",
                                    paddingRight: "1.25rem"
                                }}>

                                    <div className="form-group" style={{flex: 1}}>
                                        <label>Estado<span className="obrigatorioAsterisco"> *</span></label>
                                        <Dropdown value={selectEstado}
                                                  options={estados} style={{width: '100%'}}
                                                  optionLabel="estado"
                                                  filter showClear filterBy="estado"
                                                  disabled
                                            // onChange={e => onEstadoChange(e.target.value)}
                                                  id="estado"
                                                  className="p-inputtext-sm p-d-block p-mb-1"/>
                                    </div>


                                    {!isNullOrBlank(selectEstado) &&

                                        <div className="form-group" style={{flex: 1}}>
                                            <label>Cidade<span
                                                className="obrigatorioAsterisco"> *</span></label>
                                            <Dropdown value={selectCidade}
                                                      options={cidades}
                                                      style={{width: '100%'}}
                                                      optionLabel="nome"
                                                      emptyMessage="Nenhuma cidade para este estado"
                                                      filter showClear filterBy="nome"
                                                      disabled={true}
                                                // onChange={e => setSelectCidade(e.target.value)}
                                                      id="cidade"
                                                      className="p-inputtext-sm p-d-block p-mb-1"/>
                                        </div>}
                                </div>

                                <div>
                                    <TransacoesEmpresa empresaCodigo={user.empresa.codigo}/>
                                    <div className={"card-body  py-0"}>
                                        <div className='row-etrium gap-1'>
                                            {/*<Button label="Alterar"*/}
                                            {/*        type={'submit'}*/}
                                            {/*        className={strings.buttonPrimaryStyle}/>*/}
                                            {user.perfil.codigo === 1 &&
                                                <Button id='Modify-plan-btn' label="Alterar plano"
                                                        type={'button'}
                                                        onClick={() => setOpenAlterarPlanos(true)}
                                                        className={strings.buttonSecondaryStyle}/>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>
                    </form>
                </div>
            </div>
        </>
    )

}

export default withRouter(EditarEmpresa)