import React from 'react'

import {withRouter} from 'react-router-dom'
import ReactToPrint from "react-to-print";

import RelatorioProcessoService from '../../app/service/RelatorioProcessoService'

import '../financeiro/financeiro.css'
import './relatorioProcesso.css'
import {AuthContext} from '../../main/ProvedorAutenticacao';
import imagemRelatorio from '../../pages/seguranca/img/etrium-logo1.svg'
import {DateFormatHour, formatDateHoraImpressao} from "../../utils/DateFormat";
import {FolhaRelatorioProcesso} from "../../componentes/Relatorio/Processo/FolhaRelatorioProcesso";
import {Loading} from "../../componentes/Loading";
import {exportToCSV, extractPropertyNames} from "../../utils/ToCsv";
import {ButtonSm} from "../../componentes/ButtonSm";
import {strings} from "../../utils/strings";


class ComponentToPrintRelatorioProcesso extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nomeUsuario: '',
            dataImpressao: '',
            horaImpressao: '',
            filtro: "",
            quantidade: '',
            loading: true
        }
    }


    componentDidMount() {
        this.setState({loading: true})
        const usuario = this.context.usuarioAutenticado
        this.setState({
            nomeUsuario: usuario.nome,
        });
        this.setState({loading: false})
    }


    render() {
        return (
            <div ref={el => this.componentRef = el}>
                <Loading open={this.state.loading}/>
                <br></br>
                <div style={{margin: '38px'}}>
                    <div>
                        <img src={this.context.logoEmpresa ||  imagemRelatorio}
                             // width="200"
                             height="80" alt={'Logo do Sistema'}/>
                    </div>
                    <span className="relatorio">Relatório de processos</span>
                    <div className="flex-row gap-0">
                        <text>Impresso por: {this.state.nomeUsuario}</text>
                    </div>
                    <div className="flex-row gap-0">
                        <text>{formatDateHoraImpressao()}</text>
                    </div>
                    <div>
                        <span className="filtro">Filtros:&nbsp;{this.props.filtro}</span>
                    </div>
                    <div className={"flex-row justify-content-between"}>
                      <span>
                        Quantidade de registros:&nbsp;{this.props.quantidade}
                      </span>
                        {/*<ReactToPrint*/}
                        {/*    // eslint-disable-next-line*/}
                        {/*    trigger={() => <ButtonEtrium hidden={this.state.visibleBtnImprimir} onClick={() => this.setState({visibleBtnImprimir: true})}>Imprimir</ButtonEtrium>}*/}
                        {/*    content={() => this.componentRef}>*/}
                        {/*</ReactToPrint>*/}
                    </div>

                    <hr></hr>
                </div>

                <div style={{margin: '38px'}}>
                    {this.props.dados.map(proc => {
                        return (
                            <FolhaRelatorioProcesso processo={proc} displayInf={this.props}/>
                        )
                    })}
                </div>
            </div>
        );
    }
}


class RelatorioProcessoResult extends React.Component {
    constructor(props) {
        super(props)

        this.relatorioProcessoService = new RelatorioProcessoService();

        this.state = {
            relatorioResult: [],
            campoNumeroProcesso: '',
            campoNumeroCnj: '',
            campoPasta: '',
            campoPoloAtivo: '',
            campoPoloPassivo: '',
            campoOutrasPartes: '',
            campoTipoDecisao: '',
            campoTipoAcao: '',
            campoAreaAtuacao: '',
            campoDataUltimaDecisao: '',
            campoDataCadastro: '',
            campoDataSentenca: '',
            campoDataAlteracao: '',
            campoDataDistribuicao: '',
            campoStatusProcessual: '',
            campoGrupoTrabalho: '',
            campoObjetos: '',
            campoGarantias: '',
            campoValorGarantias: '',
            campoPagamentos: '',
            campoValorPagamentos: '',
            campoCustas: '',
            campoValorCustas: '',
            campoSnPush: '',
            campoPushEmail: '',
            campoPushHistorico: '',
            campoUltimaAtividade: '',
            campoDataUltimaAtividade: '',
            campoUltimoHistorico: '',
            campoDataUltimoHistorico: '',
            campoLiminarCumprimento: '',
            campoLiminarStatus: '',
            filtro: "",
            filterExibir: "",
            loading: true,
            objetosFiltrados: []
        }
    }

    consultarRelatorio = async (params) => {

        const filtro = {
            empresa: params.empresa,
            usuario: params.usuario,
            pessoa: params.pessoa,
            sistema: params.sistema,
            dataDecisaoInicial: params.dataDecisaoInicial,
            dataDecisaoFinal: params.dataDecisaoFinal,
            dataSentencaInicial: params.dataSentencaInicial,
            dataSentencaFinal: params.dataSentencaFinal,
            dataDistribuicaoInicial: params.dataDistribuicaoInicial,
            dataDistribuicaoFinal: params.dataDistribuicaoFinal,
            dataCadastroInicial: params.dataCadastroInicial,
            dataCadastroFinal: params.dataCadastroFinal,
            dataAlteracaoInicial: params.dataAlteracaoInicial,
            dataAlteracaoFinal: params.dataAlteracaoFinal,
            grupoTrabalho: params.grupoTrabalho,
            objetoAcao: params.objetoAcao,
            pagamento: params.pagamento,
            operadorLogicoPagamento: params.operadorLogicoPagamentos,
            valorPagamento: params.valorPagamento,
            custas: params.custas,
            operadorLogicoCustas: params.operadorLogicoCustas,
            valorCustas: params.valorCustas,
            statusProcessual: params.statusProcessual,
            areaAtuacao: params.areaAtuacao,
            tipoDecisao: params.tipoDecisao,
            tipoAcao: params.tipoAcao,
            excetoArquivados: params.excetoArquivados,
            importancia: params.importancia,
            garantia: params.garantias,
            operadorLogicoGarantias: params.operadorLogicoGarantias,
            valorGarantia: params.valorGarantia,
            liminarCumprimento: params.liminarCumprimento,
            liminarStatus: params.liminarStatus
        }
        await this.relatorioProcessoService.relatorioProcesso(filtro)
            .then(response => {
                this.setState({campoNumeroProcesso: params.campoNumeroProcesso});
                this.setState({campoNumeroCnj: params.campoNumeroCnj});
                this.setState({campoPasta: params.campoPasta});
                this.setState({campoPoloAtivo: params.campoCliente})
                this.setState({campoPoloPassivo: params.campoParteContraria});
                this.setState({campoOutrasPartes: params.campoAdvogado});
                this.setState({campoTipoDecisao: params.campoTipoDecisao});
                this.setState({campoTipoAcao: params.campoTipoAcao});
                this.setState({campoAreaAtuacao: params.campoAreaAtuacao});
                this.setState({campoDataUltimaDecisao: params.campoDataDecisao});
                this.setState({campoDataCadastro: params.campoDataCadastro});
                this.setState({campoDataSentenca: params.campoDataSentenca});
                this.setState({campoDataAlteracao: params.campoDataAlteracao});
                this.setState({campoDataDistribuicao: params.campoDataDistribuicao});
                this.setState({campoStatusProcessual: params.campoStatusProcessual});
                this.setState({campoGrupoTrabalho: params.campoGrupoTrabalho});
                this.setState({campoObjetos: params.campoObjetoAcao});
                this.setState({campoGarantias: params.campoGarantia});
                this.setState({campoValorGarantias: params.campoValorGarantia});
                this.setState({campoPagamentos: params.campoPagamento});
                this.setState({campoValorPagamentos: params.campoValorPagamento});
                this.setState({campoCustas: params.campoCustas});
                this.setState({campoValorCustas: params.campoValorCustas});
                this.setState({campoSnPush: params.campoPush});
                this.setState({campoPushEmail: params.campoPushEmail});
                this.setState({campoPushHistorico: params.campoPushHistorico});
                this.setState({campoUltimaAtividade: params.campoAtividade});
                this.setState({campoDataUltimaAtividade: params.campoDataUltimaAtividade});
                this.setState({campoUltimoHistorico: params.campoUltimoHistorico});
                this.setState({campoDataUltimoHistorico: params.campoDataUltimoHistorico});
                this.setState({campoLiminarCumprimento: params.campoCumprimentoLiminar});
                this.setState({campoLiminarStatus: params.campoStatusLiminar});
                this.setState({relatorioResult: response.data});
                this.setState({quantidade: response.data.length});
                this.setState({campoSistema: params.campoSistema});
            }).catch(error => {
                console.log('Erro', error);
            }).finally(
                () => {
                    this.addFilter(params)
                    const colunas = this.transformObject(this.filtrarObjeto(this.state))
                    const objetosFiltrados = this.selecionarItensPorColuna(this.state.relatorioResult, colunas);
                    // console.log(objetosFiltrados)
                    this.setState({objetosFiltrados: objetosFiltrados});
                }
            )
    }
    addFilter = (meuobj) => {
        let stringArray = [];
        for (var propriedade in meuobj) {
            if (meuobj[propriedade] !== "0" && meuobj[propriedade] !== "null" && meuobj[propriedade] !== 'hide' && meuobj[propriedade] !== 'none') {
                let nomePropriedade = propriedade.replace("campo", "").replace(/[A-Z]/g, ' $&').trim(); // Adiciona espaço antes de cada letra maiúscula
                nomePropriedade = nomePropriedade.charAt(0).toUpperCase() + nomePropriedade.slice(1); // Capitaliza a primeira letra
                stringArray.push(nomePropriedade);
            }
        }
        const nomeSeparado = stringArray.join(",");
        this.setState({ filterExibir: nomeSeparado });
    }

    filtrarObjeto = (objeto) => {
        // Objeto para armazenar os itens filtrados
        let novoObjeto = {};

        // Iterar sobre as chaves do objeto original
        for (let chave in objeto) {
            // Verificar se a chave contém "campo" e o valor é "hide"
            if (chave.includes("campo") && objeto[chave] === "hide") {
                // Adicionar a chave e valor correspondentes ao novo objeto
                novoObjeto[chave] = objeto[chave];
            } else {
                // Não fazer nada, não adicionando ao novo objeto
            }
        }

        return novoObjeto;
    }
    //tranfosmando o objeto removendo o campo e deixando no formato "Nome Sobrenome"
    transformObject = (objetoMutavel) => {
        const columnNames = [];

        for (const campo in objetoMutavel) {
            if (objetoMutavel.hasOwnProperty(campo) && objetoMutavel[campo] === "hide") {
                const nomeCampo = campo.replace("campo", ""); // Remover "campo" do nome
                const nomeSeparado = nomeCampo.split(/(?=[A-Z])/); // Separar nomes compostos

                const nomeFormatado = nomeSeparado.map(nome => nome.charAt(0).toUpperCase() + nome.slice(1)).join(" "); // Capitalizar a primeira letra de cada nome e juntar com espaço
                columnNames.push(nomeFormatado);
            }
        }

        return columnNames;
    }

    normalizeKey = (key) => {
        return key.charAt(0).toLowerCase() + key.slice(1); // Converte a primeira letra para minúscula
    }
    selecionarItensPorColuna = (objetos, colunas) => {
        const objetosFiltrados = [];
        objetos.forEach(objeto => {
            const objetoFiltrado = {};

            colunas.forEach(coluna => {
                const chaveCampo = this.normalizeKey(coluna).replace(/ /g, ""); // Remover espaços para obter a chave correspondente no objeto
                if (objeto.hasOwnProperty(chaveCampo)) {

                    objetoFiltrado[coluna] = objeto[chaveCampo];
                }
            });

            objetosFiltrados.push(objetoFiltrado);
        });

        return objetosFiltrados;
    }

    handleClickExportToCSV = () => {
        const {objetosFiltrados, filterExibir} = this.state;
        if (objetosFiltrados.length > 0) {

            const colunas = extractPropertyNames(objetosFiltrados[0]);
            const fileName = `Relatorio - ${DateFormatHour(new Date())} - ${filterExibir}`
            exportToCSV(objetosFiltrados, fileName, colunas)
        }
    }

    async componentDidMount() {
        this.setState({loading: true})
        const params = this.props.match.params;
        await this.consultarRelatorio(params);
        this.setState({loading: false})

    }

    render() {
        return (
            <div className="">
                <Loading open={this.state.loading}/>
                <section className="">
                    <div className="flex-row">
                        <div className="col-md-12">
                            <div className="formGroup">
                                <div className="btnImprimir-position-absosute">
                                    <div className="position-relative">
                                        <div className="flex-row gap-1">
                                            <ReactToPrint
                                                // eslint-disable-next-line
                                                trigger={() => <ButtonSm
                                                    className={strings.buttonPrimarySm}
                                                > <i className="pi pi-print"></i> Imprimir</ButtonSm>}
                                                content={() => this.componentRef}>
                                            </ReactToPrint>

                                            <ButtonSm
                                                id='BtnExp' type="button"
                                                className={strings.buttonPrimarySm}
                                                onClick={this.handleClickExportToCSV}
                                                aria-haspopup aria-controls="overlay_panel">
                                                <i className="pi pi-file-excel"></i>
                                                Exportar</ButtonSm>
                                        </div>

                                    </div>
                                </div>
                                <ComponentToPrintRelatorioProcesso dados={this.state.relatorioResult}
                                                                   quantidade={this.state.quantidade}
                                                                   exibirNumeroProcesso={this.state.campoNumeroProcesso}
                                                                   exibirNumeroCnj={this.state.campoNumeroCnj}
                                                                   exibirPasta={this.state.campoPasta}
                                                                   exibirSistema={this.state.campoSistema}
                                                                   exibirCliente={this.state.campoPoloAtivo}
                                                                   exibirParteContraria={this.state.campoPoloPassivo}
                                                                   exibirAdvogado={this.state.campoOutrasPartes}
                                                                   exibirTipoDecisao={this.state.campoTipoDecisao}
                                                                   exibirTipoAcao={this.state.campoTipoAcao}
                                                                   exibirAreaAtuacao={this.state.campoAreaAtuacao}
                                                                   exibirDataDecisao={this.state.campoDataUltimaDecisao}
                                                                   exibirDataCadastro={this.state.campoDataCadastro}
                                                                   exibirDataSentenca={this.state.campoDataSentenca}
                                                                   exibirDataAlteracao={this.state.campoDataAlteracao}
                                                                   exibirDataDistribuicao={this.state.campoDataDistribuicao}
                                                                   exibirStatusProcessual={this.state.campoStatusProcessual}
                                                                   exibirGrupoTrabalho={this.state.campoGrupoTrabalho}
                                                                   exibirObjetoAcao={this.state.campoObjetos}
                                                                   exibirGarantia={this.state.campoGarantias}
                                                                   exibirValorGarantia={this.state.campoValorGarantias}
                                                                   exibirPagamento={this.state.campoPagamentos}
                                                                   exibirValorPagamento={this.state.campoValorPagamentos}
                                                                   exibirCustas={this.state.campoCustas}
                                                                   exibirValorCustas={this.state.campoValorCustas}
                                                                   exibirPush={this.state.campoSnPush}
                                                                   exibirPushEmail={this.state.campoPushEmail}
                                                                   exibirPushHistorico={this.state.campoPushHistorico}
                                                                   exibirAtividade={this.state.campoUltimaAtividade}
                                                                   exibirDataUltimaAtividade={this.state.campoDataUltimaAtividade}
                                                                   exibirUltimoHistorico={this.state.campoUltimoHistorico}
                                                                   exibirDataUltimoHistorico={this.state.campoDataUltimoHistorico}
                                                                   exibirCumprimentoLiminar={this.state.campoLiminarCumprimento}
                                                                   exibirStatusLiminar={this.state.campoLiminarStatus}
                                                                   filtro={this.state.filterExibir}
                                                                   ref={el => (this.componentRef = el)}/>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

ComponentToPrintRelatorioProcesso.contextType = AuthContext;

export default withRouter(RelatorioProcessoResult)
